import React from "react";
import { useStaffAppraisalsStore } from "../../../../../../config/stores";
import { Col, Row } from "antd";
import CapacityDevelopmentGoalsForm from "./CapacityDevelopmentGoalsForm";
import CapacityDevelopmentGoalsTable from "./CapacityDevelopmentGoalsTable";
import { filter } from "lodash";

const CapacityDevelopmentGoals = ({ usage = "all" }) => {
  const staffAppraisalsStore = useStaffAppraisalsStore();
  const { staffAppraisalDetails } = staffAppraisalsStore;

  let data = filter(staffAppraisalDetails.capacityDevelopmentGoals, {
    is_first_appraisal: false,
  });

  if (usage === "all") {
    data = filter(staffAppraisalDetails.capacityDevelopmentGoals, {
      is_first_appraisal: true,
    });
  }
  return (
    <div>
      {staffAppraisalDetails.previousCapacityDevelopmentGoals ? (
        <></>
      ) : (
        <>
          <Row gutter={4}>
            <Col span={8}>
              <CapacityDevelopmentGoalsForm usage={usage} />
            </Col>
            <Col span={16}>
              <CapacityDevelopmentGoalsTable usage={usage} data={data} />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default CapacityDevelopmentGoals;
