import React, { useEffect } from "react";
import { useStaffAppraisalsStore } from "../../../config/stores";
import { useForm } from "antd/es/form/Form";
import usePrevious from "../../hooks/usePrevious";
import dayjs from "dayjs";
import AntdModal from "../../common/AntdModal";
import AppButton from "../../common/AppButton";
import { Col, Form, message, Row } from "antd";
import AppInput from "../../common/AppInput";

const AppraisalForm = () => {
  const staffAppraisalStore = useStaffAppraisalsStore();
  const {
    createdStaffAppraisal,
    staffAppraisalToUpdate,
    showCreateStaffAppraisal,
    creating,
  } = staffAppraisalStore;
  const [form] = useForm();
  const prevState = usePrevious({ createdStaffAppraisal });

  const closeModal = () => {
    staffAppraisalStore.setCreatingStaffAppraisal();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        const payload = data;

        if (creating) {
          staffAppraisalStore.createStaffAppraisal(payload);
        } else {
          staffAppraisalStore.updateStaffAppraisal(
            staffAppraisalToUpdate.id,
            payload
          );
        }
      })
      .catch((err) => message.error("Please fill in all fields correctly"));
  };

  useEffect(() => {
    if (
      prevState &&
      createdStaffAppraisal &&
      prevState.createdStaffAppraisal !== createdStaffAppraisal
    ) {
      form.resetFields();
    }
  }, [prevState, form, createdStaffAppraisal]);

  useEffect(() => {
    if (creating) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        ...staffAppraisalToUpdate,
        start_date: dayjs(staffAppraisalToUpdate.start_date),
        end_date: dayjs(staffAppraisalToUpdate.end_date),
        date_of_appraisal: dayjs(staffAppraisalToUpdate.date_of_appraisal),
      });
    }
  }, [creating, staffAppraisalToUpdate, form]);

  return (
    <AntdModal
      title={creating ? "ADD STAFF APPRAISAL" : "EDIT STAFF APPRAISAL"}
      visible={showCreateStaffAppraisal}
      closeModal={closeModal}
      size="large"
      footer={[
        <AppButton
          text="CREATE APPRAISAL"
          key={"create"}
          loading={
            staffAppraisalStore.creatingStaffAppraisal ||
            staffAppraisalStore.updatingStaffAppraisal
          }
          onClick={onSubmit}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form layout="vertical" form={form}>
        <AppInput
          label="Date of appraisal"
          name="date_of_appraisal"
          type="date"
          rules={[{ required: true, message: "This field is required" }]}
        />
        <Row gutter={4}>
          <Col span={12}>
            {" "}
            <AppInput
              label="Start"
              name="start_date"
              type="date"
              rules={[{ required: true, message: "This field is required" }]}
            />
          </Col>
          <Col span={12}>
            <AppInput
              label="End"
              name="end_date"
              type="date"
              rules={[{ required: true, message: "This field is required" }]}
            />
          </Col>
        </Row>
        <AppInput
          label="Reason For Appraisal"
          name="reason_for_appraisal"
          type="select"
          rules={[{ required: true, message: "This field is required" }]}
          options={[
            {
              label: "Rate periodical Performance",
              value: "Rate periodical Performance",
            },
            {
              label: "Consideration for Promotion",
              value: "Consideration for Promotion",
            },
          ]}
        />
      </Form>
      {/* <Divider className="m-1" /> */}
    </AntdModal>
  );
};

export default AppraisalForm;
