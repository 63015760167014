import { Collapse } from "antd";
import React from "react";
import AnnualTargets from "./AnnualTargets";
import SoftSkillEnhancement from "./SoftSkillEnhancement";
import OverallPerformance from "./OverallPerformance";
import CapacityDevelopmentGoals from "./CapacityDevelopmentGoals";

const EmployerSection = () => {
  return (
    <div>
      <Collapse
        defaultActiveKey={[
          "annual-targets",
          "soft-skills-enhancement",
          "capacity-development-goals",
          "overall-performance",
        ]}
        items={[
          {
            label: (
              <span className="fw-bold">
                Section 3: Appraisal of Annual Targets Supervisor assessment
              </span>
            ),
            key: "annual-targets",
            children: <AnnualTargets />,
          },
          {
            label: (
              <span className="fw-bold">
                Section 4: Soft Skills Enhancement
              </span>
            ),
            key: "soft-skills-enhancement",
            children: <SoftSkillEnhancement />,
          },
          {
            label: (
              <span className="fw-bold">
                Section 5: Capacity Development Goals
              </span>
            ),
            key: "capacity-development-goals",
            children: <CapacityDevelopmentGoals />,
          },
          {
            label: (
              <span className="fw-bold">
                Section 6: Overall Performance Summary
              </span>
            ),
            key: "overall-performance",
            children: <OverallPerformance />,
          },
        ]}
      />
    </div>
  );
};

export default EmployerSection;
