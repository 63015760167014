import { Card, Col, Form, message, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useStaffAppraisalsStore } from "../../../../../config/stores";
import { flatMap, isEmpty, map } from "lodash";
import AppInput from "../../../../common/AppInput";
import AppButton from "../../../../common/AppButton";
import AntdTable from "../../../../common/AntdTable";
import TableTitle from "../../../../common/TableTitle";
import TableButtonActions from "../../../../common/TableButtonActions";

const GoalsForNextPeriod = () => {
  const staffAppraisalsStore = useStaffAppraisalsStore();
  const [deleteId, setDeleteId] = useState(null);
  const [filterTable, setFilterTableNull] = useState(null);
  const [form] = Form.useForm();

  const {
    gettingStaffAppraisalDetails,
    staffAppraisalDetails,
    creatingGoalForNextPeriod,
    addingGoalForNextPeriod,
    updatingGoalForNextPeriod,
    goalForNextPeriodToUpdate,
    createGoalForNextPeriod,
    updateGoalForNextPeriodError,
    updateStaffAppraisalGoalForNextPeriod,
    addGoalForNextPeriodError,
  } = staffAppraisalsStore;

  const kpi = Form.useWatch("performance_indicator_id", form);

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    staffAppraisalsStore.setCreatingGoalForNextPeriod(false, true, record);
  };

  const confirmDelete = () => {
    staffAppraisalsStore.deleteGoalForNextPeriod(
      deleteId,
      staffAppraisalDetails.id
    );
  };

  const reloadDetails = () => {
    staffAppraisalsStore.getStaffAppraisalDetails(staffAppraisalDetails.id);
  };

  const resetFields = () => form.resetFields();

  const onSave = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.staff_appraisal_id = staffAppraisalDetails.id;

        delete values.annual_target;
        if (creatingGoalForNextPeriod) {
          await createGoalForNextPeriod(values);
          if (isEmpty(addGoalForNextPeriodError)) resetFields();
        } else {
          await updateStaffAppraisalGoalForNextPeriod(
            goalForNextPeriodToUpdate.id,
            values
          );
          if (isEmpty(updateGoalForNextPeriodError)) resetFields();
        }
      })
      .catch((err) => {
        message.error("Please fill in the form correctly");
      });
  };

  useEffect(() => {
    if (!creatingGoalForNextPeriod && !isEmpty(goalForNextPeriodToUpdate)) {
      form.setFieldsValue(goalForNextPeriodToUpdate);
    } else {
      form.resetFields();
    }
  }, [creatingGoalForNextPeriod, goalForNextPeriodToUpdate, form]);

  const indicators = useMemo(() => {
    return flatMap(
      staffAppraisalDetails?.staffContract?.assessment_areas,
      (area) => {
        return map(area.obligations, (obligation) => ({
          value: obligation.id,
          label: obligation.key_performance_indicator,
          annual_target: obligation?.annual_target,
        }));
      }
    );
  }, [staffAppraisalDetails?.staffContract?.assessment_areas]);

  useEffect(() => {
    if (kpi) {
      const indicator = indicators.find((indicator) => {
        return indicator.value === kpi;
      });
      form.setFieldValue("annual_target", indicator.annual_target);
    }
  }, [kpi, indicators, form]);

  return (
    <div>
      <>
        <Row gutter={4}>
          <Col span={8}>
            <Card
              title={
                creatingGoalForNextPeriod ? "CREATE RECORD" : "EDIT RECORD"
              }
            >
              <Form onFinish={onSave} form={form} layout="vertical">
                <AppInput
                  name="goal"
                  label="Goal/Target"
                  rules={[{ required: true, message: "Missing this field" }]}
                />
                <AppInput
                  name="key_performance_indicator_id"
                  label="Key Performance Indicators (KPIs)"
                  type="select"
                  options={indicators}
                  rules={[{ required: true, message: "Missing this field" }]}
                />{" "}
                <AppInput
                  name="timeline"
                  label="Timeline"
                  rules={[{ required: true, message: "Missing this field" }]}
                />
                <AppInput
                  name="resources_needed"
                  label="Resources Needed"
                  rules={[{ required: true, message: "Missing this field" }]}
                />
                <AppButton
                  text={creatingGoalForNextPeriod ? "SAVE" : "SAVE UPDATES"}
                  key={"create"}
                  block
                  loading={addingGoalForNextPeriod || updatingGoalForNextPeriod}
                  onClick={onSave}
                  disabled={staffAppraisalDetails.status === "approved"}
                />
              </Form>
            </Card>
          </Col>
          <Col span={16}>
            <AntdTable
              title={() => (
                <TableTitle
                  setFilterTableNull={setFilterTableNull}
                  openAddModal={null}
                  refreshTable={reloadDetails}
                  search={null}
                  exportRecords={null}
                />
              )}
              loading={gettingStaffAppraisalDetails}
              data={
                filterTable === null
                  ? staffAppraisalDetails?.appraisalGoalsForNextPeriod
                  : filterTable
              }
              columns={[
                {
                  title: "Goal/Target",
                  dataIndex: "goal",
                  key: "assessment-area",
                },
                {
                  title: "Key Performance Indicators (KPIs)",
                  dataIndex: "key_performance_indicator",
                  key: "kpi",
                  render: (row) => row?.key_performance_indicator,
                },
                {
                  title: "Timeline",
                  dataIndex: "timeline",
                  key: "annual_target",
                },
                {
                  title: "Resources Needed",
                  dataIndex: "resources_needed",
                },
                {
                  title: "ACTION",
                  render: (record) => {
                    return (
                      <TableButtonActions
                        record={record}
                        confirmDelete={confirmDelete}
                        deleteLoading={
                          staffAppraisalsStore.deletingGoalForNextPeriod
                        }
                        disabled={staffAppraisalDetails.status === "approved"}
                        updateRecord={updateRecord}
                        deleteId={deleteId}
                        deleteRecord={deleteRecord}
                      />
                    );
                  },
                },
              ]}
            />
          </Col>
        </Row>
      </>
    </div>
  );
};

export default GoalsForNextPeriod;
