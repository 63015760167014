import React, { useEffect, useState } from "react";
import { useStaffAppraisalsStore } from "../../../../config/stores";
import { Col, Form, message, Row } from "antd";
import { isEmpty } from "lodash";
import AppInput from "../../../common/AppInput";
import AppButton from "../../../common/AppButton";
import AntdTable from "../../../common/AntdTable";
import TableTitle from "../../../common/TableTitle";
import TableButtonActions from "../../../common/TableButtonActions";

const PersonalDevelopmentGoals = () => {
  const staffAppraisalsStore = useStaffAppraisalsStore();
  const [deleteId, setDeleteId] = useState(null);
  const [filterTable, setFilterTableNull] = useState(null);
  const [form] = Form.useForm();

  const {
    gettingStaffAppraisalDetails,
    staffAppraisalDetails,
    creatingDevelopmentGoals,
    addingDevelopmentGoals,
    updatingDevelopmentGoals,
    developmentGoalToUpdate,
    createStaffDevelopmentGoal,
    updateDevelopmentGoalsError,
    updateStaffAppraisalDevelopmentGoal,
    addDevelopmentGoalsError,
  } = staffAppraisalsStore;
  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    staffAppraisalsStore.setCreatingSelfDevelopmentGoal(false, true, record);
  };

  const confirmDelete = () => {
    staffAppraisalsStore.deletePersonalDevelopmentGoal(
      deleteId,
      staffAppraisalDetails.id
    );
  };

  const reloadDetails = () => {
    staffAppraisalsStore.getStaffAppraisalDetails(staffAppraisalDetails.id);
  };

  const resetFields = () => form.resetFields();

  const onSave = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.staff_appraisal_id = staffAppraisalDetails.id;
        if (creatingDevelopmentGoals) {
          await createStaffDevelopmentGoal(values);
          if (isEmpty(addDevelopmentGoalsError)) resetFields();
        } else {
          await updateStaffAppraisalDevelopmentGoal(
            developmentGoalToUpdate.id,
            values
          );
          if (isEmpty(updateDevelopmentGoalsError)) resetFields();
        }
      })
      .catch((err) => {
        message.error("Please fill in the form correctly");
      });
  };

  useEffect(() => {
    if (!creatingDevelopmentGoals && !isEmpty(developmentGoalToUpdate)) {
      form.setFieldsValue(developmentGoalToUpdate);
    } else {
      form.resetFields();
    }
  }, [creatingDevelopmentGoals, developmentGoalToUpdate, form]);

  return (
    <div>
      <>
        <Form onFinish={onSave} form={form} layout="horizontal">
          <Row gutter={4}>
            <Col span={10}>
              {" "}
              <AppInput
                name="development_goal"
                label="Personal Development Goal?"
                rules={[{ required: true, message: "Missing this field" }]}
              />
            </Col>
            <Col span={12}>
              {" "}
              <AppInput
                name="support_needed"
                label="Support Needed?"
                rules={[{ required: true, message: "Missing this field" }]}
              />
            </Col>
            <Col span={2}>
              <AppButton
                text={creatingDevelopmentGoals ? "SAVE" : "SAVE UPDATES"}
                key={"create"}
                block
                loading={addingDevelopmentGoals || updatingDevelopmentGoals}
                onClick={onSave}
                disabled={staffAppraisalDetails.status === "approved"}
              />
            </Col>
          </Row>
        </Form>
        <AntdTable
          title={() => (
            <TableTitle
              setFilterTableNull={setFilterTableNull}
              openAddModal={null}
              refreshTable={reloadDetails}
              search={null}
              exportRecords={null}
            />
          )}
          loading={gettingStaffAppraisalDetails}
          data={
            filterTable === null
              ? staffAppraisalDetails?.development_goals
              : filterTable
          }
          columns={[
            {
              title: "Personal Development Goal",
              dataIndex: "development_goal",
            },
            {
              title: "Support Needed",
              dataIndex: "support_needed",
            },
            {
              title: "ACTION",
              render: (record) => {
                return (
                  <TableButtonActions
                    record={record}
                    confirmDelete={confirmDelete}
                    deleteLoading={
                      staffAppraisalsStore.deletingDevelopmentGoals
                    }
                    disabled={staffAppraisalDetails.status === "approved"}
                    updateRecord={updateRecord}
                    deleteId={deleteId}
                    deleteRecord={deleteRecord}
                  />
                );
              },
            },
          ]}
        />
      </>
    </div>
  );
};

export default PersonalDevelopmentGoals;
