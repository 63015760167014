import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useStaffAppraisalsStore } from "../../../../../config/stores";
import { Form } from "antd";
import { isEmpty, map } from "lodash";
import AppInput from "../../../../common/AppInput";
import AppButton from "../../../../common/AppButton";

const SoftSkillEnhancement = () => {
  const { data: currentUserInfo } = useSelector(
    (state) => state.usersState.currentUserInfo
  );
  const staffAppraisalsStore = useStaffAppraisalsStore();
  const { staffAppraisalDetails } = staffAppraisalsStore;

  const [form] = Form.useForm();

  const skills = useMemo(() => {
    return [
      "Communication Skills",
      "Teamwork & Collaboration",
      "Adaptability & Resilience",
      "Problem-Solving Skills",
      "Leadership & Initiative",
    ];
  }, []);

  useEffect(() => {
    let enhancements = map(skills, (skill) => ({
      soft_skill_enhancement: skill,
    }));
    if (!isEmpty(staffAppraisalDetails.softSkillEnhancements))
      enhancements = map(
        staffAppraisalDetails.softSkillEnhancements,
        (assessment) => ({
          soft_skill_enhancement: assessment.soft_skill_enhancement,
          example_of_application: assessment?.example_of_application,
          rating: assessment?.rating,
          comment: assessment?.comment,
          id: assessment?.id,
        })
      );
    form.setFieldValue("enhancementSkills", enhancements);
  }, [staffAppraisalDetails, form, skills]);
  const onSave = (values) => {
    const data = map(values.enhancementSkills, (enhancement) => ({
      soft_skill_enhancement: enhancement.soft_skill_enhancement,
      example_of_application: enhancement?.example_of_application,
      rating: enhancement?.rating,
      comment: enhancement?.comment,
      id: !isEmpty(staffAppraisalDetails.softSkillEnhancements)
        ? enhancement?.id
        : null,
    }));

    if (isEmpty(staffAppraisalDetails.softSkillEnhancements)) {
      staffAppraisalsStore.createSoftSkillEnhancement(data);
    } else {
      staffAppraisalsStore.updateSoftSkills(data);
    }
  };
  return (
    <div>
      <Form form={form} onFinish={onSave}>
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Soft Skill</th>
              <th>Examples of Application</th>
              <th>Rating (1-5)</th>
              <th>Supervisor Comments for each rating</th>
            </tr>
          </thead>
          <tbody>
            <Form.List
              name="enhancementSkills"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("At least 1 indicator is required")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, _, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <tr key={field.key}>
                      <td>
                        <AppInput
                          name={[field.name, "soft_skill_enhancement"]}
                          type="select"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                          inputAttributes={{
                            variant: "borderless",
                            // disabled: true,
                          }}
                          options={map(skills, (skill) => ({
                            label: skill,
                            value: skill,
                            disabled: true,
                          }))}
                        />
                      </td>
                      <td>
                        <AppInput
                          name={[field.name, "example_of_application"]}
                          type="textarea"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "Enter Example",
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          name={[field.name, "rating"]}
                          type="select"
                          inputAttributes={{
                            variant: "borderless",
                          }}
                          options={[
                            { label: "Unsatisfactory", value: 1 },
                            { label: "Needs Improvement", value: 2 },
                            { label: "Meets Expectations", value: 3 },
                            { label: "Exceeds Expectations", value: 4 },
                            { label: "Outstanding", value: 5 },
                          ]}
                        />
                      </td>
                      <td>
                        <AppInput
                          name={[field.name, "comment"]}
                          type="textarea"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "Enter comment",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </Form.List>
          </tbody>
        </table>
        {currentUserInfo.id === staffAppraisalDetails?.supervisor_id && (
          <AppButton
            type="submit"
            loading={
              staffAppraisalsStore.addingSoftSkillEnhancement ||
              staffAppraisalsStore.updatingSoftSkillEnhancement
            }
            disabled={
              staffAppraisalDetails.status === "approved" ||
              staffAppraisalsStore.gettingStaffAppraisalDetails
            }
            className="w-100"
            text="Save Section"
          />
        )}
      </Form>
    </div>
  );
};

export default SoftSkillEnhancement;
