import { Alert, Button, message, Popover, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { advanceAccountabilityFormActions } from "../../../../config/actions/finance/advanceAccountabilityForm/advanceAccountabilityForm.actions";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";
import generatePDF from "../../../../config/services/generatePdf";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";
import { filter, isEmpty, map } from "lodash";

const { Text } = Typography;

const AdvanceAccountabilityFormExpenditureTable = ({
  handleDelete,
  handleRefreshTable,
  handleExportRecords,
  handleOpenAddModal,
  handleOpenEditModal,
  handleViewDetails,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);
  const {
    advanceAccountabilityFormsExpenditure: data,
    advanceAccountabilityFormsExpenditureLoading: loading,
    advanceAccountabilityFormsExpenditureSuccess: success,
    advanceAccountabilityFormsExpenditureError: error,
    deleteAdvanceAccountabilityFormsExpenditureLoading: deleteLoading,
    deleteAdvanceAccountabilityFormsExpenditureSuccess: deleteSuccess,
    deleteAdvanceAccountabilityFormsExpenditureError: deleteError,
    deleteAdvanceAccountabilityFormsExpenditureMessage: deleteMessage,
    advanceAccountabilityFormsDetails,
  } = useSelector((state) => state.advanceAccountabilityFormsState);
  const currency = getCurrency(
    advanceAccountabilityFormsDetails.projectDetails,
    "approved_budget"
  );
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Cost Driver",
      key: "name",
      render: (record) => (
        <span
          className="text-primary cursor-pointer"
          onClick={() => handleViewDetails(record?.id)}
        >
          {record.item}
        </span>
      ),
    },
    {
      title: "Amount Advanced",
      dataIndex: "budgeted_amount",
      key: "budgeted_ammount",
      render: (text) => numberWithCommas(text),
    },
    {
      title: "Actual Expenditure",
      key: "actual_expenditure",
      dataIndex: "actual_expenditure",
      render: (text) => numberWithCommas(text),
    },
    {
      title: "Variance",
      key: "variance",
      dataIndex: "variance",
      render: (text) => numberWithCommas(text),
    },
    {
      title: "Action",
      key: "operation",
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  /* Functions */
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const advanceAccountabilityFormsArray = data.filter(
      (e) => e.id === deleteId
    );
    handleDelete(
      deleteId,
      advanceAccountabilityFormsArray[0].advance_accountability_form
    );
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const updateRecord = (e) => {
    const advanceAccountabilityFormArr = data.filter(
      (el) => el.id === e.target.id
    );
    dispatch(
      advanceAccountabilityFormActions.editAdvanceAccountabilityFormsExpenditureData(
        advanceAccountabilityFormArr[0]
      )
    );
    handleOpenEditModal(true);
  };
  const viewDetails = (e) => handleViewDetails(e.target.id);

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () => {
    handleOpenAddModal(true);
  };
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const generateRecordsPdf = () => {
    const pdfColumns = [
      "Payment Voucher Number",
      "Requsition Form",
      "Project",
      "Status",
    ];
    let pdfRows = [];
    data?.forEach((record) => {
      const row = [record.id, record.memo_number, record.status.toUpperCase()];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Initiative Payment Voucher - ${new Date().toLocaleDateString()}`,
      "Amani Initiative Payment Voucher.pdf"
    );
  };
  /* Use effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "advanceAccountabilityFormsDocument") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    if (deleteSuccess && deleteMessage) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logDetailsSuccess,
    logDetailsError,
    deleteSuccess,
    deleteMessage,
    deleteError,
  ]);

  const hasNoReportingTemplate = filter(data, {
    has_reporting_template: false,
  });
  const hasReportingTemplate = filter(data, {
    has_reporting_template: true,
  });

  return (
    <div>
      {!isEmpty(hasNoReportingTemplate) &&
        advanceAccountabilityFormsDetails.status !== "approved" && (
          <Alert
            showIcon
            message={`${map(hasNoReportingTemplate, "item").join(
              ", "
            )} cost driver(s) have no reporting template, click on the cost driver to create a reporting template`}
          />
        )}
      {!isEmpty(hasReportingTemplate) &&
        advanceAccountabilityFormsDetails.status === "approved" && (
          <Alert
            showIcon
            message={"Click on the cost driver to view the reporting template"}
          />
        )}
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        scroll={{ x: true }}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
        summary={(records) => {
          //let budgetedAmmountSubtotal = 0;
          let varianceSubTotal = 0;
          let actualExpenditureSubTotal = 0;
          records.forEach((record) => {
            // budgetedAmmountSubtotal += record.budgeted_amount;
            varianceSubTotal += record.variance;
            actualExpenditureSubTotal += record.actual_expenditure;
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <Text type="warning">Sub Totals:</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="warning">{`${
                    currency === "D" ? "USD" : "UGX"
                  } ${numberWithCommas(actualExpenditureSubTotal)}`}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="warning">{`${
                    currency === "D" ? "USD" : "UGX"
                  } ${numberWithCommas(varianceSubTotal)}`}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default AdvanceAccountabilityFormExpenditureTable;
