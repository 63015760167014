import ConfirmAccount from "../../containers/auth/ConfirmAccount";
import Login from "../../containers/auth/Login";
import ResetPassword from "../../containers/auth/ResetPassword";
import Roles from "../../containers/settings/Roles";
import LogDetails from "../../containers/settings/LogDetails";
import Permissions from "../../containers/settings/Permissions";
import SettingsLayout from "../../containers/settings/SettingsLayout";
import AdministrationLayout from "../../containers/administration/AdministrationLayout";
import Assets from "../../containers/administration/assetRegister/Assets";
import Profile from "../../containers/settings/Profile";
import StaffRecords from "../../containers/administration/staffRecords/StaffRecords";
import StoresRegister from "../../containers/administration/StoresRegister/StoresRegister";
import ManualsAndPolicies from "../../containers/administration/ManualsAndPolicies/ManualsAndPolicies";
import StaffDetails from "../../containers/administration/staffRecords/StaffDetails";
import BusinessDevAndPartnerShipsLayout from "../../containers/businessDevelopmentAndPartnerships/BusinessDevAndPartnerShipsLayout";
import Projects from "../../containers/businessDevelopmentAndPartnerships/Projects/Projects";
import ProjectDetails from "../../containers/businessDevelopmentAndPartnerships/Projects/ProjectDetails";
import Donors from "../../containers/businessDevelopmentAndPartnerships/donors/Donors";
import DonorsDetails from "../../containers/businessDevelopmentAndPartnerships/donors/DonorsDetails";
import FinanceLayout from "../../containers/finance/FinanceLayout";
import ChartOfAccounts from "../../containers/finance/chartOfAccounts/ChartOfAccounts";
import Budget from "../../containers/finance/budget/Budget";
import BudgetDetails from "../../containers/finance/budget/BudgetDetails";
import MyProjects from "../../containers/myProjects/MyProjects";
import MyProjectDetails from "../../containers/myProjects/MyProjectDetails";
import RequisitionMemo from "../../containers/finance/requisitionMemos/RequisitionMemo";
import PaymentVoucher from "../../containers/finance/paymentVoucher/PaymentVoucher";
import AdvanceAccountabilityForm from "../../containers/finance/advanceAccountabilityForm/AdvanceAccountabilityForm";
import Procument from "../../containers/procurement/ProcumentLayout";
import Rfq from "../../containers/procurement/rfqs/Rfq";
import RecordOfBidders from "../../containers/procurement/recordOfBidders/RecordOfBidders";
import BidEvaluation from "../../containers/procurement/bidEvaluation/BidEvaluation";
import BidEvaluationForm from "../../containers/procurement/bidEvaluation/BidEvaluationForm";
import NoticeOfBestBidder from "../../containers/procurement/noticeOfBestBidder/NoticeOfBestBidder";
import LocalPurchaseOrder from "../../containers/procurement/LocalPurchaseOrder/LocalPurchaseOrder";
import Dashboard from "../../containers/home/Dashboard";
import {
  FcAbout,
  FcBarChart,
  FcBriefcase,
  FcBusiness,
  FcComboChart,
  FcDiploma2,
  FcDocument,
  FcFilingCabinet,
  FcFolder,
  FcGenealogy,
  FcGlobe,
  FcGoodDecision,
  FcKindle,
  FcMoneyTransfer,
  FcOrgUnit,
  FcPackage,
  FcPhone,
  FcReadingEbook,
  FcRules,
  FcScatterPlot,
  FcSettings,
  FcTemplate,
  FcTimeline,
  FcTodoList,
  FcViewDetails,
  FcCurrencyExchange,
  FcClock,
  FcList,
  FcCloth,
  FcGrid,
  FcReading,
  FcVoicePresentation,
  FcRatings,
  FcEnteringHeavenAlive,
  FcPortraitMode,
} from "react-icons/fc";
import { AiOutlineUser } from "react-icons/ai";
import Accounts from "../../containers/finance/accounts";
import Receipts from "../../containers/finance/receipts";
import MyTimeSheets from "../../containers/TimeSheets/MyTimeSheets";
import TimeSheets from "../../containers/TimeSheets";
import AssetIncidentForm from "../../containers/administration/assetRegister/AssetIncidentForm";
import ActivityConceptNotes from "../../containers/programsMgt/activityConceptNotes";
import StrategicPlans from "../../containers/programsMgt/strategicPlans";
import Cashbook from "../../containers/finance/cashbook";
import ChildProtectionIncidentForms from "../../containers/programsMgt/childProtectionIncidentForms";
import StaffContracts from "../../components/administration/staffContracts";
import StaffAppraisals from "../../components/administration/StaffAppraisals";
import MyStaffAppraisals from "../../components/administration/StaffAppraisals/MyStaffAppraisals";

const routes = {
  authentication: {
    title: "AUTHENTICATION",
    protected: false,
    login: {
      path: "/login",
      title: "AUTH | LOGIN",
      component: Login,
    },
    resetPassword: {
      path: "/resetPassword/:resetToken",
      title: "AUTH | RESET PASSWORD",
      component: ResetPassword,
    },
    confirmAccount: {
      path: "/confirmAccount/:confirmToken",
      title: "AUTH | CONFIRM ACCOUNT",
      component: ConfirmAccount,
    },
  },
  home: {
    path: "/",
    title: "Home",
    name: "Dashboard",
    component: Dashboard,
    order: 0,
    allAccess: true,
    protected: true,
    childRoutes: [],
    icon: FcBarChart,

    dashboard: {
      path: "/",
      title: "Home",
      component: Dashboard,
    },
  },
  settings: {
    title: "SETTINGS",
    path: "/settings",
    icon: FcSettings,
    component: SettingsLayout,
    protected: true,
    order: 6,
    name: "Settings",
    childRoutes: [
      {
        path: "/settings/roles",
        title: "SETTINGS | ROLES",
        component: Roles,
        name: "Roles",
        order: 1,
        icon: FcReadingEbook,
        permissionKey: "Roles",
        protected: true,
      },
      {
        path: "/settings/permisions",
        title: "SETTINGS | PERMISSIONS",
        component: Permissions,
        name: "Permissions",
        order: 2,
        permissionKey: "Log_details",
        icon: FcKindle,
        protected: true,
      },
      {
        path: "/settings/logDetails",
        title: "SETTINGS | LOG DETAILS",
        component: LogDetails,
        name: "Log Details",
        order: 3,
        permissionKey: "Log_details",

        icon: FcAbout,
      },
      {
        path: "/settings/profile",
        title: "SETTINGS | PROFILE",
        component: Profile,
        name: "Profile",
        order: 4,
        allAccess: true,
        icon: AiOutlineUser,
      },
    ],
    //child routes
    roles: {
      path: "/settings/roles",
      title: "SETTINGS | ROLES",
      component: Roles,
      name: "Roles",
      order: 1,
      icon: FcReadingEbook,
      protected: true,
    },
    permissions: {
      path: "/settings/permisions",
      title: "SETTINGS | PERMISSIONS",
      component: Permissions,
      name: "Permissions",
      order: 2,
      icon: FcKindle,
      protected: true,
    },
    logDetails: {
      path: "/settings/logDetails",
      title: "SETTINGS | LOG DETAILS",
      component: LogDetails,
      name: "Log Details",
      order: 3,
      icon: FcAbout,
    },
    profile: {
      path: "/settings/profile",
      title: "SETTINGS | PROFILE",
      component: Profile,
      name: "Profile",
      order: 4,
      icon: AiOutlineUser,
    },
  },
  administration: {
    path: "/administration",
    title: "ADMINISTRATION",
    icon: FcBriefcase,
    order: 1,
    protected: true,
    component: AdministrationLayout,
    name: "Administration",
    childRoutes: [
      {
        path: "/administration/staff-records",
        title: "ADMINISTRATION | STAFF RECORDS",
        component: StaffRecords,
        name: "Staff Records",
        permissionKey: "Staff_Records",
        order: 1,
        protected: true,
        //childRoutes
        childRoutes: [
          {
            path: "/administration/staff-records/:id",
            title: "ADMINISTRATION | STAFF RECORDS DETAILS",
            component: StaffDetails,
          },
        ],
      },
      {
        path: "/administration/staff-contracts",
        title: "ADMINISTRATION | STAFF CONTRACTS",
        component: StaffContracts,
        name: "Staff Contracts",
        permissionKey: "Staff_Contracts",
        order: 2,
        protected: true,
        icon: FcRatings,
      },
      {
        path: "/administration/time-sheets",
        title: "TIME SHEETS",
        component: TimeSheets,
        name: "Time Sheets",
        order: 4,
        protected: true,
        icon: FcList,
        isSingleRoute: true,
        permissionKey: "Time_Sheets",
        childRoutes: [],
      },
      {
        path: "/administration/assets",
        title: "ADMINISTRATION | ASSET REGISTER",
        component: Assets,
        icon: FcFilingCabinet,
        order: 3,
        protected: true,
        name: "Asset Register",
        permissionKey: "Asset_register",
        childRoutes: [
          {
            path: "/administration/assets",
            title: "ADMINISTRATION | ASSET REGISTER",
            component: Assets,
          },
        ],
      },
      {
        path: "/administration/asset-incident-forms",
        title: "ASSET INCIDENT FORMS",
        component: AssetIncidentForm,
        name: "Asset Incident Forms",
        order: 4,
        permissionKey: "Asset_Incident_Forms",
        protected: true,
        icon: FcList,
        isSingleRoute: true,
        childRoutes: [],
      },
      {
        path: "/administration/stores-register",
        title: "ADMINISTRATION | STORES REGISTER",
        name: "Stores Register",
        order: 3,
        protected: true,
        permissionKey: "Stores_Register",
        icon: FcFolder,
        component: StoresRegister,
      },
      {
        path: "/administration/manualsAndPolicies",
        title: "ADMINISTRATION | MANUALS AND POLICIES",
        name: "Manuals And Policies",
        order: 4,
        protected: true,
        icon: FcDocument,
        component: ManualsAndPolicies,
        permissionKey: "Manuals_and_policies",
      },
      {
        path: "/administration/staff-appraisals",
        title: "ADMINISTRATION | STAFF APPRAISALS",
        component: FcEnteringHeavenAlive,
        name: "Staff Appraisals",
        permissionKey: "Staff_Appraisals",
        order: 2,
        protected: true,
        icon: FcRatings,
      },
      {
        path: "/administration/my-staff-contracts",
        title: "ADMINISTRATION | MY STAFF APPRAISALS",
        component: FcPortraitMode,
        name: "My Appraisals",
        allAccess: true,
        order: 2,
        protected: true,
        icon: FcRatings,
      },
    ],
    //child routes
    assetRegister: {
      path: "/administration/assets",
      title: "ADMINISTRATION | ASSET REGISTER",
      component: Assets,
      icon: FcFilingCabinet,
      order: 2,
      protected: true,
      name: "Asset Register",
      assets: {
        path: "/administration/assets",
        title: "ADMINISTRATION | ASSET REGISTER",
        component: Assets,
      },
    },
    staffAppraisals: {
      path: "/administration/staff-appraisals",
      title: "ADMINISTRATION | STAFF APPRAISALS",
      component: StaffAppraisals,
      name: "Staff Appraisals",
      permissionKey: "Staff_Appraisals",
      order: 2,
      protected: true,
      icon: FcEnteringHeavenAlive,
    },
    myAppraisals: {
      path: "/administration/my-staff-contracts",
      title: "ADMINISTRATION | MY STAFF APPRAISALS",
      component: MyStaffAppraisals,
      name: "My Appraisals",
      allAccess: true,
      order: 2,
      protected: true,
      icon: FcPortraitMode,
    },
    staffRecords: {
      path: "/administration/staff-records",
      title: "ADMINISTRATION | STAFF RECORDS",
      component: StaffRecords,
      name: "Staff Records",
      order: 1,
      protected: true,
      //childRoutes
      staffDetails: {
        path: "/administration/staff-records/:id",
        title: "ADMINISTRATION | STAFF RECORDS DETAILS",
        component: StaffDetails,
      },
    },
    staffContracts: {
      path: "/administration/staff-contracts",
      title: "ADMINISTRATION | STAFF CONTRACTS",
      component: StaffContracts,
      name: "Staff Contracts",
      permissionKey: "Staff_Contracts",
      order: 2,
      protected: true,
      icon: FcRatings,
    },
    timeSheets: {
      path: "/administration/time-sheets",
      title: "TIME SHEETS",
      component: TimeSheets,
      name: "Time Sheets",
      order: 4,
      protected: true,
      icon: FcList,
      isSingleRoute: true,
      childRoutes: [],
    },
    assetIncidentForms: {
      path: "/administration/asset-incident-forms",
      title: "ASSET INCIDENT FORMS",
      component: AssetIncidentForm,
      name: "Asset Incident Forms",
      order: 4,
      protected: true,
      icon: FcList,
      isSingleRoute: true,
      childRoutes: [],
    },
    storesRegister: {
      path: "/administration/stores-register",
      title: "ADMINISTRATION | STORES REGISTER",
      name: "Stores Register",
      order: 3,
      protected: true,
      icon: FcFolder,
      component: StoresRegister,
    },
    manualsAndPolicies: {
      path: "/administration/manualsAndPolicies",
      title: "ADMINISTRATION | MANUALS AND POLICIES",
      name: "Manuals And Policies",
      order: 4,
      protected: true,
      icon: FcDocument,
      component: ManualsAndPolicies,
    },
  },
  businessDevelopmentAndPartnerships: {
    path: "/business-development-and-partnerships",
    title: "PROGRAMS MGT",
    component: BusinessDevAndPartnerShipsLayout,
    icon: FcGlobe,
    order: 2,
    protected: true,
    name: "Programs MGT",
    childRoutes: [
      {
        path: "/business-development-and-partnerships/projects",
        title: "PROJECTS",
        component: Projects,
        icon: FcOrgUnit,
        order: 2,
        protected: true,
        name: "Projects",
        permissionKey: "Projects",
        //child Routes
        childRoutes: [
          {
            path: "/business-development-and-partnerships/projects/:projectId",
            title: "PROJECT DETAILS",
            component: ProjectDetails,
          },
        ],
      },
      {
        path: "/business-development-and-partnerships/donors",
        title: "DONORS",
        component: Donors,
        icon: FcGoodDecision,
        permissionKey: "Donors",
        order: 1,
        protected: true,
        name: "Donors",
        //child routes
        childRoutes: [
          {
            path: "/business-development-and-partnerships/donors/:donorId",
            title: "DONORS DETAILS",
            component: DonorsDetails,
          },
        ],
      },
      {
        path: "/business-development-and-partnerships/strategic-plans",
        title: "STRATEGIC PLANS",
        permissionKey: "Strategic_Plans",
        component: StrategicPlans,
        icon: FcGrid,
        order: 4,
        protected: true,
        name: "Strategic Plans",
      },
      {
        path: "/business-development-and-partnerships/activity-concept-notes",
        title: "ACTIVITY CONCEPT NOTES",
        permissionKey: "Activity_Concept_Notes",
        component: ActivityConceptNotes,
        icon: FcCloth,
        order: 3,
        protected: true,
        name: "Activity Concept Notes",
      },
      {
        path: "/business-development-and-partnerships/child-protection-incident-forms",
        title: "Child Protection Incident Forms",
        component: ChildProtectionIncidentForms,
        icon: FcVoicePresentation,
        order: 5,
        protected: true,
        name: "Child Protection Incident Forms",
        permissionKey: "Child_incident_protection_form",
      },
    ],

    //child Routes
    projects: {
      path: "/business-development-and-partnerships/projects",
      title: "PROJECTS",
      component: Projects,
      icon: FcOrgUnit,
      order: 2,
      protected: true,
      name: "Projects",
      //child Routes
      projectDetails: {
        path: "/business-development-and-partnerships/projects/:projectId",
        title: "PROJECT DETAILS",
        component: ProjectDetails,
      },
    },
    donors: {
      path: "/business-development-and-partnerships/donors",
      title: "DONORS",
      component: Donors,
      icon: FcGoodDecision,
      order: 1,
      protected: true,
      name: "Donors",
      //child routes
      donorDetails: {
        path: "/business-development-and-partnerships/donors/:donorId",
        title: "DONORS DETAILS",
        component: DonorsDetails,
      },
    },
    activityConceptNotes: {
      path: "/business-development-and-partnerships/activity-concept-notes",
      title: "ACTIVITY CONCEPT NOTES",
      component: ActivityConceptNotes,
      icon: FcCloth,
      order: 3,
      protected: true,
      name: "Activity Concept Notes",
    },
    childProtectionIncidentForms: {
      path: "/business-development-and-partnerships/child-protection-incident-forms",
      title: "Child Protection Incident Forms",
      component: ChildProtectionIncidentForms,
      icon: FcVoicePresentation,
      order: 5,
      protected: true,
      permissionKey: "Child_incident_protection_form",
    },
    strategicPlans: {
      path: "/business-development-and-partnerships/strategic-plans",
      title: "STRATEGIC PLANS",
      component: StrategicPlans,
      icon: FcGrid,
      order: 4,
      protected: true,
      name: "Strategic Plans",
    },
  },
  finance: {
    path: "/finance",
    title: "FINANCE",
    component: FinanceLayout,
    icon: FcComboChart,
    order: 3,
    protected: true,
    name: "Finance",
    childRoutes: [
      {
        path: "/finance/chartOfAccounts",
        title: "FINANCE | CHART OF ACCOUNTS",
        component: ChartOfAccounts,
        name: "Chart Of Accounts",
        order: 1,
        protected: true,
        icon: FcGenealogy,
        permissionKey: "Chart_of_accounts",
      },
      {
        path: "/finance/accounts",
        title: "FINANCE | ACCOUNTS",
        component: Accounts,
        name: "Accounts",
        order: 2,
        protected: true,
        permissionKey: "Accounts",
        icon: FcCurrencyExchange,
      },
      {
        path: "/finance/receipts",
        title: "FINANCE | RECEIPTS",
        component: Receipts,
        name: "Receipts",
        order: 3,
        protected: true,
        permissionKey: "Receipts",
        icon: FcCurrencyExchange,
      },
      {
        path: "/finance/budget",
        title: "FINANCE | BUDGET",
        component: Budget,
        name: "Budget",
        order: 4,
        protected: true,
        icon: FcBusiness,
        permissionKey: "Budgets",
        childRoutes: [
          {
            path: "/finance/budget/:budgetId",
            title: "FINANCE | BUDGET DETAILS",
            component: BudgetDetails,
          },
        ],
      },
      {
        name: "Requisition Form",
        order: 5,
        protected: true,
        icon: FcRules,
        permissionKey: "Requisition_memo",
        path: "/finance/requisitionMemos/",
        title: "FINANCE | REQUISITION FORM",
        component: RequisitionMemo,
      },
      {
        path: "/finance/paymentVoucher",
        title: "FINANCE | FUNDS ADVANCE/WITHDRAW FORM",
        name: "Funds Advance/Withdraw Form",
        order: 6,
        protected: true,
        permissionKey: "Payment_voucher",
        icon: FcMoneyTransfer,
        component: PaymentVoucher,
      },
      {
        path: "/finance/advanceAccountabilityForm",
        title: "FINANCE | PAYMENT VOUCHER",
        name: "Payment Voucher",
        order: 7,
        protected: true,
        permissionKey: "Advanced_accountability_form",
        icon: FcTodoList,
        component: AdvanceAccountabilityForm,
      },
      {
        path: "/finance/cashbook",
        title: "FINANCE | CASHBOOK",
        name: "Cashbook",
        order: 8,
        protected: true,
        permissionKey: "Cashbook",
        icon: FcReading,
        component: Cashbook,
      },
    ],

    //child routes
    chartOfAccounts: {
      path: "/finance/chartOfAccounts",
      title: "FINANCE | CHART OF ACCOUNTS",
      component: ChartOfAccounts,
      name: "Chart Of Accounts",
      order: 1,
      protected: true,
      icon: FcGenealogy,
    },
    cashbook: {
      path: "/finance/cashbook",
      title: "FINANCE | CASHBOOK",
      name: "Cashbook",
      order: 7,
      protected: true,
      permissionKey: "Cashbook",
      icon: FcReading,
      component: Cashbook,
    },
    accounts: {
      path: "/finance/accounts",
      title: "FINANCE | ACCOUNTS",
      component: Accounts,
      name: "Accounts",
      order: 2,
      protected: true,
      icon: FcScatterPlot,
    },
    receipts: {
      path: "/finance/receipts",
      title: "FINANCE | RECEIPTS",
      component: Receipts,
      name: "Receipts",
      order: 3,
      protected: true,
      icon: FcCurrencyExchange,
    },
    budget: {
      path: "/finance/budget",
      title: "FINANCE | BUDGET",
      component: Budget,
      name: "Budget",
      order: 3,
      protected: true,
      icon: FcBusiness,
      budgetDetails: {
        path: "/finance/budget/:budgetId",
        title: "FINANCE | BUDGET DETAILS",
        component: BudgetDetails,
      },
    },
    requistionMemos: {
      name: "Requisition Form",
      order: 4,
      protected: true,
      icon: FcRules,
      path: "/finance/requisitionMemos/",
      title: "FINANCE | REQUISITION FORM",
      component: RequisitionMemo,
    },
    paymentVoucher: {
      path: "/finance/paymentVoucher",
      title: "FINANCE | FUNDS ADVANCE/WITHDRAW FORM",
      name: "Funds Advance/Withdraw Form",
      order: 5,
      protected: true,
      icon: FcMoneyTransfer,
      component: PaymentVoucher,
    },
    advanceAccountabilityForm: {
      path: "/finance/advanceAccountabilityForm",
      title: "FINANCE | PAYMENT VOUCHER",
      name: "Payment Voucher",
      order: 6,
      protected: true,
      icon: FcTodoList,
      component: AdvanceAccountabilityForm,
    },
  },
  myProjects: {
    path: "/myProjects",
    title: "MY PROJECTS",
    component: MyProjects,
    name: "My Projects",
    order: 4,
    protected: true,
    icon: FcBriefcase,
    isSingleRoute: true,
    childRoutes: [],
    allAccess: true,
    //my project details,
    myProjectDetails: {
      title: "MY PROJECT DETAILS",
      path: "/myProjects/:id",
      component: MyProjectDetails,
    },
  },
  myTimeSheets: {
    path: "/my-time-sheets",
    title: "MY TIME SHEETS",
    component: MyTimeSheets,
    name: "My Time Sheets",
    order: 4,
    allAccess: true,
    protected: true,
    icon: FcClock,
    isSingleRoute: true,
    childRoutes: [],
  },
  procurement: {
    path: "/procurement",
    title: "PROCUREMENT",
    component: Procument,
    icon: FcPackage,
    order: 5,
    protected: true,
    name: "Procurement",
    childRoutes: [
      {
        path: "/procurement/rfq",
        title: "PROCUREMENT | RFQ",
        component: Rfq,
        name: "RFQ",
        icon: FcDiploma2,
        order: 1,
        protected: true,
        permissionKey: "Request_For_Quotation",
      },
      {
        path: "/procurement/recordOfBidders",
        title: "PROCUREMENT | RECORD OF BIDDERS",
        component: RecordOfBidders,
        name: "Record Of Bidders",
        permissionKey: "Receiving_of_bids",
        icon: FcTemplate,
        order: 2,
        protected: true,
      },
      {
        path: "/procurement/bidEvaluation",
        title: "PROCUREMENT | BID EVALUATION",
        component: BidEvaluation,
        name: "Bid Evaluation",
        icon: FcTimeline,
        permissionKey: "Bids_evaluation",
        order: 3,
        protected: true,
        childRoutes: [
          {
            path: "/procurement/bidEvaluation/:id",
            title: "PROCUREMENT | BID EVALUATION FORM",
            component: BidEvaluationForm,
          },
        ],
      },
      {
        path: "/procurement/noticeOfBestBidder/",
        title: "PROCUREMENT | NOTICE OF BEST BIDDER",
        component: NoticeOfBestBidder,
        name: "Notice Of Best Bidder",
        permissionKey: "Notice_of_best_bidder",
        icon: FcPhone,
        order: 4,
        protected: true,
      },
      {
        path: "/procurement/local-purchase-order",
        title: "PROCUREMENT | LOCAL PURCHASE ORDER",
        permissionKey: "Local_purchase_order",
        component: LocalPurchaseOrder,
        name: "Local Purchase Order",
        icon: FcViewDetails,
        order: 5,
      },
    ],

    //rfq
    rfq: {
      path: "/procurement/rfq",
      title: "PROCUREMENT | RFQ",
      component: Rfq,
      name: "RFQ",
      icon: FcDiploma2,
      order: 1,
      protected: true,
    },
    // record of bidders
    recordOfBidders: {
      path: "/procurement/recordOfBidders",
      title: "PROCUREMENT | RECORD OF BIDDERS",
      component: RecordOfBidders,
      name: "Record Of Bidders",
      icon: FcTemplate,
      order: 2,
      protected: true,
    },
    //
    bidEvaluation: {
      path: "/procurement/bidEvaluation",
      title: "PROCUREMENT | BID EVALUATION",
      component: BidEvaluation,
      name: "Bid Evaluation",
      icon: FcTimeline,
      order: 3,
      protected: true,
      //
      bidEvaluationForm: {
        path: "/procurement/bidEvaluation/:id",
        title: "PROCUREMENT | BID EVALUATION FORM",
        component: BidEvaluationForm,
      },
    },
    noticeOfBestBidder: {
      path: "/procurement/noticeOfBestBidder/",
      title: "PROCUREMENT | NOTICE OF BEST BIDDER",
      component: NoticeOfBestBidder,
      name: "Notice Of Best Bidder",
      icon: FcPhone,
      order: 4,
      protected: true,
    },
    //local purchase order
    localPurchaseOrder: {
      path: "/procurement/local-purchase-order",
      title: "PROCUREMENT | LOCAL PURCHASE ORDER",
      component: LocalPurchaseOrder,
      name: "Local Purchase Order",
      icon: FcViewDetails,
      order: 5,
    },
  },
};

export default routes;
