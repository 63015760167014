import React, { useEffect } from "react";
import { useTimeSheetStore } from "../../config/stores";
import { first, groupBy, isEmpty, map, orderBy } from "lodash";
import TimeSheetDetails from "./MyTimeSheets/MangeMyTimeSheets/TimeSheetDetails";
import TimeSheetsTable from "./MyTimeSheets/MangeMyTimeSheets/TimeSheetsTable";
import dayjs from "dayjs";
import { Collapse, Tag } from "antd";
const TimeSheets = () => {
  const timeSheetsStore = useTimeSheetStore();
  useEffect(() => {
    if (isEmpty(timeSheetsStore.timeSheets)) {
      timeSheetsStore.getTimeSheets();
    }
  }, []);

  const timeSheets = orderBy(
    map(
      groupBy(
        timeSheetsStore.timeSheets?.data,
        (timeSheet) => `${timeSheet.month}-${timeSheet.year}`
      ),
      (records) => {
        const context = first(records);

        return {
          timeSheets: records,
          month: dayjs(`${context.month}`).format("MMMM"),
          monthNo: context.month,
          year: context.year,
        };
      }
    ),
    ["year", "month"],
    "desc"
  );

  console.log(timeSheets);

  // <TimeSheetsTable timeSheets={timeSheetsStore.timeSheets?.data} />
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">TIME SHEETS</h5>
      {timeSheetsStore.showTimeSheetDetails ? (
        <TimeSheetDetails />
      ) : (
        <Collapse
          items={map(timeSheets, (timeSheet) => ({
            label: (
              <span className="text-uppercase fw-bold">
                {timeSheet.month} - <Tag color="green">{timeSheet.year}</Tag>
              </span>
            ),
            key: ` ${timeSheet.month} - ${timeSheet.year}`,
            children: <TimeSheetsTable timeSheets={timeSheet.timeSheets} />,
          }))}
        />
      )}
    </div>
  );
};

export default TimeSheets;
