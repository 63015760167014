import React, { useEffect, useState } from "react";
import { useStaffContractsStore } from "../../../config/stores";
import { isEmpty } from "lodash";
import { searchTable } from "../../../config/constants";
import moment from "moment";
import TableButtonActions from "../../common/TableButtonActions";
import TableTitle from "../../common/TableTitle";
import AntdTable from "../../common/AntdTable";
import StaffContractsForm from "./StaffContractsForm";
import StaffContractDetails from "./StaffContractDetails";

const StaffContracts = () => {
  const staffContractStore = useStaffContractsStore();
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const {
    staffContracts,
    getStaffContracts,
    setCreatingStaffContract,
    setShowDetails,
    getStaffContractDetails,
    getMetaData,
  } = staffContractStore;

  const updateRecord = (record) => {
    setCreatingStaffContract(false, true, record);
  };
  const viewDetails = (id) => {
    setShowDetails(true);
    getStaffContractDetails(id);
  };

  const search = (value) => {
    const filterTable = searchTable(staffContracts.data, value);
    setFilterTableNull(filterTable);
  };

  const confirmDelete = () => {
    staffContractStore.deleteStaffContract(deleteId);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const openAddModal = () => {
    staffContractStore.setCreatingStaffContract(true, true, {});
  };

  useEffect(() => {
    if (isEmpty(staffContracts)) {
      getStaffContracts();
      getMetaData();
    }
  }, []);

  const reload = () => {
    getStaffContracts();
    getMetaData();
  };
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold text-uppercase">STAFF CONTRACTS</h5>
      <StaffContractsForm />
      <StaffContractDetails />
      <AntdTable
        loading={staffContractStore.gettingStaffContracts}
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={openAddModal}
            refreshTable={reload}
            search={search}
            exportRecords={null}
          />
        )}
        pageSize={20}
        data={filterTable === null ? staffContracts.data || [] : filterTable}
        columns={[
          {
            title: "Staff",
            render: (record) => {
              return (
                <div>
                  <span className="d-md-none">Staff:</span>
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => viewDetails(record.id)}
                  >
                    {`${record?.user?.first_name} ${record?.user?.other_names}`}
                  </span>{" "}
                  <br />
                  <div className="d-md-none">
                    Start Date :{" "}
                    {moment(record.start_date).format("DD/MM/YYYY")} <br />
                    End Date: {record.end_date} <br />
                    Actions:
                    <TableButtonActions
                      record={record}
                      confirmDelete={confirmDelete}
                      deleteLoading={staffContractStore.deletingStaffContract}
                      viewDetails={viewDetails}
                      updateRecord={updateRecord}
                      deleteId={deleteId}
                      deleteRecord={deleteRecord}
                    />
                  </div>
                </div>
              );
            },
          },
          {
            title: "Start Date",
            key: "start_date",
            dataIndex: "start_date",
            render: (data) => moment(data).format("DD/MM/YYYY"),
            responsive: ["md"],
          },
          {
            title: "End Date",
            key: "end_date",
            dataIndex: "end_date",
            render: (data) => moment(data).format("DD/MM/YYYY"),
            responsive: ["md"],
          },
          {
            title: "Actions",
            key: "actions",
            responsive: ["md"],
            render: (record) => {
              return (
                <TableButtonActions
                  record={record}
                  confirmDelete={confirmDelete}
                  deleteLoading={staffContractStore.deletingStaffContract}
                  viewDetails={viewDetails}
                  updateRecord={updateRecord}
                  deleteId={deleteId}
                  deleteRecord={deleteRecord}
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default StaffContracts;
