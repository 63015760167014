import React, { useEffect } from "react";
import { Col, Divider, Form, message, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import AntdModal from "../../../common/AntdModal";
import AppButton from "../../../common/AppButton";
import { useStaffContractsStore } from "../../../../config/stores";
import AppInput from "../../../common/AppInput";

const StaffAssessmentAreasForm = () => {
  const staffContractStore = useStaffContractsStore();
  const {
    assessmentAreaToUpdate,
    creatingAssessmentArea,
    showCreateAssessmentArea,
    updatingAssessmentArea,
    addingAssessmentArea,
    staffContractDetails,
  } = staffContractStore;
  const [form] = Form.useForm();

  const closeModal = () => {
    staffContractStore.setCreatingStaffAssessmentArea();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        data.staff_contract_id = staffContractDetails.id;
        if (staffContractStore.creatingAssessmentArea) {
          staffContractStore.createStaffAssessmentArea(data);
        } else {
          staffContractStore.updateStaffContractAssessmentArea(
            assessmentAreaToUpdate.id,
            data
          );
        }
      })
      .catch((err) => {
        message.error("Please fill in the form correctly");
      });
  };

  useEffect(() => {
    if (creatingAssessmentArea) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        ...assessmentAreaToUpdate,
      });
    }
  }, [creatingAssessmentArea, assessmentAreaToUpdate, form]);
  return (
    <AntdModal
      title={
        creatingAssessmentArea
          ? "ADD ASSESSMENT AREAS"
          : "EDIT ASSESSMENT AREAS"
      }
      open={showCreateAssessmentArea}
      closeModal={closeModal}
      customSize={1000}
      footer={[
        <AppButton
          text={creatingAssessmentArea ? "SAVE" : "SAVE UPDATES"}
          key={"create"}
          loading={addingAssessmentArea || updatingAssessmentArea}
          onClick={onSubmit}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form layout="vertical" form={form}>
        <AppInput
          label="Assessment Area"
          name="assessment_area"
          rules={[
            {
              required: true,
              message: "Assessment area is required",
            },
          ]}
        />
        {creatingAssessmentArea && (
          <>
            <Divider className="fw-bold text-uppercase">
              Indicators for Annual Appraisal
            </Divider>
            <Form.List
              name="obligations"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("At least 1 indicator is required")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={4} key={field.key}>
                      <Col span={11}>
                        <AppInput
                          label="Key Performance Indicator"
                          name={[field.name, "key_performance_indicator"]}
                          rules={[
                            {
                              required: true,
                              whitespace: false,
                              message: "This field is required",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={11}>
                        <AppInput
                          label="Annual Target"
                          name={[field.name, "annual_target"]}
                        />
                      </Col>
                      <Col span={1}>
                        <Form.Item label=" ">
                          {fields.length > 1 ? (
                            <span className="text-danger mx-1">
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            </span>
                          ) : null}
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <AppButton
                      type="dashed"
                      text="Add"
                      block
                      onClick={() => add()}
                      iconBefore={<PlusOutlined />}
                    />

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        )}
      </Form>
    </AntdModal>
  );
};

export default StaffAssessmentAreasForm;
