import { Card, Form, message } from "antd";
import React, { useEffect } from "react";
import { useStaffAppraisalsStore } from "../../../../../../config/stores";
import AppInput from "../../../../../common/AppInput";
import AppButton from "../../../../../common/AppButton";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const CapacityDevelopmentGoalsForm = ({ usage }) => {
  const { data: currentUserInfo } = useSelector(
    (state) => state.usersState.currentUserInfo
  );
  const staffAppraisalsStore = useStaffAppraisalsStore();
  const [form] = Form.useForm();

  const resetFields = () => form.resetFields();

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (data) => {
        console.log(data);
        const payload = data;
        if (usage === "all") {
          payload.is_first_appraisal = true;
        }
        payload.staff_appraisal_id =
          staffAppraisalsStore.staffAppraisalDetails.id;

        if (staffAppraisalsStore.creatingCapacityDevelopmentGoals) {
          await staffAppraisalsStore.createCapacityDevelopmentGoal([payload]);
          if (isEmpty(staffAppraisalsStore.addCapacityDevelopmentGoalsError)) {
            resetFields();
          }
        } else {
          staffAppraisalsStore.updateCapacityDevelopmentGoal([
            {
              id: staffAppraisalsStore.capacityDevelopmentGoalToUpdate?.id,
              ...payload,
            },
          ]);
          if (
            isEmpty(staffAppraisalsStore.updateCapacityDevelopmentGoalsError)
          ) {
            resetFields();
            staffAppraisalsStore.setCreatingCapacityDevelopmentGoal();
          }
        }
      })
      .catch(() => message.error("Please fill in all fields correctly"));
  };

  useEffect(() => {
    if (staffAppraisalsStore.creatingCapacityDevelopmentGoals) {
      form.resetFields();
    } else {
      form.setFieldsValue(staffAppraisalsStore.capacityDevelopmentGoalToUpdate);
    }
  }, [
    staffAppraisalsStore.creatingCapacityDevelopmentGoals,
    staffAppraisalsStore.capacityDevelopmentGoalToUpdate,
    form,
  ]);
  return (
    <div>
      <Card
        title={
          staffAppraisalsStore.creatingCapacityDevelopmentGoals
            ? "CREATE RECORD"
            : "EDIT RECORD"
        }
      >
        <Form onFinish={onSubmit} form={form} layout="vertical">
          <AppInput
            name="development_target"
            label="Development Target"
            rules={[{ required: true, message: "Missing this field" }]}
          />
          {usage === "all" && (
            <>
              <AppInput
                name="planned_activities"
                label="Planned Activities/Training"
                rules={[{ required: true, message: "Missing this field" }]}
              />
              <AppInput
                name="impact_on_performance"
                label="Impact on Performance"
                rules={[{ required: true, message: "Missing this field" }]}
              />
              <AppInput
                name="resources_needed"
                label="Resources Needed"
                rules={[{ required: true, message: "Missing this field" }]}
              />
              <AppInput
                name="status"
                label="Status"
                type="select"
                options={[
                  { label: "Pending", value: "PENDING" },
                  { value: "WORK_IN_PROGRESS", label: "Work In Progress" },
                  { value: "COMPLETED", label: "Completed" },
                ]}
                rules={[{ required: true, message: "Missing this field" }]}
              />
              <AppInput
                name="rating_scale"
                label="Rating"
                type="select"
                options={[
                  { label: "Unsatisfactory", value: 1 },
                  { label: "Needs Improvement", value: 2 },
                  { label: "Meets Expectations", value: 3 },
                  { label: "Exceeds Expectations", value: 4 },
                  { label: "Outstanding", value: 5 },
                ]}
                rules={[{ required: true, message: "Missing this field" }]}
              />
              <AppInput
                label="Comment"
                name={"supervisor_comment"}
                rules={[
                  {
                    required: true,
                    message: "Comment is required",
                  },
                ]}
                inputAttributes={{
                  placeholder: "Enter comment",
                }}
              />
            </>
          )}
          {usage === "new-development-goals" && (
            <>
              <AppInput
                name="planned_activities"
                label="Planned Activities/Training"
                rules={[{ required: true, message: "Missing this field" }]}
              />
              <AppInput
                name="impact_on_performance"
                label="Impact on Performance"
                rules={[{ required: true, message: "Missing this field" }]}
              />
              <AppInput
                name="resources_needed"
                label="Resources Needed"
                rules={[{ required: true, message: "Missing this field" }]}
              />
            </>
          )}
          {usage === "assessment" && (
            <>
              <AppInput
                name="status"
                label="Status"
                type="select"
                options={[
                  { label: "Pending", value: "PENDING" },
                  { value: "WORK_IN_PROGRESS", label: "Work In Progress" },
                  { value: "COMPLETED", label: "Completed" },
                ]}
                rules={[{ required: true, message: "Missing this field" }]}
              />
              <AppInput
                name="rating_scale"
                label="Rating"
                options={[
                  { label: "Unsatisfactory", value: 1 },
                  { label: "Needs Improvement", value: 2 },
                  { label: "Meets Expectations", value: 3 },
                  { label: "Exceeds Expectations", value: 4 },
                  { label: "Outstanding", value: 5 },
                ]}
                rules={[{ required: true, message: "Missing this field" }]}
              />
              <AppInput
                label="Comment"
                name={"supervisor_comment"}
                rules={[
                  {
                    required: true,
                    message: "Comment is required",
                  },
                ]}
                inputAttributes={{
                  placeholder: "Enter comment",
                }}
              />
            </>
          )}
          {currentUserInfo.id ===
            staffAppraisalsStore.staffAppraisalDetails?.supervisor_id && (
            <AppButton
              text={
                staffAppraisalsStore.creatingCapacityDevelopmentGoals
                  ? "SAVE"
                  : "SAVE UPDATES"
              }
              key={"create"}
              block
              disabled={
                staffAppraisalsStore.staffAppraisalDetails.status === "approved"
              }
              loading={
                staffAppraisalsStore.addingCapacityDevelopmentGoals ||
                staffAppraisalsStore.updatingCapacityDevelopmentGoals
              }
            />
          )}
        </Form>
      </Card>
    </div>
  );
};

export default CapacityDevelopmentGoalsForm;
