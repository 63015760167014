import React, { useEffect } from "react";
import TimeSheetsTable from "./MangeMyTimeSheets/TimeSheetsTable";
import { useTimeSheetStore } from "../../../config/stores";
import { first, groupBy, isEmpty, map, orderBy } from "lodash";
import TimeSheetsForm from "./MangeMyTimeSheets/TimeSheetsForm";
import TimeSheetDetails from "./MangeMyTimeSheets/TimeSheetDetails";
import dayjs from "dayjs";
import { Card, Collapse, Tag } from "antd";
import AppButton from "../../../components/common/AppButton";

const MyTimeSheets = () => {
  const timeSheetsStore = useTimeSheetStore();
  useEffect(() => {
    if (isEmpty(timeSheetsStore.myTimeSheets)) {
      timeSheetsStore.getMyTimeSheets();
    }
  }, []);

  const timeSheets = orderBy(
    map(
      groupBy(
        timeSheetsStore.myTimeSheets?.data,
        (timeSheet) => `${timeSheet.month}-${timeSheet.year}`
      ),
      (records) => {
        const context = first(records);

        return {
          timeSheets: records,
          month: dayjs(`${context.month}`).format("MMMM"),
          monthNo: context.month,
          year: context.year,
        };
      }
    ),
    ["year", "month"],
    "desc"
  );

  const openAddModal = () => {
    timeSheetsStore.setAddingTimeSheet(true, true, {});
  };

  return (
    <div className="container-fluid p-2">
      <Card
        title="MY TIME SHEETS"
        extra={
          <AppButton text="CREATE NEW TIME SHEET" onClick={openAddModal} />
        }
      >
        {timeSheetsStore.showTimeSheetDetails ? (
          <TimeSheetDetails />
        ) : (
          <Collapse
            items={map(timeSheets, (timeSheet) => ({
              label: (
                <span className="text-uppercase fw-bold">
                  {timeSheet.month} - <Tag color="green">{timeSheet.year}</Tag>
                </span>
              ),
              key: ` ${timeSheet.month} - ${timeSheet.year}`,
              children: (
                <TimeSheetsTable canEdit timeSheets={timeSheet.timeSheets} />
              ),
            }))}
          />
        )}
      </Card>

      <TimeSheetsForm />
    </div>
  );
};

export default MyTimeSheets;
