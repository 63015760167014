import React from "react";
import { useStaffAppraisalsStore } from "../../../../config/stores";
import {
  Card,
  Col,
  Collapse,
  Divider,
  Flex,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tabs,
  Tag,
} from "antd";
import moment from "moment";
import AppButton from "../../../common/AppButton";
import {
  AiFillCloseCircle,
  AiOutlineArrowUp,
  AiOutlineReload,
} from "react-icons/ai";
import Achievements from "./Achievements";
import Challenges from "./Challenges";
import PersonalDevelopmentGoals from "./PersonalDevelopmentGoals";
import SelfAssessment from "./SelfAssessment";
import EmployerSection from "./EmployerSection";
import RecordStatus from "../../../common/RecordStatus";
import JointAppraisalSection from "./JointSection";
import SignOff from "./SignOff";

const AppraisalDetails = () => {
  const staffAppraisalStore = useStaffAppraisalsStore();
  const { staffAppraisalDetails, gettingStaffAppraisalDetails } =
    staffAppraisalStore;

  const closeStaffAppraisal = () => staffAppraisalStore.setShowDetails(false);
  return (
    <Card
      type="inner"
      title={
        <>
          <span>
            {`${staffAppraisalDetails?.user?.first_name} ${staffAppraisalDetails?.user?.other_names} APPRAISAL`.toUpperCase()}
          </span>{" "}
          {staffAppraisalDetails.status === "submitted" ? (
            <Tag color="blue">SUBMITTED</Tag>
          ) : (
            <RecordStatus status={staffAppraisalDetails.status} />
          )}
        </>
      }
      extra={
        <Space>
          <Popconfirm
            okText="Yes"
            cancelText="No"
            cancelButtonProps={{
              danger: true,
            }}
            okButtonProps={{
              color: "green",
            }}
            onConfirm={() =>
              staffAppraisalStore.submitStaffAppraisalDetails(
                staffAppraisalDetails.id
              )
            }
            title="Are you sure you want to approve this time sheet"
          >
            {staffAppraisalDetails.status !== "approved" &&
              staffAppraisalDetails.status === "pending" && (
                <AppButton
                  text="Submit"
                  type="submit"
                  loadingText="Submitting..."
                  iconBefore={<AiOutlineArrowUp />}
                  loading={staffAppraisalStore.submitting}
                />
              )}
          </Popconfirm>
          <AppButton
            text="Reload"
            type="default"
            iconBefore={<AiOutlineReload />}
            onClick={() =>
              staffAppraisalStore.getStaffAppraisalDetails(
                staffAppraisalDetails.id
              )
            }
          />
          <AppButton
            text="Close"
            type="default"
            iconBefore={<AiFillCloseCircle />}
            danger
            onClick={closeStaffAppraisal}
          />
        </Space>
      }
    >
      <Flex justify="center">{gettingStaffAppraisalDetails && <Spin />}</Flex>
      <h5 className="text-center fw-bold">AMANI INITIATIVE</h5>
      <p className="text-center fw-bold mb-0">
        STAFF PERFORMANCE APPRAISAL FORM
      </p>
      <p className="fw-bold"> Introduction:</p>
      <span>
        In-order to stay on track with our work, we need to know how we are or
        have performed. In all work situations, there should be individual and
        team desire to be better than what we are. This requires us to evaluate
        and be evaluated. This performance evaluation form is to help us measure
        your performance against standards set in the job descriptions and
        targets. It’s purely a confidential tool and should be taken with very
        honest and open-mindedness.
      </span>
      <Tabs
        type="card"
        className="my-2"
        items={[
          {
            label: (
              <p className="mb-0 fw-bold">
                PART 1: - EMPLOYEE (APPRAISEE) SECTION
              </p>
            ),
            key: "employee-section",
            children: (
              <>
                <table className="table table-sm table-bordered">
                  <tbody>
                    <tr>
                      <td className="fw-bold w-50">Date of Appraisal</td>
                      <td>
                        {moment(
                          staffAppraisalDetails?.date_of_appraisal
                        ).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold w-50">Period</td>
                      <td>
                        {moment(staffAppraisalDetails?.start_date).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        -{" "}
                        {moment(staffAppraisalDetails?.end_date).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="my-1">
                  <span className="fw-bold">Reason for Appraisal:</span>{" "}
                  {staffAppraisalDetails?.reason_for_appraisal}
                </p>
                <Row gutter={4}>
                  <Col span={12}>
                    {" "}
                    <table className="table my-2 table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td className="w-50 fw-bold">
                            Name of Staff (Appraisee):
                          </td>
                          <td>
                            {staffAppraisalDetails?.user?.first_name}{" "}
                            {staffAppraisalDetails?.user?.other_names}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-50 fw-bold">Position:</td>
                          <td>{staffAppraisalDetails?.userRole?.role}</td>
                        </tr>
                        <tr>
                          <td className="w-50 fw-bold">
                            Main Program/Department:
                          </td>
                          <td>{staffAppraisalDetails?.user?.department}</td>
                        </tr>
                        <tr>
                          <td className="w-50 fw-bold">Employee Card No:</td>
                          <td>
                            {
                              staffAppraisalDetails?.staffContract
                                ?.employee_card_no
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="w-50 fw-bold">
                            Date Joined Amani Initiative:
                          </td>
                          <td>
                            {moment(
                              staffAppraisalDetails?.staffContract
                                ?.date_of_joining
                            ).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-50 fw-bold">
                            Date started current Contract:
                          </td>
                          <td>
                            {moment(
                              staffAppraisalDetails?.staffContract?.start_date
                            ).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col span={12}>
                    {" "}
                    <table className="table my-2 table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td className="w-50 fw-bold">
                            Name of Supervisor (Appraiser):
                          </td>
                          <td>
                            {staffAppraisalDetails?.supervisor?.first_name}{" "}
                            {staffAppraisalDetails?.supervisor?.other_names}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-50 fw-bold">Position:</td>
                          <td>{staffAppraisalDetails?.supervisorRole?.role}</td>
                        </tr>
                        <tr>
                          <td className="w-50 fw-bold">
                            Main Program/Department:
                          </td>
                          <td>
                            {staffAppraisalDetails?.supervisor?.department}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Divider orientation="left" className="text-uppercase fw-bold">
                  Section 1: Employee's Self-Appraisal
                </Divider>
                <Collapse
                  defaultActiveKey={[
                    "achievements",
                    "challenges",
                    "development-goals",
                  ]}
                  items={[
                    {
                      label: (
                        <span className="fw-bold">
                          1. What were your key achievements during the
                          appraisal period ?
                        </span>
                      ),
                      key: "achievements",
                      extra:
                        staffAppraisalDetails?.achievements?.length > 0 ? (
                          <Tag color="success">FILLED</Tag>
                        ) : (
                          <Tag color="error">NOT FILLED</Tag>
                        ),
                      children: <Achievements />,
                    },
                    {
                      label: (
                        <span className="fw-bold">
                          2. What challenges did you face, and how did you
                          address them?
                        </span>
                      ),
                      extra:
                        staffAppraisalDetails?.challenges?.length > 0 ? (
                          <Tag color="success">FILLED</Tag>
                        ) : (
                          <Tag color="error">NOT FILLED</Tag>
                        ),
                      key: "challenges",
                      children: <Challenges />,
                    },
                    {
                      label: (
                        <span className="fw-bold">
                          3. What are your personal development goals and
                          support needed for the next appraisal period?
                        </span>
                      ),
                      extra:
                        staffAppraisalDetails?.development_goals?.length > 0 ? (
                          <Tag color="success">FILLED</Tag>
                        ) : (
                          <Tag color="error">NOT FILLED</Tag>
                        ),
                      key: "development-goals",
                      children: <PersonalDevelopmentGoals />,
                    },
                  ]}
                />
                <Divider orientation="left" className="text-uppercase fw-bold">
                  Section 2: Appraisal of Annual Targets self-assessment.
                </Divider>
                <Collapse
                  defaultActiveKey={["self-assessment"]}
                  items={[
                    {
                      label: (
                        <span className="fw-bold">
                          Appraisal of Annual Targets self-assessment
                        </span>
                      ),
                      extra:
                        staffAppraisalDetails?.self_assessment?.length > 0 ? (
                          <Tag color="success">FILLED</Tag>
                        ) : (
                          <Tag color="error">NOT FILLED</Tag>
                        ),
                      key: "self-assessment",
                      children: <SelfAssessment />,
                    },
                  ]}
                />
              </>
            ),
          },
          {
            label: (
              <p className="mb-0 fw-bold">
                PART 2: - EMPLOYER (APPRAISER) SECTION
              </p>
            ),
            key: "employer-section",
            disabled: staffAppraisalDetails.status === "pending",
            children: <EmployerSection />,
          },
          {
            label: (
              <p className="mb-0 fw-bold">
                PART 3: JOINT PLANNING (EMPLOYEE AND EMPLOYEE)
              </p>
            ),
            disabled: staffAppraisalDetails.status === "pending",
            key: "joint-section",
            children: <JointAppraisalSection />,
          },
          {
            label: <p className="mb-0 fw-bold">SIGN OFF</p>,
            disabled: staffAppraisalDetails.status === "pending",
            key: "sign-off",
            children: <SignOff />,
          },
        ]}
      ></Tabs>
    </Card>
  );
};

export default AppraisalDetails;
