import React, { useEffect, useMemo, useState } from "react";
import { useStaffAppraisalsStore } from "../../../../config/stores";
import { Card, Col, Form, message, Row } from "antd";
import { flatMap, includes, isEmpty, map } from "lodash";
import AppInput from "../../../common/AppInput";
import AppButton from "../../../common/AppButton";
import AntdTable from "../../../common/AntdTable";
import TableTitle from "../../../common/TableTitle";
import TableButtonActions from "../../../common/TableButtonActions";

const SelfAssessment = () => {
  const staffAppraisalsStore = useStaffAppraisalsStore();
  const [deleteId, setDeleteId] = useState(null);
  const [filterTable, setFilterTableNull] = useState(null);
  const [form] = Form.useForm();

  const {
    gettingStaffAppraisalDetails,
    staffAppraisalDetails,
    creatingSelfAssessments,
    addingSelfAssessments,
    updatingSelfAssessments,
    selfAssessmentToUpdate,
    createStaffSelfAssessment,
    updateSelfAssessmentsError,
    updateStaffAppraisalSelfAssessment,
    addSelfAssessmentsError,
  } = staffAppraisalsStore;

  const kpi = Form.useWatch("performance_indicator_id", form);

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    staffAppraisalsStore.setCreatingSelfAssessment(false, true, record);
  };

  const confirmDelete = () => {
    staffAppraisalsStore.deleteStaffAppraisalSelfAssessment(
      deleteId,
      staffAppraisalDetails.id
    );
  };

  const reloadDetails = () => {
    staffAppraisalsStore.getStaffAppraisalDetails(staffAppraisalDetails.id);
  };

  const resetFields = () => form.resetFields();

  const onSave = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.staff_appraisal_id = staffAppraisalDetails.id;

        delete values.annual_target;
        if (creatingSelfAssessments) {
          await createStaffSelfAssessment(values);
          if (isEmpty(addSelfAssessmentsError)) resetFields();
        } else {
          await updateStaffAppraisalSelfAssessment(
            selfAssessmentToUpdate.id,
            values
          );
          if (isEmpty(updateSelfAssessmentsError)) resetFields();
        }
      })
      .catch((err) => {
        message.error("Please fill in the form correctly");
      });
  };

  useEffect(() => {
    if (!creatingSelfAssessments && !isEmpty(selfAssessmentToUpdate)) {
      form.setFieldsValue(selfAssessmentToUpdate);
    } else {
      form.resetFields();
    }
  }, [creatingSelfAssessments, selfAssessmentToUpdate, form]);

  const indicators = useMemo(() => {
    return flatMap(
      staffAppraisalDetails?.staffContract?.assessment_areas,
      (area) => {
        return map(area.obligations, (obligation) => ({
          value: obligation.id,
          label: obligation.key_performance_indicator,
          annual_target: obligation?.annual_target,
          disabled: includes(
            map(
              staffAppraisalDetails?.self_assessment,
              "performance_indicator_id"
            ),
            obligation.id
          ),
        }));
      }
    );
  }, [
    staffAppraisalDetails?.staffContract?.assessment_areas,
    staffAppraisalDetails?.self_assessment,
  ]);

  useEffect(() => {
    if (kpi) {
      const indicator = indicators.find((indicator) => {
        return indicator.value === kpi;
      });
      form.setFieldValue("annual_target", indicator.annual_target);
    }
  }, [kpi, indicators, form]);

  return (
    <div>
      <>
        <Row gutter={4}>
          <Col span={8}>
            <Card
              title={creatingSelfAssessments ? "CREATE RECORD" : "EDIT RECORD"}
            >
              <Form onFinish={onSave} form={form} layout="vertical">
                <AppInput
                  name="performance_indicator_id"
                  label="Key Performance Indicators (KPIs)"
                  type="select"
                  options={indicators}
                  inputAttributes={{
                    wordWrap: "break-word",
                  }}
                  rules={[{ required: true, message: "Missing this field" }]}
                />{" "}
                <AppInput
                  name="annual_target"
                  label="Annual Target"
                  inputAttributes={{
                    readOnly: true,
                  }}
                  rules={[{ required: true, message: "Missing this field" }]}
                />
                <AppInput
                  name="quantitative_assessment"
                  label="Quantitative Assessment"
                  type="number"
                  rules={[{ required: true, message: "Missing this field" }]}
                />
                <AppInput
                  name="qualitative_assessment"
                  label="Qualitative Assessment"
                  rules={[{ required: true, message: "Missing this field" }]}
                />
                <AppInput
                  name="comment"
                  label="Employee's Comments"
                  rules={[{ required: true, message: "Missing this field" }]}
                />
                <AppButton
                  text={creatingSelfAssessments ? "SAVE" : "SAVE UPDATES"}
                  key={"create"}
                  block
                  loading={addingSelfAssessments || updatingSelfAssessments}
                  onClick={onSave}
                  disabled={staffAppraisalDetails.status === "approved"}
                />
              </Form>
            </Card>
          </Col>
          <Col span={16}>
            <AntdTable
              title={() => (
                <TableTitle
                  setFilterTableNull={setFilterTableNull}
                  openAddModal={null}
                  refreshTable={reloadDetails}
                  search={null}
                  exportRecords={null}
                />
              )}
              loading={gettingStaffAppraisalDetails}
              data={
                filterTable === null
                  ? staffAppraisalDetails?.self_assessment
                  : filterTable
              }
              columns={[
                {
                  title: "Roles and Responsibilities",
                  dataIndex: "performance_indicator",
                  key: "assessment-area",
                  render: (indicator) =>
                    indicator.assessmentArea?.assessment_area,
                },
                {
                  title: "Key Performance Indicators (KPIs)",
                  dataIndex: "performance_indicator",
                  key: "kpi",
                  render: (row) => row?.key_performance_indicator,
                },
                {
                  title: "Annual Target",
                  dataIndex: "performance_indicator",
                  key: "annual_target",
                  render: (row) => row?.annual_target,
                },
                {
                  title: "Achievement (Quantitative)",
                  dataIndex: "quantitative_assessment",
                },
                {
                  title: "Achievement (Qualitative)",
                  dataIndex: "qualitative_assessment",
                },
                {
                  title: "ACTION",
                  render: (record) => {
                    return (
                      <TableButtonActions
                        record={record}
                        confirmDelete={confirmDelete}
                        deleteLoading={
                          staffAppraisalsStore.deletingSelfAssessments
                        }
                        disabled={staffAppraisalDetails.status === "approved"}
                        updateRecord={updateRecord}
                        deleteId={deleteId}
                        deleteRecord={deleteRecord}
                      />
                    );
                  },
                },
              ]}
            />
          </Col>
        </Row>
      </>
    </div>
  );
};

export default SelfAssessment;
