import React, { useState } from "react";
import { useStaffAppraisalsStore } from "../../../../config/stores";
import { searchTable } from "../../../../config/constants";
import TableButtonActions from "../../../common/TableButtonActions";
import dayjs from "dayjs";
import RecordStatus from "../../../common/RecordStatus";
import AntdTable from "../../../common/AntdTable";
import TableTitle from "../../../common/TableTitle";

const AppraisalTable = ({ appraisals = [], canEdit = false }) => {
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const appraisalStore = useStaffAppraisalsStore();

  const search = (value) => {
    const filterTable = searchTable(appraisals, value);
    setFilterTableNull(filterTable);
  };

  const confirmDelete = () => {
    appraisalStore.deleteStaffAppraisal(deleteId);
    setDeleteId(null);
  };
  const viewDetails = (id) => {
    console.log({
      id,
    });
    appraisalStore.setShowDetails(true);
    appraisalStore.getStaffAppraisalDetails(id);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    appraisalStore.setCreatingStaffAppraisal(false, true, record);
  };

  const columns = [
    {
      title: "Staff",
      render: (record) => {
        return (
          <div>
            <span className="d-md-none">month:</span>
            <span
              className="text-primary cursor-pointer"
              onClick={() => viewDetails(record.id)}
            >
              {" "}
              {`${record.user?.first_name} ${record.user?.other_names}`}
            </span>
            <br />
            <div className="d-md-none">
              Reason : {record.reason} <br />
              Supervisor : {record.supervisor?.first_name} $
              {record.supervisor?.other_names} <br />
              Status: {record.status} <br />
              Actions:
              <TableButtonActions
                record={record}
                confirmDelete={confirmDelete}
                deleteLoading={appraisalStore.deletingStaffAppraisal}
                viewDetails={viewDetails}
                updateRecord={updateRecord}
                deleteId={deleteId}
                deleteRecord={deleteRecord}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Period",
      responsive: ["md"],
      render: (row) =>
        `${dayjs(row.start_date).format("DD/MM/YYYY")} - ${dayjs(
          row.end_date
        ).format("DD/MM/YYYY")}`,
    },
    {
      title: "Supervisor",
      responsive: ["md"],
      render: (row) =>
        `${row?.supervisor?.first_name || ""} ${
          row?.supervisor?.other_names || ""
        }`,
    },
    {
      title: "Reason",
      dataIndex: "reason_for_appraisal",
      responsive: ["md"],
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["md"],
      render: (text) => <RecordStatus status={text} />,
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["md"],
      render: (record) => {
        return (
          <TableButtonActions
            record={record}
            confirmDelete={confirmDelete}
            deleteLoading={appraisalStore.deletingTimeSheet}
            viewDetails={viewDetails}
            updateRecord={canEdit && updateRecord}
            deleteId={deleteId}
            deleteRecord={canEdit && deleteRecord}
          />
        );
      },
    },
  ];

  const openAddModal = () => {
    appraisalStore.setCreatingStaffAppraisal(true, true, {});
  };

  const refreshTable = () => {
    appraisalStore.getMyStaffAppraisals();
    if (!canEdit) {
      appraisalStore.getStaffAppraisals();
    }
  };
  return (
    <div>
      <AntdTable
        loading={
          appraisalStore.gettingStaffAppraisals ||
          appraisalStore.gettingMyStaffAppraisals
        }
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={canEdit && openAddModal}
            refreshTable={refreshTable}
            search={search}
            exportRecords={null}
          />
        )}
        pageSize={20}
        data={filterTable === null ? appraisals || [] : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default AppraisalTable;
