import React, { useCallback, useState } from "react";
import { useStaffContractsStore } from "../../../../config/stores";
import { searchTable } from "../../../../config/constants";
import AntdTable from "../../../common/AntdTable";
import TableTitle from "../../../common/TableTitle";
import TableButtonActions from "../../../common/TableButtonActions";
import WorkAreasForm from "./WorkAreasForm";

const WorkAreas = () => {
  const staffContractStore = useStaffContractsStore();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteResponsibilityId, setDeleteResponsibilityId] = useState();
  const { staffContractDetails, gettingStaffContractDetails } =
    staffContractStore;

  const [filterTable, setFilterTableNull] = useState(null);

  const search = (value) => {
    const filterTable = searchTable(staffContractDetails.workAreas, value);
    setFilterTableNull(filterTable);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    staffContractStore.setCreatingWorkArea(false, true, record);
  };

  const confirmDelete = () => {
    staffContractStore.deleteWorkArea(deleteId, staffContractDetails.id);
  };

  const reloadDetails = () => {
    staffContractStore.getStaffContractDetails(staffContractDetails.id);
  };

  const openAddModal = () =>
    staffContractStore.setCreatingWorkArea(true, true, {});

  const rowExpanded = useCallback((record) => {
    const updateResponsibility = (record) => {
      staffContractStore.setCreatingResponsibility(false, true, record);
    };

    const deleteResponsibility = (id) => setDeleteResponsibilityId(id);

    const confirmDeleteObligation = () => {
      staffContractStore.deleteResponsibility(
        deleteResponsibilityId,
        staffContractDetails.id
      );
    };
    return (
      <AntdTable
        data={record.rolesAndResponsibilities}
        columns={[
          {
            title: "Responsibility",
            dataIndex: "responsibility",
          },
          {
            title: "ACTION",
            render: (record) => {
              return (
                <TableButtonActions
                  record={record}
                  confirmDelete={confirmDeleteObligation}
                  deleteLoading={
                    staffContractStore.deletingStaffContractObligation
                  }
                  updateRecord={updateResponsibility}
                  deleteId={deleteResponsibilityId}
                  deleteRecord={deleteResponsibility}
                />
              );
            },
          },
        ]}
      />
    );
  }, []);

  return (
    <div>
      <WorkAreasForm />
      <AntdTable
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={openAddModal}
            refreshTable={reloadDetails}
            search={search}
            exportRecords={null}
          />
        )}
        loading={gettingStaffContractDetails}
        data={
          filterTable === null ? staffContractDetails.workAreas : filterTable
        }
        expandable={{
          expandRowByClick: true,
          columnWidth: 40,
          expandedRowRender: rowExpanded,
        }}
        columns={[
          {
            title: "Work Area",
            dataIndex: "work_area",
          },
          {
            title: "ACTION",
            render: (record) => {
              return (
                <TableButtonActions
                  record={record}
                  confirmDelete={confirmDelete}
                  deleteLoading={staffContractStore.deletingWorkArea}
                  updateRecord={updateRecord}
                  deleteId={deleteId}
                  deleteRecord={deleteRecord}
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default WorkAreas;
