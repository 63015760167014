import React, { useEffect, useMemo } from "react";
import { useReceiptsStore } from "../../../../config/stores";
import usePrevious from "../../../../components/hooks/usePrevious";
import { find, flatMap, isEmpty, map } from "lodash";
import { Form } from "antd";
import AntdModal from "../../../../components/common/AntdModal";
import { inputNumberFormatter } from "../../../../config/constants";
import AppButton from "../../../../components/common/AppButton";
import AppInput from "../../../../components/common/AppInput";
import dayjs from "dayjs";

const ReceiptsForm = () => {
  const receiptsStore = useReceiptsStore();
  const { createdReceipt, receiptToEdit, creating } = receiptsStore;

  const allProjects = useMemo(() => {
    return flatMap(receiptsStore.metaData.donors, "projects");
  }, [receiptsStore.metaData.donors]);

  const onSubmit = (values) => {
    const donor = find(allProjects, {
      id: values.project_id,
    });
    if (receiptsStore.creating) {
      values.donor_id = donor?.donor || null;
      receiptsStore.createReceipt([values]);
    } else {
      receiptsStore.updateReceipt(values, receiptToEdit.id);
    }
  };
  const prevState = usePrevious({ createdReceipt });

  const closeModal = () => {
    receiptsStore.setCreating(false);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(createdReceipt) &&
      prevState.createdReceipt !== createdReceipt
    ) {
      form.resetFields();
      receiptsStore.setCreating(true, true, {});
    }
  }, [prevState, createdReceipt, form, receiptsStore]);

  useEffect(() => {
    if (!isEmpty(receiptToEdit) && !creating) {
      form.setFieldsValue({
        amount: receiptToEdit.amount,
        donor_id: receiptToEdit.donor_id,
        project_id: receiptToEdit.project_id,
        date: dayjs(receiptToEdit.date),
        details: receiptToEdit.details,
        account_id: receiptToEdit.account_id,
        chart_of_accounts_id: receiptToEdit.chart_of_accounts_id,
        category: receiptToEdit.category,
      });
    }
  }, [receiptToEdit, form, creating]);

  return (
    <div>
      <AntdModal
        open={receiptsStore.showCreateReceipt}
        title={receiptsStore.creating ? "CREATE RECEIPT" : "UPDATE RECEIPT"}
        footer={null}
        closeModal={closeModal}
        size="large"
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={onSubmit}
          form={form}
        >
          <AppInput
            name="date"
            label="Date"
            type="date"
            rules={[
              {
                required: true,
              },
            ]}
          />
          <AppInput
            name="account_id"
            type="select"
            label="Account"
            options={map(receiptsStore.metaData.accounts, (account) => ({
              label: account.name,
              value: account.id,
            }))}
            rules={[
              {
                required: true,
              },
            ]}
          />
          <AppInput
            name="category"
            type="select"
            label="Category"
            options={[
              { label: "Income", value: "Income" },
              { label: "Liability", value: "Liability" },
            ]}
            rules={[
              {
                required: true,
              },
            ]}
          />
          <AppInput
            name="chart_of_accounts_id"
            type="select"
            label="Chart of Accounts"
            options={map(
              receiptsStore.metaData.chartsOfAccounts,
              (account) => ({
                label: account.name,
                value: account.id,
              })
            )}
            rules={[
              {
                required: true,
              },
            ]}
          />
          <AppInput
            name="source_of_funds"
            label="Source of funds"
            rules={[
              {
                required: true,
              },
            ]}
          />
          <AppInput
            name="project_id"
            type="select"
            label="Project"
            rules={[
              {
                required: true,
              },
            ]}
            options={map(allProjects, (project) => ({
              label: project.name,
              value: project.id,
            }))}
          />
          <AppInput
            name="amount"
            type="number"
            label="Amount"
            rules={[
              {
                required: true,
              },
            ]}
            inputAttributes={{
              formatter: inputNumberFormatter,
            }}
          />
          <AppInput name="details" type="textarea" label="Details" />
          <Form.Item className={"mt-3"} wrapperCol={{ offset: 5 }}>
            <AppButton
              text={
                receiptsStore.creating ? "Create Account" : "Update Account"
              }
              loading={
                receiptsStore.creatingReceipt || receiptsStore.updatingReceipt
              }
              type="submit"
            />
          </Form.Item>
        </Form>
      </AntdModal>
    </div>
  );
};

export default ReceiptsForm;
