import React from "react";
import AntdTable from "../../../../components/common/AntdTable";
import PrintOutHeader from "../../../../components/shared/PrintOutHeader";
import { useTimeSheetStore } from "../../../../config/stores";
import dayjs from "dayjs";
import {
  filter,
  find,
  groupBy,
  isEmpty,
  map,
  range,
  sumBy,
  toInteger,
  toUpper,
  trim,
} from "lodash";

const PrintTimeSheet = () => {
  const timeSheetStore = useTimeSheetStore();

  const { timeSheetDetails } = timeSheetStore;
  const month = dayjs(`${timeSheetDetails?.month}`).format("MMMM");
  const firstDay = dayjs(`${timeSheetDetails?.month}`)
    .startOf("month")
    .format("DD");
  const lastDay = dayjs(`${timeSheetDetails?.month}`)
    .endOf("month")
    .format("DD");

  const approvedAt = dayjs(timeSheetDetails.approved_at);
  const getProjectColumns = map(timeSheetDetails.myProjects, (project) => ({
    title: project.name ? toUpper(project?.name) : null,
    dataIndex: trim(project.id),
    align: "center",
    className: "text-center",
    width: 100,
  }));

  const columns = [
    {
      title: "DATE",
      dataIndex: "date",
      fixed: "left",
      width: 120,
      render: (date) => (
        <span
          style={{ cursor: "pointer" }}
          className="text-primary cursor-pointer"
        >
          {date}
        </span>
      ),
    },
    {
      title: "ACTIVITIES",
      width: 600,
      dataIndex: "activities",
    },
    ...getProjectColumns,
    {
      title: "SHARED",
      dataIndex: "SHARED",
      align: "center",
      width: 100,
    },
    {
      title: "TOTAL",
      dataIndex: "total",
      align: "center",
      width: 200,
      render: (total) => (
        <span style={{ cursor: "pointer" }} className="fw-bold">
          {total}
        </span>
      ),
    },
  ];

  const daysOfTheMonth = range(firstDay, toInteger(lastDay) + 1);

  let data = map(daysOfTheMonth, (day) => {
    const dayActivities = filter(
      timeSheetDetails.timeSheetActivities,
      (activity) => {
        // console.log(dayjs(activity.date).date(), day);
        return dayjs(activity.date).date() === day;
      }
    );

    const groupedByProject = map(
      groupBy(dayActivities, "project_id"),
      (activities, key) => {
        return {
          project_id: key,
          is_shared: key === "null",
          hours: sumBy(activities, "hours"),
          activities: map(activities, "activity").join(", "),
        };
      }
    );

    // console.log(dayActivities);
    const total = sumBy(dayActivities, "hours");
    const sharedActivities = filter(groupedByProject, { is_shared: true });
    const projectActivities = filter(groupedByProject, { is_shared: false });

    let activities = [...projectActivities, ...sharedActivities];

    if (isEmpty(activities)) {
      activities = map(timeSheetDetails.myProjects, (project) => ({
        project_id: project.id,
        is_shared: false,
        hours: 0,
        activities: "",
      }));
      activities.push({
        is_shared: true,
        project_id: "null",
        hours: 0,
        activities: "",
      });
    } else {
      activities = map(
        [...(timeSheetDetails?.myProjects || []), { id: "null" }],
        (project) => {
          return find(
            activities,
            (activity) => activity?.project_id === project.id
          );
        }
      );
    }

    return {
      date: `${day}/${timeSheetDetails.month}/${timeSheetDetails.year}`,
      day: day,
      activities: map(dayActivities, "activity").join(", "),
      groupedByProject: activities,
      total,
    };
  });

  console.log(data);

  const totalHours = sumBy(timeSheetDetails.timeSheetActivities, "hours");

  const groupedProjectActivities = groupBy(
    timeSheetDetails.timeSheetActivities,
    "project_id"
  );

  const summary = ["TOTAL", "PERCENTAGE"].map((key) => {
    const groupedByProject = map(
      groupedProjectActivities,
      (activities, projectId) => {
        const totalProjectHours = sumBy(activities, "hours");
        const projectPercentage = (
          (totalProjectHours / totalHours) *
          100
        ).toFixed(1);

        return {
          is_shared: projectId === "null",
          hours: key === "TOTAL" ? totalProjectHours : projectPercentage,
          activities: "",
          project_id: projectId,
        };
      }
    );
    const sharedActivities = filter(groupedByProject, { is_shared: true });
    const projectActivities = filter(groupedByProject, { is_shared: false });

    let activities = [...projectActivities, ...sharedActivities];

    activities = map(
      [...(timeSheetDetails?.myProjects || []), { id: "null" }],
      (project) => {
        return find(
          activities,
          (activity) => activity?.project_id === project.id
        );
      }
    );

    return {
      date: key,
      activities: "",
      total: key === "TOTAL" ? totalHours : "100%",
      groupedByProject: activities,
    };
  });
  data.push(...summary);

  return (
    <div>
      <AntdTable
        title={() => <PrintOutHeader />}
        className="mb-1"
        showHeader={false}
        columns={[
          { dataIndex: "column" },
          {
            dataIndex: "value",
            render: (value) => (
              <span className="text-uppercase fw-bold">{value}</span>
            ),
          },
        ]}
        data={[
          {
            column: "PERIOD",
            value: `${firstDay}-${lastDay} ${month} ${timeSheetDetails.year} `,
          },
          {
            column: "NAME OF STAFF",
            value: `${timeSheetDetails?.user?.first_name || ""} ${
              timeSheetDetails?.user?.other_names || ""
            }`,
          },
          {
            column: "POSITION",
            value: timeSheetDetails?.role?.role || "",
          },
          {
            column: "STATUS",
            value: timeSheetDetails?.status || "",
          },
          {
            column: "APPROVED AT",
            value: approvedAt.isValid()
              ? approvedAt.format("DD/MM/YYYY HH:mm")
              : "NOT APPROVED",
          },
          {
            column: "APPROVED BY",
            value: `${timeSheetDetails?.approved_by?.first_name || "NOT"} ${
              timeSheetDetails?.approved_by?.other_names || " APPROVED"
            }`,
          },
        ]}
      />
      <table className=" table table-sm table-bordered">
        <thead>
          <tr>
            {map(columns, (column) => (
              <th className={column.className} key={column.title}>
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {map(data, (timeSheet) => (
            <tr key={timeSheet.id}>
              <td>{timeSheet.date}</td>
              <td>{timeSheet.activities}</td>
              {map(timeSheet.groupedByProject, (sheet) => (
                <td className="text-center" key={sheet?.project_id}>
                  {sheet?.hours || "-"}
                </td>
              ))}
              <td className="text-center">{timeSheet.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrintTimeSheet;
