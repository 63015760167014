import React, { useRef, useState } from "react";
import { useTimeSheetStore } from "../../../../config/stores";
import { isEmpty } from "lodash";
import { Alert, Card, Col, Popconfirm, Row, Space } from "antd";
import AppLoader from "../../../../components/common/AppLoader";
import dayjs from "dayjs";
import AppButton from "../../../../components/common/AppButton";
import {
  AiFillCloseCircle,
  AiFillSave,
  AiOutlineArrowUp,
  AiOutlinePrinter,
  AiOutlineReload,
} from "react-icons/ai";
import TimeSheetActivitiesTable from "../TimeSheetActivities/TimeSheetActivitiesTable";
import AntdTable from "../../../../components/common/AntdTable";
import TimeSheetActivitiesForm from "./TimeSheetActivitiesForm";
import TimeSheetDayActivities from "../TimeSheetActivities/DayActivities";
import { useReactToPrint } from "react-to-print";
import PrintOutHeader from "../../../../components/shared/PrintOutHeader";
import PrintTimeSheet from "./PrintTimeSheet";

const TimeSheetDetails = () => {
  const timeSheetStore = useTimeSheetStore();
  const [showHeader, setShowHeader] = useState(false);

  const { timeSheetDetails, gettingTimeSheetDetails } = timeSheetStore;
  const month = dayjs(`${timeSheetDetails?.month}`).format("MMMM");

  const closeTimeSheet = () =>
    timeSheetStore.setShowViewTimeSheetDetails(false);
  const firstDay = dayjs(`${timeSheetDetails?.month}`)
    .startOf("month")
    .format("DD");
  const lastDay = dayjs(`${timeSheetDetails?.month}`)
    .endOf("month")
    .format("DD");

  const approvedAt = dayjs(timeSheetDetails.approved_at);

  const componentRef = useRef();

  const pageStyle = `
  @page {size: A4 landscape);

   @media all {
     .pagebreak {
       page-break-before: always;
     }
   }

   @media print {
     html, body {
       height: initial;
       overflow: initial;
       font-size: 12px !important;
     }

     -webkit-transform: rotate(-90deg) scale(.68,.68); 
     -moz-transform:rotate(-90deg) scale(.58,.58)

     @page {size: A4 landscape); 
     -moz-transform:rotate(-90deg) scale(.58,.58)}
       .pagebreak {
       page-break-before: always;
     }
     @page {size: A4 portrait;max-height:100%; max-width:100%}
   }
   .image {
     height: 48px !important,
   }
 `;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    contentRef: componentRef,
    pageStyle,
    onAfterPrint: () => {
      setShowHeader(false);
    },
  });
  const onPrint = () => {
    setShowHeader(true);
    setTimeout(() => {
      handlePrint();
    }, 500);
  };

  return (
    <div>
      {!isEmpty(timeSheetStore.getTimeSheetDetailsError) && (
        <Alert
          message={timeSheetStore.getTimeSheetDetailsError?.message}
          type="error"
          showIcon
          className="my-2"
          action={
            <Space>
              <AppButton
                text="Close"
                type="default"
                iconBefore={<AiFillCloseCircle />}
                danger
                onClick={closeTimeSheet}
              />
            </Space>
          }
        />
      )}
      {isEmpty(timeSheetDetails) &&
      !gettingTimeSheetDetails &&
      isEmpty(timeSheetStore.getTimeSheetDetailsError) ? (
        <AppLoader message="GETTING TIME SHEET DETAILS" />
      ) : (
        <Row>
          <Col span={24}>
            <Card
              title={`${month} ${timeSheetDetails.year} TIME SHEET`.toUpperCase()}
              extra={
                <Space>
                  <Popconfirm
                    okText="Yes"
                    cancelText="No"
                    cancelButtonProps={{
                      danger: true,
                    }}
                    okButtonProps={{
                      color: "green",
                    }}
                    onConfirm={() =>
                      timeSheetStore.approveTimeSheet(timeSheetDetails.id)
                    }
                    title="Are you sure you want to approve this time sheet"
                  >
                    {timeSheetDetails.status !== "approved" &&
                      timeSheetDetails.status !== "pending" && (
                        <AppButton
                          text="Approve"
                          type="submit"
                          loadingText="Approving..."
                          iconBefore={<AiFillSave />}
                          loading={timeSheetStore.approvingTimeSheet}
                        />
                      )}
                  </Popconfirm>
                  <Popconfirm
                    okText="Yes"
                    cancelText="No"
                    cancelButtonProps={{
                      danger: true,
                    }}
                    okButtonProps={{
                      color: "green",
                    }}
                    onConfirm={() =>
                      timeSheetStore.submitTimeSheet(timeSheetDetails.id)
                    }
                    title="Are you sure you want to approve this time sheet"
                  >
                    {timeSheetDetails.status !== "approved" &&
                      timeSheetDetails.status === "pending" && (
                        <AppButton
                          text="Submit"
                          type="submit"
                          loadingText="Submitting..."
                          iconBefore={<AiOutlineArrowUp />}
                          loading={timeSheetStore.submittingTimesSheet}
                        />
                      )}
                  </Popconfirm>
                  <AppButton
                    iconBefore={<AiOutlinePrinter />}
                    text="Download"
                    loading={showHeader}
                    loadingText="Downloading..."
                    onClick={onPrint}
                  />
                  <AppButton
                    text="Reload"
                    type="default"
                    iconBefore={<AiOutlineReload />}
                    onClick={() =>
                      timeSheetStore.getTimeSheetDetails(timeSheetDetails.id)
                    }
                  />
                  <AppButton
                    text="Close"
                    type="default"
                    iconBefore={<AiFillCloseCircle />}
                    danger
                    onClick={closeTimeSheet}
                  />
                </Space>
              }
            >
              <div className="d-none">
                <div ref={componentRef}>
                  <PrintTimeSheet />
                </div>
              </div>
              <div>
                <AntdTable
                  title={
                    showHeader
                      ? () => <PrintOutHeader />
                      : () => (
                          <span className="fw-bold">
                            AMANI INITIATIVE TIME SHEET
                          </span>
                        )
                  }
                  className="mb-1"
                  showHeader={false}
                  columns={[
                    { dataIndex: "column" },
                    {
                      dataIndex: "value",
                      render: (value) => (
                        <span className="text-uppercase fw-bold">{value}</span>
                      ),
                    },
                  ]}
                  data={[
                    {
                      column: "PERIOD",
                      value: `${firstDay}-${lastDay} ${month} ${timeSheetDetails.year} `,
                    },
                    {
                      column: "NAME OF STAFF",
                      value: `${timeSheetDetails?.user?.first_name || ""} ${
                        timeSheetDetails?.user?.other_names || ""
                      }`,
                    },
                    {
                      column: "POSITION",
                      value: timeSheetDetails?.role?.role || "",
                    },
                    {
                      column: "STATUS",
                      value: timeSheetDetails?.status || "",
                    },
                    {
                      column: "APPROVED AT",
                      value: approvedAt.isValid()
                        ? approvedAt.format("DD/MM/YYYY HH:mm")
                        : "NOT APPROVED",
                    },
                    {
                      column: "APPROVED BY",
                      value: `${
                        timeSheetDetails?.approved_by?.first_name || "NOT"
                      } ${
                        timeSheetDetails?.approved_by?.other_names ||
                        " APPROVED"
                      }`,
                    },
                  ]}
                />
                <TimeSheetActivitiesTable showHeader={showHeader} />
              </div>
              <TimeSheetActivitiesForm />
              <TimeSheetDayActivities />
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TimeSheetDetails;
