import React from "react";
import { useStaffContractsStore } from "../../../config/stores";
import AppButton from "../../common/AppButton";
import AntdModal from "../../common/AntdModal";
import { Card, Col, Divider, Input, Row, Tabs } from "antd";
import moment from "moment";
import StaffContractObligations from "./StaffContractObligations";
import { AiOutlineClose, AiOutlineReload } from "react-icons/ai";
import { isEmpty } from "lodash";
import StaffAssessmentAreasForm from "./StaffAssessmentAreas/StaffAssessmentAreasForm";
import StaffAssessmentAreas from "./StaffAssessmentAreas";
import StaffObligationsForm from "./StaffObligationsForm";
import WorkAreasForm from "./WorkAreas/WorkAreasForm";
import WorkAreas from "./WorkAreas";

const StaffContractDetails = () => {
  const staffContractStore = useStaffContractsStore();
  const {
    showStaffContractDetails,
    staffContractDetails,
    gettingStaffContractDetails,
  } = staffContractStore;
  const closeModal = () => staffContractStore.setShowDetails(false);
  const reload = () =>
    staffContractStore.getStaffContractDetails(staffContractDetails.id);
  return (
    <AntdModal
      title={"STAFF CONTRACT DETAILS"}
      open={showStaffContractDetails}
      closeModal={closeModal}
      customSize={1200}
      footer={[
        <AppButton
          iconBefore={<AiOutlineReload />}
          text="RELOAD"
          key={"cancel"}
          onClick={reload}
        />,
        <AppButton
          text="CLOSE"
          key={"cancel"}
          onClick={closeModal}
          danger
          iconBefore={<AiOutlineClose />}
        />,
      ]}
    >
      <Card
        loading={gettingStaffContractDetails && isEmpty(staffContractDetails)}
      >
        <Row gutter={4}>
          <Col span={24} className="my-1">
            <Col span={24}>
              <span className="fw-bold me-1 my-1">Staff</span>
              <Input
                value={`${staffContractDetails?.user?.first_name} ${staffContractDetails?.user?.other_names}`}
              />
            </Col>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="my-1">
            <span className="fw-bold me-1 my-1">Start Date</span>
            <Input
              value={moment(showStaffContractDetails?.start_date).format(
                "DD/MM/YYYY"
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="my-1">
            <span className="fw-bold me-1 my-1">End Date</span>
            <Input
              value={moment(showStaffContractDetails?.end_date).format(
                "DD/MM/YYYY"
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="my-1">
            <span className="fw-bold me-1 my-1">Date Joined</span>
            <Input
              value={moment(showStaffContractDetails?.date_of_joining).format(
                "DD/MM/YYYY"
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="my-1">
            <span className="fw-bold me-1 my-1">Employee Card No.</span>
            <Input value={staffContractDetails?.employee_card_no} />
          </Col>
        </Row>
        <Divider className="fw-bold">Indicators for Annual Appraisal</Divider>
        <StaffAssessmentAreasForm />
        <StaffObligationsForm />
        <WorkAreasForm />
        <Tabs
          type="card"
          items={[
            {
              label: "ASSESSMENT AREAS",
              key: "assessment-areas",
              children: <StaffAssessmentAreas />,
            },
            {
              label: "WORK AREAS",
              key: "work-areas",
              children: <WorkAreas />,
            },
            {
              label: "INDICATORS",
              key: "indictors",
              children: <StaffContractObligations />,
            },
          ]}
        />
      </Card>
    </AntdModal>
  );
};

export default StaffContractDetails;
