import React from "react";
import PropTypes from "prop-types";
import { Button, Popconfirm, Popover, Space } from "antd";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";

const TableButtonActions = ({
  viewDetails,
  updateRecord,
  confirmDelete,
  deleteLoading,
  record,
  deleteId,
  deleteRecord,
  extraActions,
  disabled = false,
}) => {
  const viewRecordDetails = () => viewDetails(record?.id, record);
  const updateRecordDetails = () => updateRecord(record);
  const confirmDeleteRecord = () => confirmDelete(record?.id, record);
  const deleteRow = () => deleteRecord(record?.id, record);
  return (
    <Popover
      placement="bottom"
      trigger="click"
      content={
        <Space direction="vertical">
          {extraActions}
          {viewDetails && (
            <Button
              icon={<AiOutlineEye />}
              className="w-100"
              onClick={viewRecordDetails}
            >
              View
            </Button>
          )}
          {updateRecord && (
            <Button
              type="primary"
              onClick={updateRecordDetails}
              className="w-100"
              icon={<AiOutlineEdit />}
            >
              Edit
            </Button>
          )}
          <Popconfirm
            okText="Yes"
            cancelText="No"
            onConfirm={confirmDeleteRecord}
            title="Are you sure you want to delete this ?"
          >
            {deleteRecord && (
              <Button
                onClick={deleteRow}
                loading={deleteId === record?.id ? deleteLoading : false}
                danger
                type="primary"
                className="w-100"
                icon={<AiOutlineDelete />}
              >
                Delete
              </Button>
            )}
          </Popconfirm>
        </Space>
      }
    >
      <Button disabled={disabled} type="link" className="d-md-none">
        Actions
      </Button>
      <Button disabled={disabled} className="d-none d-md-block">
        Actions
      </Button>
    </Popover>
  );
};

TableButtonActions.propTypes = {
  viewDetails: PropTypes.func,
  updateRecord: PropTypes.func,
  confirmDelete: PropTypes.func,
  deleteLoading: PropTypes.bool,
  record: PropTypes.object,
  deleteId: PropTypes.any,
  deleteRecord: PropTypes.func,
  extraActions: PropTypes.any,
};

export default TableButtonActions;
