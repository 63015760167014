import { Button, message, Popover, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import AssetsTableTitle from "./AssetsTableTitle";
import { assetRegisterActions } from "../../../../config/actions/administration/assetRegister/assetRegister.actions";
import generatePDF from "../../../../config/services/generatePdf";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";

const AssetsTable = ({
  handleDeleteAsset,
  handleRefreshTable,
  handleViewDetails,
  handleExportRecords,
  handleOpenAddModal,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const deleteLoading = useSelector(
    (state) => state.assetRegisterState.deleteAsset.loading
  );
  const { loading, data } = useSelector(
    (state) => state.assetRegisterState.fetchAssets
  );
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const [filterTable, setfilterTable] = useState(null);
  const dispatch = useDispatch();
  /** functions */
  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const exportRecords = () => generateRecordsPdf();

  const deleteAssets = (e) => {
    setDeleteId(e.target.id);
  };
  const confirmDelete = () => {
    handleDeleteAsset(deleteId);
  };
  const updateRecord = (e) => {
    const updateRecordArray = data.filter((r) => r.id === e.target.id);
    dispatch(assetRegisterActions.setUpdateAssetData(updateRecordArray[0]));
    dispatch(appUiActions.toggleEditAssetModal(true));
  };
  const viewAssetDetails = (e) => handleViewDetails(e.target.id);
  const generateRecordsPdf = () => {
    const pdfColumns = [
      "Asset",
      "Staff With Asset",
      "Location",
      "Purchase Amount",
      "Financial Year",
      "Aqusition Date",
      "Depreciation",
      "Net Book Value",
    ];
    let pdfRows = [];
    data.forEach((record) => {
      const row = [
        record.name,
        record.staffWithAssetName,
        record.location,
        record.purchase_ammount,
        record.financial_year,
        new Date(record.procurement_date).toLocaleDateString(),
        record.depreciationValue,
        record.netBookValue,
      ];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Initaive Asset Register - ${new Date().toLocaleDateString()}`,
      `Amani Intiative Asset Regiter.pdf`
    );
  };
  /** use Effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "assetRegister") {
      message.info("Started download");
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetailsSuccess, logDetailsError]);
  const columns = [
    {
      title: "Asset Description",
      key: "name",
      render: (text) => (
        <div>
          <span className="d-md-none">Asset:</span> {text.name} <br />
          <div className="d-md-none">
            Location: {text.location} <br />
            Purchase Amount: {text.purchase_ammount} <br />
            Financial Year: {text.financial_year} <br />
            Acquisition date:{" "}
            {new Date(text.procurement_date).toLocaleDateString()} <br />
            Depreciation: {text.depreciationValue} <br />
            Net Book Value: {text.netBookValue} <br />
            <Popover
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails: viewAssetDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord: deleteAssets,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              <Button>Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      responsive: ["lg"],
    },
    {
      title: "Amount of Purchase",
      dataIndex: "purchase_ammount",
      key: "purchase_ammount",
      responsive: ["lg"],
    },
    {
      title: "Financial Year",
      dataIndex: "financial_year",
      key: "financial_year",
      responsive: ["lg"],
    },
    {
      title: "Acquisition Date",
      dataIndex: "procurement_date",
      render: (text) => <>{new Date(text).toLocaleDateString()}</>,
      responsive: ["lg"],
    },
    {
      title: "Staff With Asset",
      dataIndex: "staffWithAssetName",
      key: "staffWithAssetName",
      responsive: ["lg"],
    },
    {
      title: "Depreciation Value",
      dataIndex: "depreciationValue",
      key: "depreciationValue",
      responsive: ["md"],
    },
    {
      title: "Net Book Value",
      dataIndex: "netBookValue",
      key: "netBookValue",
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "operation",
      responsive: ["md"],
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails: viewAssetDetails,
              updateRecord,
              confirmDelete,
              deleteRecord: deleteAssets,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];

  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const refreshTable = () => {
    handleRefreshTable();
  };
  const openAddModal = () => handleOpenAddModal(true);
  const showTotal = (total) => `Total: ${total}`;

  return (
    <div className="">
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          AssetsTableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default AssetsTable;
