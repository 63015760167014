import React, { useEffect } from "react";
import { useStaffContractsStore } from "../../../config/stores";
import { Col, Form, message, Row } from "antd";
// import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import AntdModal from "../../common/AntdModal";
import AppButton from "../../common/AppButton";
import AppInput from "../../common/AppInput";
import { map } from "lodash";

const StaffContractsForm = () => {
  const staffContractStore = useStaffContractsStore();
  const {
    staffContractToUpdate,
    creating,
    showCreateStaffContract,
    updatingStaffContract,
    creatingStaffContract,
    metaData,
  } = staffContractStore;
  const [form] = Form.useForm();

  const closeModal = () => {
    staffContractStore.setCreatingStaffContract();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        if (staffContractStore.creating) {
          staffContractStore.createStaffContract(data);
        } else {
          staffContractStore.updateStaffContract(
            staffContractToUpdate.id,
            data
          );
        }
      })
      .catch((err) => {
        message.error("Please fill in the form correctly");
      });
  };

  useEffect(() => {
    if (creating) {
      form.setFieldsValue({
        start_date: null,
        end_date: null,
        date_of_joining: null,
      });
    } else {
      form.setFieldsValue({
        ...staffContractToUpdate,
        start_date: dayjs(staffContractToUpdate.start_date),
        end_date: dayjs(staffContractToUpdate.end_date),
        date_of_joining: dayjs(staffContractToUpdate.date_of_joining),
      });
    }
  }, [creating, staffContractToUpdate, form]);
  return (
    <AntdModal
      title={creating ? "ADD STAFF CONTRACT" : "EDIT STAFF CONTRACT"}
      open={showCreateStaffContract}
      closeModal={closeModal}
      customSize={1000}
      footer={[
        <AppButton
          text={creating ? "SAVE" : "SAVE UPDATES"}
          key={"create"}
          loading={creatingStaffContract || updatingStaffContract}
          onClick={onSubmit}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form layout="vertical" form={form}>
        <AppInput
          label="Staff"
          name="user_id"
          type="select"
          rules={[
            {
              required: true,
              message: "Title is required",
            },
          ]}
          options={map(metaData.users, (user) => ({
            label: `${user.first_name} ${user.other_names}`,
            value: user.id,
          }))}
        />
        <Row gutter={4}>
          <Col span={12}>
            <AppInput
              type="date"
              label="Start Date"
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Start date is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AppInput
              type="date"
              label="End Date"
              name="end_date"
              rules={[
                {
                  required: true,
                  message: "End date is required",
                },
              ]}
            />
          </Col>
        </Row>
        <AppInput
          type="date"
          label="Date Joined"
          name="date_of_joining"
          rules={[
            {
              required: true,
              message: "Start date is required",
            },
          ]}
        />
        <AppInput
          label="Employee Card No"
          name={"employee_card_no"}
          rules={[
            {
              required: true,
              message: "Start date is required",
            },
          ]}
        />
        {/* {creating && (
          <>
            <Divider className="fw-bold text-uppercase">
              Assessment Areas
            </Divider>
            <Form.List
              name="assessment_areas"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("At least 1 role and responsibility")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={4} key={field.key}>
                      <Col span={22}>
                        <AppInput
                          label="Assessment Area"
                          name={[field.name, "assessment_area"]}
                          rules={[
                            {
                              required: true,
                              whitespace: false,
                              message: "This field is required",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={1}>
                        <Form.Item label=" ">
                          {fields.length > 1 ? (
                            <span className="text-danger mx-1">
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            </span>
                          ) : null}
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <AppButton
                      type="dashed"
                      text="Add"
                      block
                      onClick={() => add()}
                      iconBefore={<PlusOutlined />}
                    />

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        )} */}
        {/* <Divider className="fw-bold">OTHER ROLES AND RESPONSIBILITIES</Divider> */}
        <AppInput
          label="Other Roles and Responsibilities"
          name={"other_responsibilities"}
          type="textarea"
        />
      </Form>
    </AntdModal>
  );
};

export default StaffContractsForm;
