import React, { useEffect, useState } from "react";
import { useStaffAppraisalsStore } from "../../../../config/stores";
import { Col, Form, message, Row } from "antd";
import { isEmpty } from "lodash";
import AppInput from "../../../common/AppInput";
import AppButton from "../../../common/AppButton";
import AntdTable from "../../../common/AntdTable";
import TableTitle from "../../../common/TableTitle";
import TableButtonActions from "../../../common/TableButtonActions";

const Challenges = () => {
  const staffAppraisalsStore = useStaffAppraisalsStore();
  const [deleteId, setDeleteId] = useState(null);
  const [filterTable, setFilterTableNull] = useState(null);
  const [form] = Form.useForm();

  const {
    gettingStaffAppraisalDetails,
    staffAppraisalDetails,
    creatingChallenge,
    addingChallenge,
    updatingChallenge,
    challengeToUpdate,
    createStaffChallenge,
    updateStaffAppraisalChallenge,
    updateChallengeError,
    addChallengeError,
  } = staffAppraisalsStore;

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    staffAppraisalsStore.setCreatingStaffChallenge(false, true, record);
  };

  const confirmDelete = () => {
    staffAppraisalsStore.deleteStaffAppraisalChallenge(
      deleteId,
      staffAppraisalDetails.id
    );
  };

  const reloadDetails = () => {
    staffAppraisalsStore.getStaffAppraisalDetails(staffAppraisalDetails.id);
  };

  const resetFields = () => form.resetFields();

  const onSave = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.staff_appraisal_id = staffAppraisalDetails.id;
        if (creatingChallenge) {
          await createStaffChallenge(values);
          if (isEmpty(addChallengeError)) resetFields();
        } else {
          await updateStaffAppraisalChallenge(challengeToUpdate.id, values);
          if (isEmpty(updateChallengeError)) resetFields();
        }
      })
      .catch((err) => {
        message.error("Please fill in the form correctly");
      });
  };

  useEffect(() => {
    if (!creatingChallenge && !isEmpty(challengeToUpdate)) {
      form.setFieldsValue(challengeToUpdate);
    } else {
      form.resetFields();
    }
  }, [creatingChallenge, challengeToUpdate, form]);

  return (
    <div>
      <>
        <Form onFinish={onSave} form={form} layout="horizontal">
          <Row gutter={4}>
            <Col span={10}>
              {" "}
              <AppInput
                name="challenge"
                label="Challenge"
                rules={[{ required: true, message: "Missing this field" }]}
              />
            </Col>
            <Col span={12}>
              {" "}
              <AppInput
                name="challenge_solution"
                label="How you addressed the challenge"
                rules={[{ required: true, message: "Missing this field" }]}
              />
            </Col>
            <Col span={2}>
              <AppButton
                text={creatingChallenge ? "SAVE" : "SAVE UPDATES"}
                key={"create"}
                block
                disabled={staffAppraisalDetails.status === "approved"}
                loading={addingChallenge || updatingChallenge}
                onClick={onSave}
              />
            </Col>
          </Row>
        </Form>
        <AntdTable
          title={() => (
            <TableTitle
              setFilterTableNull={setFilterTableNull}
              openAddModal={null}
              refreshTable={reloadDetails}
              search={null}
              exportRecords={null}
            />
          )}
          loading={gettingStaffAppraisalDetails}
          data={
            filterTable === null
              ? staffAppraisalDetails?.challenges
              : filterTable
          }
          columns={[
            {
              title: "Challenge",
              dataIndex: "challenge",
            },
            {
              title: "How you addressed the challenge",
              dataIndex: "challenge_solution",
            },
            {
              title: "ACTION",
              render: (record) => {
                return (
                  <TableButtonActions
                    record={record}
                    disabled={staffAppraisalDetails.status === "approved"}
                    confirmDelete={confirmDelete}
                    deleteLoading={staffAppraisalsStore.deletingChallenge}
                    updateRecord={updateRecord}
                    deleteId={deleteId}
                    deleteRecord={deleteRecord}
                  />
                );
              },
            },
          ]}
        />
      </>
    </div>
  );
};

export default Challenges;
