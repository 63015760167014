import React, { useEffect } from "react";
import { useStaffContractsStore } from "../../../config/stores";
import { Form, message } from "antd";
import { isEmpty, map } from "lodash";
import AppInput from "../../common/AppInput";
import AppButton from "../../common/AppButton";
import AntdModal from "../../common/AntdModal";

const StaffObligationsForm = () => {
  const {
    creatingObligation,
    obligationToUpdate,
    creatingStaffContractObligation,
    createStaffContractObligation,
    updatingStaffContractObligation,
    setCreatingStaffContractObligation,
    createStaffObligationError,
    staffContractDetails,
    updateObligationError,
    updateStaffContractObligation,
    showCreateObligation,
  } = useStaffContractsStore();

  const [form] = Form.useForm();

  const resetFields = () => {
    setCreatingStaffContractObligation();
  };

  const onSave = async (values) => {
    form
      .validateFields()
      .then(async (values) => {
        if (creatingObligation) {
          await createStaffContractObligation(values);
          if (isEmpty(createStaffObligationError)) resetFields();
        } else {
          await updateStaffContractObligation(obligationToUpdate.id, values);
          if (isEmpty(updateObligationError)) resetFields();
        }
      })
      .catch((err) => {
        message.error("Please fill in the form correctly");
      });
  };

  useEffect(() => {
    if (!creatingObligation && !isEmpty(obligationToUpdate)) {
      form.setFieldsValue(obligationToUpdate);
    } else {
      form.resetFields();
    }
  }, [creatingObligation, obligationToUpdate, form]);

  const closeModal = () => setCreatingStaffContractObligation();

  return (
    <AntdModal
      title={creatingObligation ? "ADD" : "EDIT"}
      open={showCreateObligation}
      closeModal={closeModal}
      footer={[
        <AppButton
          text={creatingObligation ? "SAVE" : "SAVE UPDATES"}
          key={"create"}
          loading={
            creatingStaffContractObligation || updatingStaffContractObligation
          }
          onClick={onSave}
        />,
        <AppButton text="CANCEL" key={"cancel"} onClick={closeModal} danger />,
      ]}
    >
      <Form onFinish={onSave} form={form} layout="vertical">
        <AppInput
          name="staff_assessment_area_id"
          label="Assessment Area"
          type="select"
          rules={[{ required: true, message: "Missing this field" }]}
          options={map(staffContractDetails.assessment_areas, (area) => ({
            label: area.assessment_area,
            value: area.id,
          }))}
        />
        <AppInput
          name="key_performance_indicator"
          label="Key Performance Indicator"
          rules={[{ required: true, message: "Missing this field" }]}
        />
        <AppInput
          name="annual_target"
          label="Annual Target"
          rules={[{ required: true, message: "Missing this field" }]}
        />
      </Form>
    </AntdModal>
  );
};

export default StaffObligationsForm;
