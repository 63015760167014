import React, { useCallback, useState } from "react";
import { useStaffContractsStore } from "../../../../config/stores";
import { searchTable } from "../../../../config/constants";
import AntdTable from "../../../common/AntdTable";
import TableButtonActions from "../../../common/TableButtonActions";
import StaffAssessmentAreasForm from "./StaffAssessmentAreasForm";
import { Col, Row } from "antd";
import TableTitle from "../../../common/TableTitle";

const StaffAssessmentAreas = () => {
  const staffContractStore = useStaffContractsStore();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteObligationId, setObligationDeleteId] = useState(null);

  const {
    showStaffContractDetails,
    staffContractDetails,
    gettingStaffContractDetails,
  } = staffContractStore;

  const [filterTable, setFilterTableNull] = useState(null);

  const search = (value) => {
    const filterTable = searchTable(
      showStaffContractDetails.assessment_areas,
      value
    );
    setFilterTableNull(filterTable);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    staffContractStore.setCreatingStaffAssessmentArea(false, true, record);
  };

  const confirmDelete = () => {
    staffContractStore.deleteStaffContractAssessmentArea(
      deleteId,
      staffContractDetails.id
    );
  };

  const reloadDetails = () => {
    staffContractStore.getStaffContractDetails(staffContractDetails.id);
  };

  const openAddNew = () => {
    staffContractStore.setCreatingStaffAssessmentArea(true, true);
  };

  const deleteObligation = (id) => setObligationDeleteId(id);

  const rowExpanded = useCallback((record) => {
    const updateObligation = (record) => {
      staffContractStore.setCreatingStaffContractObligation(
        false,
        true,
        record
      );
    };

    const confirmDeleteObligation = () => {
      staffContractStore.deleteStaffContractObligation(
        deleteObligationId,
        staffContractDetails.id
      );
    };
    return (
      <AntdTable
        data={record.obligations}
        columns={[
          {
            title: "KPI",
            dataIndex: "key_performance_indicator",
          },
          {
            title: "ANNUAL TARGET",
            dataIndex: "annual_target",
          },
          {
            title: "ACTION",
            render: (record) => {
              return (
                <TableButtonActions
                  record={record}
                  confirmDelete={confirmDeleteObligation}
                  deleteLoading={
                    staffContractStore.deletingStaffContractObligation
                  }
                  updateRecord={updateObligation}
                  deleteId={deleteObligationId}
                  deleteRecord={deleteObligation}
                />
              );
            },
          },
        ]}
      />
    );
  }, []);

  return (
    <div>
      <StaffAssessmentAreasForm />
      <Row gutter={4}>
        <Col span={24}>
          <AntdTable
            serialNumber
            title={() => (
              <TableTitle
                setFilterTableNull={setFilterTableNull}
                openAddModal={openAddNew}
                refreshTable={reloadDetails}
                search={search}
                exportRecords={null}
              />
            )}
            loading={gettingStaffContractDetails}
            data={
              filterTable === null
                ? staffContractDetails.assessment_areas || []
                : filterTable
            }
            expandable={{
              expandRowByClick: true,
              columnWidth: 40,
              expandedRowRender: rowExpanded,
            }}
            columns={[
              {
                title: "Assessment Area",
                dataIndex: "assessment_area",
              },
              {
                title: "ACTION",
                render: (record) => {
                  return (
                    <TableButtonActions
                      record={record}
                      confirmDelete={confirmDelete}
                      deleteLoading={staffContractStore.deletingAssessmentArea}
                      updateRecord={updateRecord}
                      deleteId={deleteId}
                      deleteRecord={deleteRecord}
                    />
                  );
                },
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StaffAssessmentAreas;
