import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";

const initialState = {
  gettingStaffContracts: true,
  staffContracts: {},
  staffContractsError: {},

  creating: false,
  creatingStaffContract: false,
  createStaffContractError: {},
  createdStaffContract: null,
  showCreateStaffContract: false,

  updatingStaffContract: false,
  staffContractToUpdate: {},
  updateStaffContractError: {},

  deletingStaffContract: false,
  deleteStaffContractError: {},

  gettingStaffContractDetails: false,
  staffContractDetails: {},
  staffContractDetailsError: {},
  showStaffContractDetails: false,

  updatingStaffContractObligation: false,
  obligationToUpdate: {},
  updateObligationError: {},

  creatingObligation: true,
  showCreateObligation: false,

  creatingStaffContractObligation: false,
  createStaffObligationError: {},

  deletingStaffContractObligation: false,
  deleteStaffObligationError: {},

  metaData: {},
  gettingMetaData: false,
  metaDataError: {},

  creatingAssessmentArea: true,
  addingAssessmentArea: false,
  addedAssessmentArea: null,
  addAssessmentAreaError: {},
  showCreateAssessmentArea: false,

  updatingAssessmentArea: false,
  updatedAssessmentArea: null,
  updateAssessmentAreaError: {},
  assessmentAreaToUpdate: {},

  deletingAssessmentArea: false,
  deletedAssessmentArea: null,
  deleteAssessmentAreaError: {},

  creatingWorkArea: true,
  addingWorkArea: false,
  addedWorkArea: null,
  addWorkAreaError: {},
  showCreateWorkArea: false,

  updatingWorkArea: false,
  updatedWorkArea: null,
  updateWorkAreaError: {},
  workAreaToUpdate: {},

  deletingWorkArea: false,
  deletedWorkArea: null,
  deleteWorkAreaError: {},

  creatingResponsibility: true,
  addingResponsibility: false,
  addedResponsibility: null,
  addResponsibilityError: {},
  showCreateResponsibility: false,

  updatingResponsibility: false,
  updatedResponsibility: null,
  updateResponsibilityError: {},
  responsibilityToUpdate: {},

  deletingResponsibility: false,
  deletedResponsibility: null,
  deleteResponsibilityError: {},
  
};

const store = (set, get) => ({
  ...initialState,

  getMetaData: async () => {
    set({ gettingMetaData: true });
    await axios
      .get("/administration/staff-contracts/meta-data")
      .then((res) => {
        set({ metaData: res });
      })
      .catch((err) => set({ metaDataError: err }))
      .finally(() => set({ gettingMetaData: false }));
  },
  // actions
  getStaffContracts: async () => {
    set({ gettingStaffContracts: true });
    await axios
      .get("/administration/staff-contracts")
      .then((res) => {
        set({ staffContracts: res });
      })
      .catch((err) => set({ staffContractsError: err }))
      .finally(() => set({ gettingStaffContracts: false }));
  },
  getStaffContractDetails: async (id) => {
    set({ gettingStaffContractDetails: true });
    await axios
      .get(`/administration/staff-contracts/${id}`)
      .then((res) => {
        set({ staffContractDetails: res });
      })
      .catch((err) => set({ staffContractDetailsError: err }))
      .finally(() => set({ gettingStaffContractDetails: false }));
  },
  createStaffContract: async (data) => {
    set({
      creatingStaffContract: true,
      createStaffContractError: {},
      createdStaffContract: null,
    });
    await axios
      .post(`/administration/staff-contracts`, data)
      .then((res) => {
        set({ createdStaffContract: res });
        get().getStaffContracts();
        get().setCreatingStaffContract();
        get().setShowDetails(true);
        get().getStaffContractDetails(res.id);
      })
      .catch((err) => set({ createStaffContractError: err }))
      .finally(() => set({ creatingStaffContract: false }));
  },
  updateStaffContract: async (id, data) => {
    set({
      updatingStaffContract: true,
      updateStaffContractError: {},
    });
    await axios
      .put(`/administration/staff-contracts/${id}`, data)
      .then(() => {
        get().setCreatingStaffContract();
        get().getStaffContracts();
        get().setCreatingStaffContract();
      })
      .catch((err) => set({ createStaffContractError: err }))
      .finally(() => set({ updatingStaffContract: false }));
  },
  deleteStaffContract: async (id) => {
    set({
      deletingStaffContract: true,
      deleteStaffContractError: {},
    });
    await axios
      .delete(`/administration/staff-contracts/${id}`)
      .then(() => {
        get().getStaffContracts();
      })
      .catch((err) => set({ deletingStaffContract: err }))
      .finally(() => set({ deletingStaffContract: false }));
  },
  createStaffContractObligation: async (data) => {
    set({
      creatingStaffContractObligation: true,
    });
    await axios
      .post(`/administration/staff-contracts/obligations`, data)
      .then((res) => {
        get().getStaffContractDetails(get().staffContractDetails?.id);
        get().setCreatingStaffContractObligation()
      })
      .catch((err) => set({ createStaffObligationError: err }))
      .finally(() => set({ creatingStaffContractObligation: false }));
  },
  createStaffAssessmentArea: async (data) => {
    set({
      addingAssessmentArea: true,
    });
    await axios
      .post(`/administration/staff-contracts/assessment-areas`, data)
      .then((res) => {
        get().getStaffContractDetails(data.staff_contract_id);
        get().setCreatingStaffAssessmentArea()
      })
      .catch((err) => set({ addAssessmentAreaError: err }))
      .finally(() => set({ addingAssessmentArea: false }));
  },
  createWorkArea: async (data) => {
    set({
      addingWorkArea: true,
    });
    await axios
      .post(`/administration/staff-contracts/work-areas`, data)
      .then((res) => {
        get().getStaffContractDetails(data.staff_contract_id);
        get().setCreatingStaffAssessmentArea()
        set({
          creatingWorkArea: true
        })
      })
      .catch((err) => set({ addWorkAreaError: err }))
      .finally(() => set({ addingWorkArea: false }));
  },
  createResponsibility: async (data) => {
    set({
      addingResponsibility: true,
    });
    await axios
      .post(`/administration/staff-contracts/responsibility`, data)
      .then((res) => {
        get().getStaffContractDetails(data.staff_contract_id);
        get().setCreatingStaffAssessmentArea()
      })
      .catch((err) => set({ addResponsibilityError: err }))
      .finally(() => set({ addingResponsibility: false }));
  },
  updateStaffContractObligation: async (id, data) => {
    set({
      updatingStaffContractObligation: true,
      updateObligationError: {},
    });
    await axios
      .put(`/administration/staff-contracts/obligations/${id}`, data)
      .then(() => {
        get().getStaffContractDetails(get().staffContractDetails?.id);
        get().setCreatingStaffContractObligation()
      })
      .catch((err) => set({ updateObligationError: err }))
      .finally(() => set({ updatingStaffContractObligation: false }));
  },
  updateWorkarea: async (id, data) => {
    set({
      updatingWorkArea: true,
      updateWorkAreaError: {},
    });
    await axios
      .put(`/administration/staff-contracts/work-areas/${id}`, data)
      .then(() => {
        get().getStaffContractDetails(get().staffContractDetails?.id);
        get().setCreatingStaffContractObligation()
      })
      .catch((err) => set({ updateWorkAreaError: err }))
      .finally(() => set({ updatingWorkArea: false }));
  },
  updateStaffContractAssessmentArea: async (id, data) => {
    set({
      updatingAssessmentArea: true,
      updateAssessmentAreaError: {},
    });
    await axios
      .put(`/administration/staff-contracts/assessment-areas/${id}`, data)
      .then(() => {
        get().getStaffContractDetails(data.staff_contract_id);
        get().setCreatingStaffAssessmentArea()
      })
      .catch((err) => set({ updateAssessmentAreaError: err }))
      .finally(() => set({ updatingAssessmentArea: false }));
  },
  deleteStaffContractObligation: async (id, staffContractId) => {
    set({
      deletingStaffContractObligation: true,
      deleteStaffObligationError: {},
    });
    await axios
      .delete(`/administration/staff-contracts/obligations/${id}`)
      .then(() => {
        get().getStaffContractDetails(staffContractId);

      })
      .catch((err) => set({ deleteStaffObligationError: err }))
      .finally(() => set({ deletingStaffContractObligation: false }));
  },
  deleteWorkArea: async (id, staffContractId) => {
    set({
      deletingWorkArea: true,
      deleteWorkAreaError: {},
    });
    await axios
      .delete(`/administration/staff-contracts/work-areas/${id}`)
      .then(() => {
        get().getStaffContractDetails(staffContractId);

      })
      .catch((err) => set({ deleteWorkAreaError: err }))
      .finally(() => set({ deletingWorkArea: false }));
  },
  deleteResponsibility: async (id, staffContractId) => {
    set({
      deletingResponsibility: true,
      deleteResponsibilityError: {},
    });
    await axios
      .delete(`/administration/staff-contracts/work-areas/${id}`)
      .then(() => {
        get().getStaffContractDetails(staffContractId);

      })
      .catch((err) => set({ deleteResponsibilityError: err }))
      .finally(() => set({ deletingResponsibility: false }));
  },
  deleteStaffContractAssessmentArea: async (id, staffContractId) => {
    set({
      deletingAssessmentArea: true,
      deleteAssessmentAreaError: {},
    });
    await axios
      .delete(`/administration/staff-contracts/assessment-areas/${id}`)
      .then(() => {
        get().getStaffContractDetails(staffContractId);
      })
      .catch((err) => set({ deleteAssessmentAreaError: err }))
      .finally(() => set({ deletingAssessmentArea: false }));
  },

  setCreatingStaffContract: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      creating: creating,
      showCreateStaffContract: show,
      staffContractToUpdate: data,
    });
  },
  setCreatingWorkArea: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      creatingWorkArea: creating,
      showCreateWorkArea: show,
      workAreaToUpdate: data,
    });
  },
  setCreatingResponsibility: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      creatingResponsibility: creating,
      showCreateResponsibility: show,
      responsibilityToUpdate: data,
    });
  },
  setCreatingStaffAssessmentArea: async (
    creating = false,
    show = false,
    data = {}
  ) => {
    set({
      creatingAssessmentArea: creating,
      showCreateAssessmentArea: show,
      assessmentAreaToUpdate: data,
    });
  },
  setCreatingStaffContractObligation: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      obligationToUpdate: data,
      creatingObligation: creating,
      showCreateObligation: show,
    });
  },
  setShowDetails: (show) => {
    set({ showStaffContractDetails: show, staffContractDetails: {} });
  },
});

const storeName = "STAFF CONTRACTS";
const useStaffContractsStore = create(devtools(store, { name: storeName }), {
  name: storeName,
});

export default useStaffContractsStore;
