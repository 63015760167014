import React, { useEffect } from "react";
import { useStaffAppraisalsStore } from "../../../../../config/stores";
import { Form } from "antd";
import AppInput from "../../../../common/AppInput";
import { map } from "lodash";
import AppButton from "../../../../common/AppButton";
import { useSelector } from "react-redux";

const AnnualTargets = () => {
  const { data: currentUserInfo } = useSelector(
    (state) => state.usersState.currentUserInfo
  );
  const staffAppraisalsStore = useStaffAppraisalsStore();
  const { staffAppraisalDetails } = staffAppraisalsStore;

  const [form] = Form.useForm();

  useEffect(() => {
    const annualTargets = map(
      staffAppraisalDetails.self_assessment,
      (assessment) => ({
        id: assessment.id,
        kpi: assessment?.performance_indicator?.key_performance_indicator,
        target: assessment?.performance_indicator?.annual_target,
        achievement: assessment?.quantitative_assessment,
        rating_scale: assessment?.rating_scale,
        supervisor_comment: assessment?.supervisor_comment,
      })
    );
    form.setFieldValue("annual_targets", annualTargets);
  }, [staffAppraisalDetails, form]);

  const onSave = (values) => {
    const data = map(values.annual_targets, (target) => ({
      supervisor_comment: target.supervisor_comment,
      rating_scale: target?.rating_scale,
      id: target?.id,
    }));

    staffAppraisalsStore.updateStaffAppraisalAnnualTargets(data);
  };
  return (
    <div>
      <Form form={form} onFinish={onSave}>
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Roles and Responsibilities</th>
              <th>Key Performance Indicators (KPIs)</th>
              <th>Target</th>
              <th>Achieved (based on employee information)</th>
              <th>Rating (1-5)</th>
              <th>Supervisor Comments for each rating</th>
            </tr>
          </thead>
          <tbody>
            <Form.List
              name="annual_targets"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("At least 1 indicator is required")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, _, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <tr key={field.key}>
                      <td>
                        <AppInput
                          name={[field.name, "id"]}
                          type="select"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                          inputAttributes={{
                            variant: "borderless",
                            disabled: true,
                          }}
                          options={map(
                            staffAppraisalDetails?.self_assessment,
                            (assessment) => ({
                              label:
                                assessment?.performance_indicator
                                  ?.assessmentArea?.assessment_area,
                              value: assessment?.id,
                            })
                          )}
                        />
                      </td>
                      <td>
                        <AppInput
                          name={[field.name, "kpi"]}
                          type="textarea"
                          inputAttributes={{
                            variant: "borderless",
                            readOnly: true,
                            rows: 2,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          name={[field.name, "target"]}
                          inputAttributes={{
                            variant: "borderless",
                            readOnly: true,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          name={[field.name, "achievement"]}
                          inputAttributes={{
                            variant: "borderless",
                            readOnly: true,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          name={[field.name, "rating_scale"]}
                          type="select"
                          rules={[
                            {
                              required: true,
                              message: "Rating is required",
                            },
                          ]}
                          inputAttributes={{
                            variant: "borderless",
                          }}
                          options={[
                            { label: "Unsatisfactory", value: 1 },
                            { label: "Needs Improvement", value: 2 },
                            { label: "Meets Expectations", value: 3 },
                            { label: "Exceeds Expectations", value: 4 },
                            { label: "Outstanding", value: 5 },
                          ]}
                        />
                      </td>
                      <td>
                        <AppInput
                          name={[field.name, "supervisor_comment"]}
                          rules={[
                            {
                              required: true,
                              message: "Comment is required",
                            },
                          ]}
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "Enter comment",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </Form.List>
          </tbody>
        </table>
        {currentUserInfo.id === staffAppraisalDetails?.supervisor_id && (
          <AppButton
            type="submit"
            loading={staffAppraisalsStore.updatingAnnualTargets}
            disabled={staffAppraisalDetails.status === "approved"}
            className="w-100"
            text="Save Section"
          />
        )}
      </Form>
    </div>
  );
};

export default AnnualTargets;
