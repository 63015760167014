import { Collapse } from "antd";
import React from "react";
import CapacityDevelopmentGoals from "../EmployerSection/CapacityDevelopmentGoals";
import GoalsForNextPeriod from "./GoalsForNextPeriod";

const JointAppraisalSection = () => {
  return (
    <div>
      <Collapse
        defaultActiveKey={[
          "annual-targets",
          "soft-skills-enhancement",
          "capacity-development-goals",
          "overall-performance",
        ]}
        items={[
          {
            label: (
              <span className="fw-bold">
                Section 7: Capacity Development Targets
              </span>
            ),
            key: "capacity-development-goals",
            children: (
              <CapacityDevelopmentGoals usage="new-development-goals" />
            ),
          },
          {
            label: (
              <span className="fw-bold">
                Section 8: Goals for the Next Appraisal Period
              </span>
            ),
            key: "goals-for-next-period",
            children: <GoalsForNextPeriod />,
          },
        ]}
      />
    </div>
  );
};

export default JointAppraisalSection;
