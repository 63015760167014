import React, { useEffect } from "react";
import { useStaffAppraisalsStore } from "../../../../../config/stores";
import { Form, message } from "antd";
import { useForm } from "antd/es/form/Form";
import AppButton from "../../../../common/AppButton";
import AppInput from "../../../../common/AppInput";

const OverallPerformance = () => {
  const staffAppraisalStore = useStaffAppraisalsStore();
  const { creating, staffAppraisalDetails } = staffAppraisalStore;
  const [form] = useForm();

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        const payload = data;
        staffAppraisalStore.updateStaffAppraisal(
          staffAppraisalDetails.id,
          payload
        );
      })
      .catch((err) => message.error("Please fill in all fields correctly"));
  };

  useEffect(() => {
    form.setFieldsValue({
      achievement_of_target_rating:
        staffAppraisalDetails.achievement_of_target_rating,
      soft_skills_enhancement: staffAppraisalDetails?.soft_skills_enhancement,
      capacity_development_goals_rating:
        staffAppraisalDetails?.capacity_development_goals_rating,
      overall_performance_rating:
        staffAppraisalDetails?.overall_performance_rating,
    });
  }, [creating, staffAppraisalDetails, form]);

  return (
    <>
      <Form layout="vertical" form={form}>
        <AppInput
          label="Achievement of Targets"
          name="achievement_of_target_rating"
          options={[
            { label: "Unsatisfactory", value: 1 },
            { label: "Needs Improvement", value: 2 },
            { label: "Meets Expectations", value: 3 },
            { label: "Exceeds Expectations", value: 4 },
            { label: "Outstanding", value: 5 },
          ]}
          type="select"
          rules={[{ required: true, message: "This field is required" }]}
        />
        <AppInput
          label="Soft Skills Enhancement"
          name="soft_skills_enhancement"
          options={[
            { label: "Unsatisfactory", value: 1 },
            { label: "Needs Improvement", value: 2 },
            { label: "Meets Expectations", value: 3 },
            { label: "Exceeds Expectations", value: 4 },
            { label: "Outstanding", value: 5 },
          ]}
          type="select"
          rules={[{ required: true, message: "This field is required" }]}
        />
        <AppInput
          label="Capacity Development Goals"
          name="capacity_development_goals_rating"
          options={[
            { label: "Unsatisfactory", value: 1 },
            { label: "Needs Improvement", value: 2 },
            { label: "Meets Expectations", value: 3 },
            { label: "Exceeds Expectations", value: 4 },
            { label: "Outstanding", value: 5 },
          ]}
          type="select"
          rules={[{ required: true, message: "This field is required" }]}
        />
        <AppInput
          label="Overall Performance"
          name="overall_performance_rating"
          options={[
            { label: "Unsatisfactory", value: 1 },
            { label: "Needs Improvement", value: 2 },
            { label: "Meets Expectations", value: 3 },
            { label: "Exceeds Expectations", value: 4 },
            { label: "Outstanding", value: 5 },
          ]}
          type="select"
          rules={[{ required: true, message: "This field is required" }]}
        />
        <AppButton
          text="SAVE OVERALL SUMMARY"
          key={"create"}
          block
          disabled={staffAppraisalDetails.status === "approved"}
          loading={staffAppraisalStore.updatingStaffAppraisal}
          onClick={onSubmit}
        />
      </Form>
      {/* <Divider className="m-1" /> */}
    </>
  );
};

export default OverallPerformance;
