import { Button, Form, Modal, Upload } from "antd";
import React from "react";
import { FcHighPriority } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { appUiActions } from "../../../../config/actions/app/appUi.actions";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";

const AddManualsAndPolicies = ({ handleAddManualsAndPolicies }) => {
  const dispatch = useDispatch();
  const { error, success, loading } = useSelector(
    (state) => state.manualsAndPoliciesState.addManualsAndPolicies
  );
  const onFinish = (values) => {
    values.createdAt = new Date();
    const formData = new FormData();
    formData.append("document", values.document.file.originFileObj);
    handleAddManualsAndPolicies(formData);
  };
  const visible = useSelector(
    (state) =>
      state.AppUi.admisnistration.manualsAndPolicies.addManualsAndPoliciesModal
  );
  const closeAddRoleModal = () => {
    dispatch(appUiActions.toggleAddManualsAndPoliciesModal(false));
  };

  return (
    <div>
      <Modal
        onCancel={closeAddRoleModal}
        open={visible}
        title="ADD NEW FILE"
        footer={null}
      >
        <Form validateTrigger="onBlur" layout="vertical" onFinish={onFinish}>
          {!success && error && (
            <div className="alert alert-danger">
              <FcHighPriority /> &nbsp; {error}
            </div>
          )}
          <Form.Item
            name="document"
            rules={[{ required: true, message: "Please add a file" }]}
          >
            <Dragger multiple={false}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Form.Item>
          <Form.Item>
            <Button
              className={"w-100"}
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddManualsAndPolicies;
