import { Form, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import { useStaffAppraisalsStore } from "../../../../../config/stores";
import AppInput from "../../../../common/AppInput";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import AppButton from "../../../../common/AppButton";

const CommentForm = ({ usage = "employee" }) => {
  const { data: currentUserInfo } = useSelector(
    (state) => state.usersState.currentUserInfo
  );
  const staffAppraisalStore = useStaffAppraisalsStore();
  const { creating, staffAppraisalDetails } = staffAppraisalStore;
  const [form] = useForm();

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        let payload = data;
        if (usage === "approval") {
          if (currentUserInfo.id === staffAppraisalDetails?.user_id) {
            return message.error("You are not allowed to edit this section");
          }
          staffAppraisalStore.approveAppraisal(staffAppraisalDetails.id, {
            executive_director_comment: data.executive_director_comment,
            executive_director_date: data.executive_director_date,
          });
        } else {
          if (usage === "employee") {
            payload = {
              employee_comment: data.employee_comment,
              employee_comment_date: data.employee_comment_date,
            };
          } else if (usage === "employer") {
            payload = {
              appraiser_comment: data.appraiser_comment,
              appraiser_comment_date: data.appraiser_comment_date,
            };
            if (currentUserInfo.id === staffAppraisalDetails?.user_id) {
              return message.error("You are not allowed to edit this section");
            }
          } else if (usage === "hod") {
            payload = {
              head_of_department_comment: data.head_of_department_comment,
              head_of_department_date: data.head_of_department_date,
              head_of_department_id: currentUserInfo.id,
            };
            if (currentUserInfo.id === staffAppraisalDetails?.user_id) {
              return message.error("You are not allowed to edit this section");
            }
          }

          return staffAppraisalStore.updateStaffAppraisal(
            staffAppraisalDetails.id,
            payload
          );
        }
      })
      .catch((err) => message.error("Please fill in all fields correctly"));
  };

  useEffect(() => {
    form.setFieldsValue({
      employee_comment: staffAppraisalDetails?.employee_comment,
      appraiser_comment: staffAppraisalDetails?.appraiser_comment,
      executive_director_comment:
        staffAppraisalDetails?.executive_director_comment,
      head_of_department_comment:
        staffAppraisalDetails?.head_of_department_comment,
      employee_comment_date: dayjs(
        staffAppraisalDetails.employee_comment_date || new Date()
      ),
      appraiser_comment_date: dayjs(
        staffAppraisalDetails.appraiser_comment_date || new Date()
      ),
      head_of_department_date: dayjs(
        staffAppraisalDetails.head_of_department_date || new Date()
      ),
      executive_director_date: dayjs(
        staffAppraisalDetails.executive_director_date || new Date()
      ),
    });
  }, [creating, staffAppraisalDetails, form]);

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        {usage === "employee" && (
          <>
            <AppInput
              label="Employee Comment"
              name="employee_comment"
              inputAttributes={{
                readOnly: currentUserInfo.id !== staffAppraisalDetails?.user_id,
              }}
              type="textarea"
              rules={[{ required: true, message: "This field is required" }]}
            />
            <AppInput
              label="Date"
              name="employee_comment_date"
              type="date"
              inputAttributes={{
                disabled: true,
              }}
              rules={[{ required: true, message: "This field is required" }]}
            />
          </>
        )}
        {usage === "employer" && (
          <>
            <AppInput
              label="Employer Comment"
              name="appraiser_comment"
              inputAttributes={{
                readOnly:
                  currentUserInfo.id !== staffAppraisalDetails?.supervisor_id,
              }}
              type="textarea"
              rules={[{ required: true, message: "This field is required" }]}
            />
            <AppInput
              label="Date"
              name="appraiser_comment_date"
              inputAttributes={{
                disabled: true,
              }}
              type="date"
              rules={[{ required: true, message: "This field is required" }]}
            />
          </>
        )}
        {usage === "hod" && (
          <>
            <AppInput
              label="Head of Department Comment"
              name="head_of_department_comment"
              type="textarea"
              inputAttributes={{
                readOnly: currentUserInfo.id === staffAppraisalDetails?.user_id,
              }}
              rules={[{ required: true, message: "This field is required" }]}
            />
            <AppInput
              label="Date"
              name="head_of_department_date"
              inputAttributes={{
                disabled: true,
              }}
              type="date"
              rules={[{ required: true, message: "This field is required" }]}
            />
          </>
        )}
        {usage === "approval" && (
          <>
            <AppInput
              label="Appraisal Status"
              name="status"
              type="select"
              options={[
                {
                  label: "APPROVED",
                  value: "approved",
                },
                {
                  label: "REJECTED",
                  value: "rejected",
                },
              ]}
              inputAttributes={{
                readOnly: currentUserInfo.id === staffAppraisalDetails?.user_id,
              }}
              rules={[{ required: true, message: "This field is required" }]}
            />
            <AppInput
              label="Executive Director Comment"
              name="executive_director_comment"
              type="textarea"
              inputAttributes={{
                readOnly: currentUserInfo.id === staffAppraisalDetails?.user_id,
              }}
              rules={[{ required: true, message: "This field is required" }]}
            />
            <AppInput
              label="Date"
              name="executive_director_date"
              inputAttributes={{
                disabled: true,
              }}
              type="date"
              rules={[{ required: true, message: "This field is required" }]}
            />
          </>
        )}
        <AppButton
          text="SAVE"
          key={"create"}
          block
          loading={staffAppraisalStore.updatingStaffAppraisal}
          disabled={staffAppraisalDetails.status === "approved"}
          onClick={onSubmit}
        />
      </Form>
      {/* <Divider className="m-1" /> */}
    </>
  );
};

export default CommentForm;
