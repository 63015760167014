import React, { useEffect, useState } from "react";
import { useStaffAppraisalsStore } from "../../../../config/stores";
import AntdTable from "../../../common/AntdTable";
import TableTitle from "../../../common/TableTitle";
import TableButtonActions from "../../../common/TableButtonActions";
import { Col, Form, message, Row } from "antd";
import AppInput from "../../../common/AppInput";
import AppButton from "../../../common/AppButton";
import { isEmpty } from "lodash";

const Achievements = () => {
  const staffAppraisalsStore = useStaffAppraisalsStore();
  const [deleteId, setDeleteId] = useState(null);
  const [filterTable, setFilterTableNull] = useState(null);
  const [form] = Form.useForm();

  const {
    gettingStaffAppraisalDetails,
    staffAppraisalDetails,
    creatingStaffAppraisalAchievement,
    creatingAchievement,
    updatingStaffAppraisalAchievement,
    achievementToUpdate,
    createStaffAppraisalAchievement,
    updateStaffAppraisalAchievement,
    updateAchievementError,
    createStaffAchievementError,
  } = staffAppraisalsStore;

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    staffAppraisalsStore.setCreatingStaffAppraisalAchievement(
      false,
      true,
      record
    );
  };

  const confirmDelete = () => {
    staffAppraisalsStore.deleteStaffAppraisalAchievement(
      deleteId,
      staffAppraisalDetails.id
    );
  };

  const reloadDetails = () => {
    staffAppraisalsStore.getStaffAppraisalDetails(staffAppraisalDetails.id);
  };

  const resetFields = () => form.resetFields();

  const onSave = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.staff_appraisal_id = staffAppraisalDetails.id;
        if (creatingAchievement) {
          await createStaffAppraisalAchievement(values);
          if (isEmpty(createStaffAchievementError)) resetFields();
        } else {
          await updateStaffAppraisalAchievement(achievementToUpdate.id, values);
          if (isEmpty(updateAchievementError)) resetFields();
        }
      })
      .catch((err) => {
        message.error("Please fill in the form correctly");
      });
  };

  useEffect(() => {
    if (!creatingAchievement && !isEmpty(achievementToUpdate)) {
      form.setFieldsValue(achievementToUpdate);
    } else {
      form.resetFields();
    }
  }, [creatingAchievement, achievementToUpdate, form]);

  return (
    <div>
      <>
        <Form onFinish={onSave} form={form} layout="horizontal">
          <Row gutter={4}>
            <Col span={22}>
              {" "}
              <AppInput
                name="achievement"
                label="Achievement"
                rules={[{ required: true, message: "Missing this field" }]}
              />
            </Col>
            <Col span={2}>
              <AppButton
                text={creatingAchievement ? "SAVE" : "SAVE UPDATES"}
                key={"create"}
                block
                loading={
                  creatingStaffAppraisalAchievement ||
                  updatingStaffAppraisalAchievement
                }
                disabled={staffAppraisalDetails.status === "approved"}
                onClick={onSave}
              />
            </Col>
          </Row>
        </Form>
        <AntdTable
          title={() => (
            <TableTitle
              setFilterTableNull={setFilterTableNull}
              openAddModal={null}
              refreshTable={reloadDetails}
              multiple
              exportRecords={null}
            />
          )}
          loading={gettingStaffAppraisalDetails}
          data={
            filterTable === null
              ? staffAppraisalDetails?.achievements
              : filterTable
          }
          columns={[
            {
              title: "Achievement",
              dataIndex: "achievement",
            },
            {
              title: "ACTION",
              render: (record) => {
                return (
                  <TableButtonActions
                    record={record}
                    confirmDelete={confirmDelete}
                    deleteLoading={
                      staffAppraisalsStore.deletingStaffAppraisalAchievement
                    }
                    disabled={staffAppraisalDetails.status === "approved"}
                    updateRecord={updateRecord}
                    deleteId={deleteId}
                    deleteRecord={deleteRecord}
                  />
                );
              },
            },
          ]}
        />
      </>
    </div>
  );
};

export default Achievements;
