import React, { useEffect } from "react";
import { useStaffAppraisalsStore } from "../../../config/stores";
import { isEmpty } from "lodash";
import AppraisalDetails from "./ManageAppraisals";
import AppraisalTable from "./ManageAppraisals/AppraisalsTable";

const StaffAppraisals = () => {
  const staffAppraisalStore = useStaffAppraisalsStore();
  useEffect(() => {
    if (isEmpty(staffAppraisalStore.t)) {
      staffAppraisalStore.getStaffAppraisals();
    }
  }, []);

  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">STAFF APPRAISALS</h5>
      {staffAppraisalStore.showStaffAppraisalDetails ? (
        <AppraisalDetails />
      ) : (
        <AppraisalTable
          appraisals={staffAppraisalStore.staffAppraisals?.data}
        />
      )}
    </div>
  );
};

export default StaffAppraisals;
