import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReportingTemplateStore } from "../../../../config/stores";
import AppInput from "../../../common/AppInput";
import { Divider, Form, Row } from "antd";
import { find, isEmpty, map } from "lodash";
import AppButton from "../../../common/AppButton";
import dayjs from "dayjs";
import advanceAccountabilityFormThunks from "../../../../config/thunks/finance/advanceAccountabilityForm/advanceAccountabilityForm.thunks";
import AntdTable from "../../../common/AntdTable";

const ActivityReportingTemplate = () => {
  const {
    advanceAccountabilityFormsDetails,
    advanceAccountabilityFormsExpenditureDetails,
  } = useSelector((state) => state.advanceAccountabilityFormsState);
  const reportingTemplateStore = useReportingTemplateStore();

  const reportingTemplate = useMemo(() => {
    return (
      find(advanceAccountabilityFormsDetails.reportingTemplates, {
        actual_expenditure_id: advanceAccountabilityFormsExpenditureDetails.id,
      }) || {}
    );
  }, [
    advanceAccountabilityFormsDetails.reportingTemplates,
    advanceAccountabilityFormsExpenditureDetails.id,
  ]);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...reportingTemplate,
      date: dayjs(reportingTemplate.date),
    });
  }, [reportingTemplate, form]);
  const dispatch = useDispatch();

  const onSave = async (values) => {
    values.project_id = advanceAccountabilityFormsDetails.project;
    values.concept_note_id = advanceAccountabilityFormsDetails?.conceptNote?.id;
    values.payment_voucher_id = advanceAccountabilityFormsDetails.id;
    values.actual_expenditure_id =
      advanceAccountabilityFormsExpenditureDetails.id;
    if (reportingTemplate) {
      await reportingTemplateStore.updateReportingTemplate(
        reportingTemplate.id,
        values
      );
    } else {
      await reportingTemplateStore.createReportingTemplate(values);
    }
    dispatch(
      advanceAccountabilityFormThunks.getAdvanceAccountabilityFormDetails(
        advanceAccountabilityFormsDetails.id
      )
    );
  };

  return (
    <div>
      <p className="text-center h5">Amani Initiative Activity Report</p>
      <p className="text-muted text-center">
        Complete and submit the activity report to your Supervisor & your MEAL
        support staff not more than 7 days upon completion of the activity.{" "}
      </p>
      <Form form={form} onFinish={onSave}>
        <table className="table table-sm table-bordered">
          <tbody>
            <tr>
              <td className="w-50 fw-bold">Project/initiative name</td>
              <td>
                {advanceAccountabilityFormsDetails.projectDetails?.project_name}
              </td>
            </tr>
            <tr>
              <td className="w-50 fw-bold">Title of activity</td>
              <td>{advanceAccountabilityFormsExpenditureDetails.name}</td>
            </tr>
            {/* <tr>
              <td className="w-50 fw-bold">Strategic plan priority Input</td>
              <td>{advanceAccountabilityFormsExpenditureDetails.name}</td>
            </tr> */}
            <tr>
              <td className="w-50 fw-bold">Strategic plan indicator</td>
              <td>
                <AppInput
                  type="select"
                  name="indicator_id"
                  inputAttributes={{
                    variant: "borderless",
                    placeholder: "You can select the one that apply.",
                  }}
                  options={map(
                    advanceAccountabilityFormsDetails.strategicPlanIndicators,
                    (indicator) => ({
                      label: indicator.indicator,
                      value: indicator.id,
                    })
                  )}
                />
              </td>
            </tr>
            <tr>
              <td className="w-50 fw-bold">Funder</td>
              <td>
                {advanceAccountabilityFormsDetails?.projectDetails?.donor_name}
              </td>
            </tr>
            <tr>
              <td className="w-50 fw-bold">
                Dates and duration{" "}
                <span className="text-muted fw-normal">
                  (Indicate date and how long the activity took place)
                </span>{" "}
              </td>
              <td>
                <Row>
                  <span className="mx-2"> Date:</span>{" "}
                  <AppInput
                    type="date"
                    name="date"
                    inputAttributes={{
                      variant: "borderless",
                    }}
                  />
                  <span className="mx-2"> Duration:</span>
                  <AppInput
                    type="number"
                    name="duration"
                    inputAttributes={{
                      variant: "borderless",
                      placeholder: "Enter Period",
                    }}
                  />
                  <AppInput
                    type="select"
                    name="duration_unit"
                    inputAttributes={{
                      variant: "borderless",
                      placeholder: "Select Period Type",
                    }}
                    options={[
                      { label: "Day(s)", value: "days" },
                      { label: "Week(s)", value: "weeks" },
                      { label: "Month(s)", value: "months" },
                    ]}
                  />
                </Row>
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                Activity Venue{" "}
                <span className="text-muted fw-normal">
                  (Indicate the venue including district, sub-county, and
                  villages in which the activity took place)
                </span>
              </td>
              <td>
                <AppInput
                  name="venue"
                  inputAttributes={{
                    variant: "borderless",
                    placeholder: "Enter Venue",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                Description of the activity{" "}
                <span className="text-muted fw-normal">
                  (Give a summary description including activity objectives, and
                  how (methodology or approach) this activity was conducted or
                  carried out.)
                </span>
              </td>
              <td>
                <AppInput
                  type="textarea"
                  name="achievements_and_results"
                  inputAttributes={{
                    variant: "borderless",
                    placeholder: "Enter Achievements and results",
                    autoSize: { minRows: 2, maxRows: 5 },
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                Activity achievements and results{" "}
                <span className="text-muted fw-normal">
                  ( Provide the successes/results of the activity in relation to
                  what was planned. Also describe the unplanned
                  successes/results of the activity. (Include both the outcome
                  and output results. Outcomes refer to changes in capacities of
                  project participants or intermediaries for instance 10%
                  increase in knowledge, positive change in attitude Outputs
                  refer to: a))
                </span>
              </td>
              <td>
                <AppInput
                  type="textarea"
                  name="description"
                  inputAttributes={{
                    variant: "borderless",
                    placeholder: `Enter  Activity Achievements`,
                    autoSize: { minRows: 3, maxRows: 5 },
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                Challenges encountered and how these challenges were overcome{" "}
                <span className="text-muted fw-normal">
                  (If any, provide the technical and operational factors that
                  affected implementation of the activity and solutions that
                  were implored to address these challenges)
                </span>
              </td>
              <td>
                <AppInput
                  type="textarea"
                  name="challenges"
                  inputAttributes={{
                    variant: "borderless",
                    placeholder: `Enter  Challenges`,
                    autoSize: { minRows: 2, maxRows: 5 },
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                Key lessons{" "}
                <span className="text-muted fw-normal">
                  ( Explicitly describe the key lessons learned from the
                  implementation of this activity These lessons should be able
                  to guide future activities and programming)
                </span>
              </td>
              <td>
                <AppInput
                  type="textarea"
                  name="key_lessons"
                  inputAttributes={{
                    variant: "borderless",
                    placeholder: `Enter  Key Lessons`,
                    autoSize: { minRows: 2, maxRows: 5 },
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                Recommendations{" "}
                <span className="text-muted fw-normal">
                  (Give a summary description of what do we needs to be done
                  better to ensure the activity achieves its intended
                  objectives)
                </span>
              </td>
              <td>
                <AppInput
                  type="textarea"
                  name="recommendations"
                  inputAttributes={{
                    variant: "borderless",
                    placeholder: `Enter Recommendations`,
                    autoSize: { minRows: 2, maxRows: 5 },
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                Number of project participants or intermediaries reached{" "}
                <span className="text-muted fw-normal">
                  (This is to automatically update Amani Initiative’s strategic
                  plan indicators and performance based on the selected activity
                  and strategic plan priority in put selected)
                </span>
              </td>
              <td>
                <table class="table table-bordered table-sm ">
                  <thead class="">
                    <tr>
                      <th>Sex</th>
                      <th>0-12</th>
                      <th>13-19</th>
                      <th>20-35</th>
                      <th>Above 35</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Male Ugandan</td>
                      <td>
                        <AppInput
                          type="number"
                          name="male_ugandan_0_12"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 0,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="male_ugandan_13_19"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 13,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="male_ugandan_20_35"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 20,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="male_ugandan_above_35"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 35,
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Female Ugandan</td>
                      <td>
                        <AppInput
                          type="number"
                          name="female_ugandan_0_12"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 0,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="female_ugandan_13_19"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 13,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="female_ugandan_20_35"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 20,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="female_ugandan_above_35"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 35,
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Male Refugee</td>
                      <td>
                        <AppInput
                          type="number"
                          name="male_refugee_0_12"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 0,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="male_refugee_13_19"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 13,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="male_refugee_20_35"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 20,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="male_refugee_above_35"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 35,
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Female Refugee</td>
                      <td>
                        <AppInput
                          type="number"
                          name="female_refugee_0_12"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 0,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="female_refugee_13_19"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 13,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="female_refugee_20_35"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 20,
                          }}
                        />
                      </td>
                      <td>
                        <AppInput
                          type="number"
                          name="female_refugee_above_35"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "eg 2",
                            min: 35,
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <Divider>Person with a disability</Divider>
                <table className="table table-sm table-bordered">
                  <tr>
                    <td>Male</td>
                    <td>
                      <AppInput
                        type="number"
                        name="persons_with_disability_male"
                        inputAttributes={{
                          variant: "borderless",
                          placeholder: "eg 2",
                          min: 0,
                        }}
                      />
                    </td>
                    <td>Female</td>
                    <td>
                      {" "}
                      <AppInput
                        type="number"
                        name="persons_with_disability_female"
                        inputAttributes={{
                          variant: "borderless",
                          placeholder: "eg 2",
                          min: 0,
                        }}
                      />
                    </td>
                    <td>Total</td>
                    <td></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                Community Voice (Quotes){" "}
                <span className="text-muted fw-normal">
                  Community Voice (Quotes){" "}
                </span>
              </td>
              <td>
                <AppInput
                  type="textarea"
                  name="community_voice"
                  inputAttributes={{
                    variant: "borderless",
                    placeholder: `Enter  Community Voice (Quotes)`,
                    autoSize: { minRows: 2, maxRows: 5 },
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>

        {!isEmpty(reportingTemplate) &&
          !isEmpty(reportingTemplate?.actionPoints) && (
            <>
              <AntdTable
                title={() => (
                  <Divider className="text-uppercase fw-bold">
                    Activity follow up and action points{" "}
                  </Divider>
                )}
                data={reportingTemplate?.actionPoints}
                columns={[
                  {
                    title: "ACTION POINT",
                    dataIndex: "action_point",
                  },
                  {
                    title: "PERSON RESPONSIBLE",
                    dataIndex: "person_responsible",
                  },
                  {
                    title: "TIMELINE",
                    dataIndex: "timeline",
                  },
                  {
                    title: "STATUS",
                    dataIndex: "status",
                  },
                  {
                    title: "ACTION",
                    dataIndex: "action",
                  },
                ]}
              />
            </>
          )}

        <AppButton
          text="SAVE REPORTING TEMPLATE"
          type="submit"
          block
          disabled={
            reportingTemplate.status === "approved" ||
            advanceAccountabilityFormsDetails.status === "approved"
          }
          loadingText="SAVING..."
          className="mt-3"
          loading={
            reportingTemplateStore.creatingReportTemplate ||
            reportingTemplateStore.updatingReportTemplate
          }
        />
      </Form>
    </div>
  );
};

export default ActivityReportingTemplate;
