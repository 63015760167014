import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";

const initialState = {
  gettingStaffAppraisals: false,
  staffAppraisals: {},
  staffAppraisalsError: {},

  gettingMyStaffAppraisals: false,
  myStaffAppraisals: {},
  myStaffAppraisalsError: {},

  creating: false,
  creatingStaffAppraisal: false,
  createStaffAppraisalError: {},
  createdStaffAppraisal: null,
  showCreateStaffAppraisal: false,

  updatingStaffAppraisal: false,
  staffAppraisalToUpdate: {},
  updateStaffAppraisalError: {},

  deletingStaffAppraisal: false,
  deleteStaffAppraisalError: {},

  gettingStaffAppraisalDetails: false,
  staffAppraisalDetails: {},
  staffAppraisalDetailsError: {},
  showStaffAppraisalDetails: false,

  updatingStaffAppraisalAchievement: false,
  achievementToUpdate: {},
  updateAchievementError: {},

  creatingAchievement: true,
  showCreateAchievement: false,

  creatingStaffAppraisalAchievement: false,
  createStaffAchievementError: {},

  deletingStaffAppraisalAchievement: false,
  deleteStaffAchievementError: {},

  metaData: {},
  gettingMetaData: false,
  metaDataError: {},

  creatingChallenge: true,
  addingChallenge: false,
  addedChallenge: null,
  addChallengeError: {},
  showCreateChallenge: false,

  updatingChallenge: false,
  updatedChallenge: null,
  updateChallengeError: {},
  challengeToUpdate: {},

  deletingChallenge: false,
  deletedChallenge: null,
  deleteChallengeError: {},

  creatingDevelopmentGoals: true,
  addingDevelopmentGoals: false,
  addedDevelopmentGoals: null,
  addDevelopmentGoalsError: {},
  showCreateDevelopmentGoals: false,

  updatingDevelopmentGoals: false,
  updatedDevelopmentGoals: null,
  updateDevelopmentGoalsError: {},
  developmentGoalToUpdate: {},

  deletingDevelopmentGoals: false,
  deletedDevelopmentGoals: null,
  deleteDevelopmentGoalsError: {},

  creatingSelfAssessments: true,
  addingSelfAssessments: false,
  addedSelfAssessments: null,
  addSelfAssessmentsError: {},
  showCreateSelfAssessments: false,

  updatingSelfAssessments: false,
  updatedSelfAssessments: null,
  updateSelfAssessmentsError: {},
  selfAssessmentToUpdate: {},

  deletingSelfAssessments: false,
  deletedSelfAssessments: null,
  deleteSelfAssessmentsError: {},

  creatingSoftSkillEnhancement: true,
  addingSoftSkillEnhancement: false,
  addedSoftSkillEnhancement: null,
  addSoftSkillEnhancementError: {},
  showCreateSoftSkillEnhancement: false,

  updatingSoftSkillEnhancement: false,
  updatedSoftSkillEnhancement: null,
  updateSoftSkillEnhancementError: {},
  softSkillEnhancementToUpdate: {},

  creatingCapacityDevelopmentGoals: true,
  addingCapacityDevelopmentGoals: false,
  addedCapacityDevelopmentGoals: null,
  addCapacityDevelopmentGoalsError: {},
  showCreateCapacityDevelopmentGoals: false,

  updatingCapacityDevelopmentGoals: false,
  updatedCapacityDevelopmentGoals: null,
  updateCapacityDevelopmentGoalsError: {},
  capacityDevelopmentGoalToUpdate: {},

  deletingCapacityDevelopmentGoals: false,
  deletedCapacityDevelopmentGoals: null,
  deleteCapacityDevelopmentGoalsError: {},

  creatingGoalForNextPeriod: true,
  addingGoalForNextPeriod: false,
  addedGoalForNextPeriod: null,
  addGoalForNextPeriodError: {},
  showCreateGoalForNextPeriod: false,

  updatingGoalForNextPeriod: false,
  updatedGoalForNextPeriod: null,
  updateGoalForNextPeriodError: {},
  goalForNextPeriodToUpdate: {},

  deletingGoalForNextPeriod: false,
  deletedGoalForNextPeriod: null,
  deleteGoalForNextPeriodError: {},

  addingComments: false,
  commentUser: "user",
  commentTitle: "",
  addCommentError: {},
  showAddComment: false,

  updatingAnnualTargets: false,
  updatedAnnualTargets: null,
  updateAnnualTargetsError: {},
  annualTargetToUpdate: {},

  submitting: false,
  submitError: {},

  approving: false,
  approvedAppraisal: null,
  approveError: {},
};

const store = (set, get) => ({
  ...initialState,

  setComment(commentUser = "user", show = false, commentTitle = "") {
    set({
      commentUser,
      showAddComment: show,
      commentTitle,
    });
  },
  getMetaData: async () => {
    set({ gettingMetaData: true });
    await axios
      .get("/administration/staff-appraisals/meta-data")
      .then((res) => {
        set({ metaData: res });
      })
      .catch((err) => set({ metaDataError: err }))
      .finally(() => set({ gettingMetaData: false }));
  },
  approveAppraisal: async (id, data) => {
    set({ approving: true });
    await axios
      .patch(`/administration/staff-appraisals/approve/${id}`, data)
      .then((res) => {
        set({ approveError: res });
        get().getStaffAppraisalDetails(id);
      })
      .catch((err) => set({ approveErrorError: err }))
      .finally(() => set({ approving: false }));
  },
  // actions
  getStaffAppraisals: async () => {
    set({ gettingStaffAppraisals: true });
    await axios
      .get("/administration/staff-appraisals")
      .then((res) => {
        set({ staffAppraisals: res });
      })
      .catch((err) => set({ staffAppraisalsError: err }))
      .finally(() => set({ gettingStaffAppraisals: false }));
  },
  getMyStaffAppraisals: async () => {
    set({ gettingMyStaffAppraisals: true });
    await axios
      .get("/administration/staff-appraisals/my-appraisals")
      .then((res) => {
        set({ myStaffAppraisals: res });
      })
      .catch((err) => set({ myStaffAppraisalsError: err }))
      .finally(() => set({ gettingMyStaffAppraisals: false }));
  },
  getStaffAppraisalDetails: async (id) => {
    set({ gettingStaffAppraisalDetails: true });
    await axios
      .get(`/administration/staff-appraisals/${id}`)
      .then((res) => {
        set({ staffAppraisalDetails: res });
      })
      .catch((err) => set({ staffAppraisalDetailsError: err }))
      .finally(() => set({ gettingStaffAppraisalDetails: false }));
  },
  submitStaffAppraisalDetails: async (id) => {
    set({ submitting: true });
    await axios
      .patch(`/administration/staff-appraisals/submit/${id}`)
      .then((res) => {
        get().getStaffAppraisalDetails(id);
        set({ staffAppraisalDetails: res });
      })
      .catch((err) => set({ submitError: err }))
      .finally(() => set({ submitting: false }));
  },
  createStaffAppraisal: async (data) => {
    set({
      creatingStaffAppraisal: true,
      createStaffAppraisalError: {},
      createdStaffAppraisal: null,
    });
    await axios
      .post(`/administration/staff-appraisals`, data)
      .then((res) => {
        set({ createdStaffAppraisal: res });
        get().getMyStaffAppraisals();
        get().setCreatingStaffAppraisal();
        get().setShowDetails(true);
        get().getStaffAppraisalDetails(res.id);
      })
      .catch((err) => set({ createStaffAppraisalError: err }))
      .finally(() => set({ creatingStaffAppraisal: false }));
  },
  updateStaffAppraisal: async (id, data) => {
    set({
      updatingStaffAppraisal: true,
      updateStaffAppraisalError: {},
    });
    await axios
      .put(`/administration/staff-appraisals/${id}`, data)
      .then(() => {
        get().setCreatingStaffAppraisal();
        get().getMyStaffAppraisals();
        get().getStaffAppraisalDetails(id);
        get().setCreatingStaffAppraisal();
      })
      .catch((err) => set({ updateStaffAppraisalError: err }))
      .finally(() => set({ updatingStaffAppraisal: false }));
  },
  updateStaffAppraisalAnnualTargets: async (data) => {
    set({
      updatingAnnualTargets: true,
      updateAnnualTargetsError: {},
    });
    const staffAppraisal = get().staffAppraisalDetails;

    await axios
      .patch(
        `/administration/staff-appraisals/update-appraisal-annual-targets/${staffAppraisal.id}`,
        data
      )
      .then(() => {
        get().getStaffAppraisalDetails(staffAppraisal.id);
      })
      .catch((err) => set({ updateAnnualTargetsError: err }))
      .finally(() => set({ updatingAnnualTargets: false }));
  },
  deleteStaffAppraisal: async (id) => {
    set({
      deletingStaffAppraisal: true,
      deleteStaffAppraisalError: {},
    });
    await axios
      .delete(`/administration/staff-appraisals/${id}`)
      .then(() => {
        get().getMyStaffAppraisals();
      })
      .catch((err) => set({ deletingStaffAppraisal: err }))
      .finally(() => set({ deletingStaffAppraisal: false }));
  },
  createStaffAppraisalAchievement: async (data) => {
    set({
      creatingStaffAppraisalAchievement: true,
    });
    await axios
      .post(`/administration/staff-appraisals/achievements`, data)
      .then((res) => {
        get().getStaffAppraisalDetails(get().staffAppraisalDetails?.id);
        get().setCreatingStaffAppraisalAchievement();
      })
      .catch((err) => set({ createStaffAchievementError: err }))
      .finally(() => set({ creatingStaffAppraisalAchievement: false }));
  },
  createCapacityDevelopmentGoal: async (data) => {
    set({
      addingCapacityDevelopmentGoals: true,
      addCapacityDevelopmentGoalsError: {},
    });
    await axios
      .post(
        `/administration/staff-appraisals/capacity-development-goals/${
          get().staffAppraisalDetails?.id
        }`,
        data
      )
      .then(() => {
        get().getStaffAppraisalDetails(get().staffAppraisalDetails?.id);
        get().setCreatingCapacityDevelopmentGoals();
      })
      .catch((err) => set({ addCapacityDevelopmentGoalsError: err }))
      .finally(() => set({ addingCapacityDevelopmentGoals: false }));
  },
  createSoftSkillEnhancement: async (data) => {
    set({
      addingSoftSkillEnhancement: true,
    });
    const staffAppraisal = get().staffAppraisalDetails;
    await axios
      .post(
        `/administration/staff-appraisals/soft-skill-enhancement/${staffAppraisal.id}`,
        data
      )
      .then(() => {
        get().getStaffAppraisalDetails(get().staffAppraisalDetails?.id);
        get().setCreatingSofSkillEnhancement();
      })
      .catch((err) => set({ addSoftSkillEnhancementError: err }))
      .finally(() => set({ addingSoftSkillEnhancement: false }));
  },
  createGoalForNextPeriod: async (data) => {
    set({
      addingGoalForNextPeriod: true,
      addGoalForNextPeriodError: {},
    });
    await axios
      .post(`/administration/staff-appraisals/goals-for-next-period`, data)
      .then(() => {
        get().getStaffAppraisalDetails(get().staffAppraisalDetails?.id);
        get().setCreatingGoalForNextPeriod();
      })
      .catch((err) => set({ addGoalForNextPeriodError: err }))
      .finally(() => set({ addingGoalForNextPeriod: false }));
  },
  createStaffChallenge: async (data) => {
    set({
      addingChallenge: true,
    });
    await axios
      .post(`/administration/staff-appraisals/challenges`, data)
      .then((res) => {
        get().getStaffAppraisalDetails(data.staff_appraisal_id);
        get().setCreatingStaffChallenge();
      })
      .catch((err) => set({ addChallengeError: err }))
      .finally(() => set({ addingChallenge: false }));
  },
  createStaffDevelopmentGoal: async (data) => {
    set({
      addingDevelopmentGoals: true,
    });
    await axios
      .post(`/administration/staff-appraisals/development-goals`, data)
      .then((res) => {
        get().getStaffAppraisalDetails(data.staff_appraisal_id);
        get().setCreatingStaffChallenge();
      })
      .catch((err) => set({ addDevelopmentGoalsError: err }))
      .finally(() => set({ addingDevelopmentGoals: false }));
  },
  createStaffSelfAssessment: async (data) => {
    set({
      addingSelfAssessments: true,
    });
    await axios
      .post(`/administration/staff-appraisals/self-assessment`, data)
      .then((res) => {
        get().getStaffAppraisalDetails(data.staff_appraisal_id);
        get().setCreatingStaffChallenge();
      })
      .catch((err) => set({ addSelfAssessmentsError: err }))
      .finally(() => set({ addingSelfAssessments: false }));
  },
  updateStaffAppraisalAchievement: async (id, data) => {
    set({
      updatingStaffAppraisalAchievement: true,
      updateAchievementError: {},
    });
    await axios
      .put(`/administration/staff-appraisals/achievements/${id}`, data)
      .then(() => {
        get().getStaffAppraisalDetails(get().staffAppraisalDetails?.id);
        get().setCreatingStaffAppraisalAchievement();
      })
      .catch((err) => set({ updateAchievementError: err }))
      .finally(() => set({ updatingStaffAppraisalAchievement: false }));
  },
  updateCapacityDevelopmentGoal: async (data) => {
    set({
      updatingCapacityDevelopmentGoals: true,
      updateCapacityDevelopmentGoalsError: {},
    });
    const staffAppraisal = get().staffAppraisalDetails;
    await axios
      .put(
        `/administration/staff-appraisals/capacity-development-goals/${staffAppraisal.id}`,
        data
      )
      .then(() => {
        get().getStaffAppraisalDetails(get().staffAppraisalDetails?.id);
        get().setCreatingStaffAppraisalAchievement();
      })
      .catch((err) => set({ updateCapacityDevelopmentGoalsError: err }))
      .finally(() => set({ updatingCapacityDevelopmentGoals: false }));
  },
  updateSoftSkills: async (data) => {
    set({
      updatingSoftSkillEnhancement: true,
      updateSoftSkillEnhancementError: {},
    });
    const appraisalId = get().staffAppraisalDetails?.id;
    await axios
      .put(
        `/administration/staff-appraisals/soft-skill-enhancement/${appraisalId}`,
        data
      )
      .then(() => {
        get().getStaffAppraisalDetails(appraisalId);
        get().setCreatingStaffAppraisalAchievement();
      })
      .catch((err) => set({ updateSoftSkillEnhancementError: err }))
      .finally(() => set({ updatingSoftSkillEnhancement: false }));
  },
  updateStaffAppraisalDevelopmentGoal: async (id, data) => {
    set({
      updatingDevelopmentGoals: true,
      updateDevelopmentGoalsError: {},
    });
    await axios
      .put(`/administration/staff-appraisals/development-goals/${id}`, data)
      .then(() => {
        get().getStaffAppraisalDetails(get().staffAppraisalDetails?.id);
        get().setCreatingStaffAppraisalAchievement();
      })
      .catch((err) => set({ updateDevelopmentGoalsError: err }))
      .finally(() => set({ updatingDevelopmentGoals: false }));
  },
  updateStaffAppraisalGoalForNextPeriod: async (id, data) => {
    set({
      updatingGoalForNextPeriod: true,
      updateGoalForNextPeriodError: {},
    });
    await axios
      .put(`/administration/staff-appraisals/goals-for-next-period/${id}`, data)
      .then(() => {
        get().getStaffAppraisalDetails(get().staffAppraisalDetails?.id);
        get().setCreatingStaffAppraisalAchievement();
      })
      .catch((err) => set({ updateGoalForNextPeriodError: err }))
      .finally(() => set({ updatingGoalForNextPeriod: false }));
  },
  updateStaffAppraisalSelfAssessment: async (id, data) => {
    set({
      updatingSelfAssessments: true,
      updateSelfAssessmentsError: {},
    });
    await axios
      .put(`/administration/staff-appraisals/self-assessment/${id}`, data)
      .then(() => {
        get().getStaffAppraisalDetails(get().staffAppraisalDetails?.id);
        get().setCreatingStaffAppraisalAchievement();
      })
      .catch((err) => set({ updateSelfAssessmentsError: err }))
      .finally(() => set({ updatingSelfAssessments: false }));
  },
  updateStaffAppraisalChallenge: async (id, data) => {
    set({
      updatingChallenge: true,
      updateChallengeError: {},
    });
    await axios
      .put(`/administration/staff-appraisals/challenges/${id}`, data)
      .then(() => {
        get().getStaffAppraisalDetails(data.staff_appraisal_id);
        get().setCreatingStaffChallenge();
      })
      .catch((err) => set({ updateChallengeError: err }))
      .finally(() => set({ updatingChallenge: false }));
  },
  deleteStaffAppraisalAchievement: async (id, staffAppraisalId) => {
    set({
      deletingStaffAppraisalAchievement: true,
      deleteStaffAchievementError: {},
    });
    await axios
      .delete(`/administration/staff-appraisals/achievements/${id}`)
      .then(() => {
        get().getStaffAppraisalDetails(staffAppraisalId);
      })
      .catch((err) => set({ deleteStaffAchievementError: err }))
      .finally(() => set({ deletingStaffAppraisalAchievement: false }));
  },
  deleteStaffAppraisalChallenge: async (id, staffAppraisalId) => {
    set({
      deletingChallenge: true,
      deleteChallengeError: {},
    });
    await axios
      .delete(`/administration/staff-appraisals/challenges/${id}`)
      .then(() => {
        get().getStaffAppraisalDetails(staffAppraisalId);
      })
      .catch((err) => set({ deleteChallengeError: err }))
      .finally(() => set({ deletingChallenge: false }));
  },
  deleteStaffAppraisalSelfAssessment: async (id, staffAppraisalId) => {
    set({
      deletingSelfAssessments: true,
      deleteSelfAssessmentsError: {},
    });
    await axios
      .delete(`/administration/staff-appraisals/self-assessment/${id}`)
      .then(() => {
        get().getStaffAppraisalDetails(staffAppraisalId);
      })
      .catch((err) => set({ deleteSelfAssessmentsError: err }))
      .finally(() => set({ deletingSelfAssessments: false }));
  },
  deletePersonalDevelopmentGoal: async (id, staffAppraisalId) => {
    set({
      deletingDevelopmentGoals: true,
      deleteDevelopmentGoalsError: {},
    });
    await axios
      .delete(`/administration/staff-appraisals/development-goals/${id}`)
      .then(() => {
        get().getStaffAppraisalDetails(staffAppraisalId);
      })
      .catch((err) => set({ deleteDevelopmentGoalsError: err }))
      .finally(() => set({ deletingDevelopmentGoals: false }));
  },
  deleteGoalForNextPeriod: async (id, staffAppraisalId) => {
    set({
      deletingGoalForNextPeriod: true,
      deleteGoalForNextPeriodError: {},
    });
    await axios
      .delete(`/administration/staff-appraisals/goals-for-next-period/${id}`)
      .then(() => {
        get().getStaffAppraisalDetails(staffAppraisalId);
      })
      .catch((err) => set({ deleteGoalForNextPeriodError: err }))
      .finally(() => set({ deletingGoalForNextPeriod: false }));
  },
  deleteCapacityDevelopmentGoal: async (id, staffAppraisalId) => {
    set({
      deletingCapacityDevelopmentGoals: true,
      deleteGoalForNextPeriodError: {},
    });
    await axios
      .delete(
        `/administration/staff-appraisals/capacity-development-goals/${id}`
      )
      .then(() => {
        get().getStaffAppraisalDetails(staffAppraisalId);
      })
      .catch((err) => set({ deleteDevelopmentGoalsError: err }))
      .finally(() => set({ deletingGoalForNextPeriod: false }));
  },

  setCreatingStaffAppraisal: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      creating: creating,
      showCreateStaffAppraisal: show,
      staffAppraisalToUpdate: data,
    });
  },
  setCreatingCapacityDevelopmentGoal: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      creatingCapacityDevelopmentGoals: creating,
      showCreateDevelopmentGoals: show,
      capacityDevelopmentGoalToUpdate: data,
    });
  },
  setCreatingSoftSkills: async (creating = true, show = false, data = {}) => {
    set({
      creatingSoftSkillEnhancement: creating,
      showCreateSoftSkillEnhancement: show,
      softSkillEnhancementToUpdate: data,
    });
  },
  setCreatingGoalForNextPeriod: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      creatingGoalForNextPeriod: creating,
      showCreateGoalForNextPeriod: show,
      goalForNextPeriodToUpdate: data,
    });
  },
  setCreatingStaffChallenge: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      creatingChallenge: creating,
      showCreateChallenge: show,
      challengeToUpdate: data,
    });
  },
  setCreatingSelfAssessment: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      creatingSelfAssessments: creating,
      showCreateSelfAssessments: show,
      selfAssessmentToUpdate: data,
    });
  },
  setCreatingSelfDevelopmentGoal: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      creatingDevelopmentGoals: creating,
      showCreateDevelopmentGoals: show,
      developmentGoalToUpdate: data,
    });
  },
  setCreatingStaffAppraisalAchievement: async (
    creating = true,
    show = false,
    data = {}
  ) => {
    set({
      achievementToUpdate: data,
      creatingAchievement: creating,
      showCreateAchievement: show,
    });
  },
  setShowDetails: (show) => {
    set({ showStaffAppraisalDetails: show, staffAppraisalDetails: {} });
  },
});

const storeName = "STAFF APPRAISALS";
const useStaffAppraisalsStore = create(devtools(store, { name: storeName }), {
  name: storeName,
});

export default useStaffAppraisalsStore;
