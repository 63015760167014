import React, { useMemo, useState } from "react";
import { useStaffContractsStore } from "../../../config/stores";
import AntdTable from "../../common/AntdTable";
import { searchTable } from "../../../config/constants";
import TableTitle from "../../common/TableTitle";
import TableButtonActions from "../../common/TableButtonActions";
import { flatMap, map } from "lodash";

const StaffContractObligations = () => {
  const staffContractStore = useStaffContractsStore();
  const [deleteId, setDeleteId] = useState(null);
  const { staffContractDetails, gettingStaffContractDetails } =
    staffContractStore;

  const [filterTable, setFilterTableNull] = useState(null);

  const indicators = useMemo(() => {
    return flatMap(staffContractDetails.assessment_areas, (area) => {
      return map(area.obligations, (obligation) => ({
        ...obligation,
        assessmentArea: area.assessment_area,
      }));
    });
  }, [staffContractDetails]);

  const search = (value) => {
    const filterTable = searchTable(
      flatMap(staffContractDetails.indicators, "obligations"),
      value
    );
    setFilterTableNull(filterTable);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    staffContractStore.setCreatingStaffContractObligation(false, true, record);
  };

  const confirmDelete = () => {
    staffContractStore.deleteStaffContractObligation(
      deleteId,
      staffContractDetails.id
    );
  };

  const reloadDetails = () => {
    staffContractStore.getStaffContractDetails(staffContractDetails.id);
  };

  const openAddModal = () =>
    staffContractStore.setCreatingStaffContractObligation(true, true, {});

  return (
    <div>
      <AntdTable
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={openAddModal}
            refreshTable={reloadDetails}
            search={search}
            exportRecords={null}
          />
        )}
        loading={gettingStaffContractDetails}
        data={filterTable === null ? indicators : filterTable}
        columns={[
          {
            title: "Assessment Area",
            dataIndex: "assessmentArea",
          },
          {
            title: "KPI",
            dataIndex: "key_performance_indicator",
          },
          {
            title: "ANNUAL TARGET",
            dataIndex: "annual_target",
          },
          {
            title: "ACTION",
            render: (record) => {
              return (
                <TableButtonActions
                  record={record}
                  confirmDelete={confirmDelete}
                  deleteLoading={
                    staffContractStore.deletingStaffContractObligation
                  }
                  updateRecord={updateRecord}
                  deleteId={deleteId}
                  deleteRecord={deleteRecord}
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default StaffContractObligations;
