import React, { useEffect } from "react";
import { useStaffAppraisalsStore } from "../../../config/stores";
import { isEmpty } from "lodash";
import AppraisalDetails from "./ManageAppraisals";
import AppraisalTable from "./ManageAppraisals/AppraisalsTable";
import AppraisalForm from "./CreateAppraisalForm";

const MyStaffAppraisals = () => {
  const staffAppraisalStore = useStaffAppraisalsStore();
  useEffect(() => {
    if (isEmpty(staffAppraisalStore.staffAppraisals)) {
      staffAppraisalStore.getMyStaffAppraisals();
    }
  }, []);

  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">MY APPRAISALS</h5>
      <AppraisalForm />
      {staffAppraisalStore.showStaffAppraisalDetails ? (
        <AppraisalDetails />
      ) : (
        <AppraisalTable
          appraisals={staffAppraisalStore.myStaffAppraisals?.data || []}
          canEdit
        />
      )}
    </div>
  );
};

export default MyStaffAppraisals;
