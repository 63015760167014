import React from "react";
import { useTimeSheetStore } from "../../../../config/stores";
import TableTitle from "../../../../components/common/TableTitle";
import AntdTable from "../../../../components/common/AntdTable";
import {
  filter,
  first,
  forEach,
  groupBy,
  map,
  range,
  sumBy,
  toInteger,
  toUpper,
  trim,
} from "lodash";
import dayjs from "dayjs";

const TimeSheetActivitiesTable = ({ showHeader = false }) => {
  const timeSheetsStore = useTimeSheetStore();
  const { timeSheetDetails } = timeSheetsStore;

  const openAddModal = () => {
    timeSheetsStore.setAddingTimeSheetActivity(true, true, {});
  };

  const getProjectColumns = map(timeSheetDetails.myProjects, (project) => ({
    title: project.name ? toUpper(project?.name) : null,
    dataIndex: trim(project.id),
    align: "center",
    className: "cursor-pointer",
    width: 100,
  }));

  const columns = [
    {
      title: "DATE",
      dataIndex: "date",
      fixed: "left",
      width: 120,
      render: (date) => (
        <span
          style={{ cursor: "pointer" }}
          className="text-primary cursor-pointer"
        >
          {date}
        </span>
      ),
    },
    {
      title: "ACTIVITIES",
      width: 600,
      dataIndex: "activities",
    },
    ...getProjectColumns,
    {
      title: "SHARED",
      dataIndex: "SHARED",
      align: "center",
      width: 100,
    },
    {
      title: "TOTAL",
      dataIndex: "total",
      align: "center",
      width: 200,
      render: (total) => (
        <span style={{ cursor: "pointer" }} className="fw-bold">
          {total}
        </span>
      ),
    },
  ];

  const firstDay = dayjs(`${timeSheetDetails?.month}`)
    .startOf("month")
    .format("DD");
  const lastDay = dayjs(`${timeSheetDetails?.month}`)
    .endOf("month")
    .format("DD");

  const daysOfTheMonth = range(firstDay, toInteger(lastDay) + 1);

  let data = map(daysOfTheMonth, (day) => {
    const dayActivities = filter(
      timeSheetDetails.timeSheetActivities,
      (activity) => {
        return dayjs(activity.date).date() === day;
      }
    );

    const projects = {};
    const groupedByProject = map(
      groupBy(dayActivities, "project_id"),
      (activities, key) => {
        return {
          project_id: key,
          is_shared: key === "null",
          hours: sumBy(activities, "hours"),
          activities: map(activities, "activity").join(", "),
        };
      }
    );

    forEach(groupedByProject, (activity) => {
      if (activity.is_shared) {
        projects["SHARED"] = activity.hours;
      } else {
        projects[activity.project_id] = activity.hours;
      }
    });

    // console.log(dayActivities);
    const total = sumBy(dayActivities, "hours");

    return {
      date: `${day}/${timeSheetDetails.month}/${timeSheetDetails.year}`,
      day: day,
      activities: map(dayActivities, "activity").join(", "),
      ...projects,
      total,
    };
  });

  const timeSheetTotals = {};
  const percentageDetails = {};
  const totalHours = sumBy(timeSheetDetails.timeSheetActivities, "hours");

  forEach(
    groupBy(timeSheetDetails.timeSheetActivities, "project_id"),
    (projectActivities) => {
      const context = first(projectActivities);
      const totalProjectHours = sumBy(projectActivities, "hours");
      const projectPercentage = (
        (totalProjectHours / totalHours) *
        100
      ).toFixed(1);

      timeSheetTotals["date"] = "TOTAL";
      percentageDetails["date"] = "PERCENTAGE";
      // project contributions
      timeSheetTotals[context?.project_id || "SHARED"] = totalProjectHours;
      percentageDetails[
        context?.project_id || "SHARED"
      ] = `${projectPercentage}%`;
      // totals
      timeSheetTotals["total"] = totalHours;
      percentageDetails["total"] = "100%";
    }
  );

  data.push(timeSheetTotals, percentageDetails);

  return (
    <div>
      <AntdTable
        loading={timeSheetsStore.gettingTimeSheetDetails}
        title={
          !showHeader
            ? () => (
                <TableTitle
                  openAddModal={
                    timeSheetDetails.status === "approved" ? null : openAddModal
                  }
                  refreshTable={null}
                  search={null}
                  exportRecords={null}
                />
              )
            : null
        }
        rowKey="date"
        pagination={false}
        pageSize={20}
        data={data}
        columns={columns}
        scrollY="100%"
        onRow={(record) => {
          return {
            onClick: () => {
              if (record.date !== "TOTAL")
                timeSheetsStore.setShowTimeSheetActivities(record, true);
            },
          };
        }}
      />
    </div>
  );
};

export default TimeSheetActivitiesTable;
