import { Collapse, Divider, Tag } from "antd";
import React from "react";
import CommentForm from "./CommentForm";
import { useStaffAppraisalsStore } from "../../../../../config/stores";

const SignOff = () => {
  const staffAppraisalStore = useStaffAppraisalsStore();
  const { staffAppraisalDetails } = staffAppraisalStore;
  return (
    <div>
      <Divider orientation="left" className="text-uppercase fw-bold">
        SIGN OFF
      </Divider>
      <Collapse
        items={[
          {
            label: <span className="fw-bold">Employee Comment</span>,
            extra: staffAppraisalDetails?.employee_comment ? (
              <Tag color="success">FILLED</Tag>
            ) : (
              <Tag color="error">NOT FILLED</Tag>
            ),
            key: "employee-comment",
            children: <CommentForm usage="employee" />,
          },
          {
            label: <span className="fw-bold">Appraiser Comment</span>,
            extra: staffAppraisalDetails?.appraiser_comment ? (
              <Tag color="success">FILLED</Tag>
            ) : (
              <Tag color="error">NOT FILLED</Tag>
            ),
            key: "appraiser_comment",
            children: <CommentForm usage="employer" />,
          },
          {
            label: (
              <span className="fw-bold">
                Department Head Comment (if applicable):{" "}
              </span>
            ),
            extra: staffAppraisalDetails?.head_of_department_comment ? (
              <Tag color="success">FILLED</Tag>
            ) : (
              <Tag color="error">NOT FILLED</Tag>
            ),
            key: "head_of_department_comment",
            children: <CommentForm usage="hod" />,
          },
          {
            label: <span className="fw-bold">Executive Director:</span>,
            extra: staffAppraisalDetails?.executive_director_comment ? (
              <Tag color="success">FILLED</Tag>
            ) : (
              <Tag color="error">NOT FILLED</Tag>
            ),
            key: "executive_director_comment",
            children: <CommentForm usage="approval" />,
          },
        ]}
      />
    </div>
  );
};

export default SignOff;
