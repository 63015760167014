import React, { useState } from "react";
import { useStaffAppraisalsStore } from "../../../../../../config/stores";
import AntdTable from "../../../../../common/AntdTable";
import TableTitle from "../../../../../common/TableTitle";
import TableButtonActions from "../../../../../common/TableButtonActions";

const CapacityDevelopmentGoalsTable = ({ usage, data = [] }) => {
  const staffAppraisalsStore = useStaffAppraisalsStore();
  const [deleteId, setDeleteId] = useState(null);
  const { staffAppraisalDetails } = staffAppraisalsStore;
  const [filterTable, setFilterTableNull] = useState(null);

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    staffAppraisalsStore.setCreatingCapacityDevelopmentGoal(
      false,
      true,
      record
    );
  };

  const confirmDelete = () => {
    staffAppraisalsStore.deleteCapacityDevelopmentGoal(
      deleteId,
      staffAppraisalDetails.id
    );
  };

  let columns = [
    {
      title: "Development Target",
      dataIndex: "development_target",
    },
    {
      title: "Status on Development target",
      dataIndex: "status",
    },
    {
      title: "Rating (1-5)",
      dataIndex: "rating_scale",
      key: "rating_scale",
    },
    {
      title: "Comments by Appraiser",
      dataIndex: "supervisor_comment",
    },
  ];

  if (usage === "new-development-goals") {
    columns = [
      {
        title: "Development Target",
        dataIndex: "development_target",
      },
      {
        title: "Planned Activities/Training",
        dataIndex: "planned_activities",
      },
      {
        title: "Impact on Performance",
        dataIndex: "impact_on_performance",
        key: "impact_on_performance",
      },
      {
        title: "Resources Needed",
        dataIndex: "resources_needed",
      },
    ];
  }
  return (
    <div>
      <AntdTable
        title={() => (
          <TableTitle
            setFilterTableNull={setFilterTableNull}
            openAddModal={null}
            refreshTable={null}
            multiple
            exportRecords={null}
          />
        )}
        loading={staffAppraisalsStore.gettingStaffAppraisalDetails}
        data={filterTable === null ? data : filterTable}
        columns={[
          ...columns,
          {
            title: "ACTION",
            render: (record) => {
              return (
                <TableButtonActions
                  record={record}
                  confirmDelete={confirmDelete}
                  deleteLoading={
                    staffAppraisalsStore.deletingCapacityDevelopmentGoals
                  }
                  disabled={staffAppraisalDetails.status === "approved"}
                  updateRecord={updateRecord}
                  deleteId={deleteId}
                  deleteRecord={deleteRecord}
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default CapacityDevelopmentGoalsTable;
